/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import moment from 'moment-timezone';

import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
} from '@material-ui/core';
import moment from 'moment';
import ExportExcelR from './ExportExcelR';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#303474",
    color: "white",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// eslint-disable-next-line import/prefer-default-export
export function LowTable({
  docs,
  selectedDate,
}) {
  const [dataExcel, setDataExcel] = useState();
  const classes = useStyles();
  // const [rows, setRows] = useState([]);

  useEffect(() => {
    if (docs) {
      // const list = [];
      const listE = [];
      docs?.details?.forEach((row) => {
        row?.categories?.forEach((c) =>{
            listE.push({
          'Nota fiscal': row?.invoice_number || row?.debit_note_number,
          'N do Pedido': row?.debit_note_number ? row?.order_number : row?.type === 'Card' ? 'CARTÃO' : 'CC',
          'CNPJ NF Element': row?.cnpj,
          'Emissão': row?.created_at && moment(row?.created_at).format('DD/MM/YYYY'),
          'Vencimento': row?.endDate && moment(row?.endDate).format('DD/MM/YYYY'),
          'Recebimento':row?.date_payment && moment(row?.date_payment).format('DD/MM/YYYY'),
          'Conciliação': row?.dataConciliation && moment(row?.dataConciliation).format('DD/MM/YYYY'),
          'Banco': row?.bank,
          'Valor': c?.valor,
          'Receita': c?.categoria !== 'Reembolso de despesas com aumento de produtividade' ? c?.categoria?.toUpperCase() : 'PRÊMIO',
          'Cliente': row?.client
        });
        })
      })

      // });
      setDataExcel({ Relatorio: listE })
      // setRows(list);
    }
  }, [docs]);

  const mountDataExcel = d => {
    return <ExportExcelR allData={d} name={`Liberações do dia ${moment(selectedDate).format('DD/MM/YYYY')}`} />;
  };

  return (
    <Paper className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Nota Fiscal</StyledTableCell>
              <StyledTableCell>N do Pedido</StyledTableCell>
              <StyledTableCell>CNPJ NF Element</StyledTableCell>
              <StyledTableCell>Emissão</StyledTableCell>
              <StyledTableCell>Vencimento</StyledTableCell>
              <StyledTableCell>Recebimento</StyledTableCell>
              <StyledTableCell>Conciliação</StyledTableCell>
              <StyledTableCell>Banco</StyledTableCell>
              <StyledTableCell>Valor</StyledTableCell>
              <StyledTableCell>Receita</StyledTableCell>
              <StyledTableCell> <div style={{ display: 'flex', justifyContent: 'center' }}>
                Cliente {dataExcel && mountDataExcel(dataExcel)}
              </div></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docs?.details?.map((row) => (
              row?.categories?.map((c, i) =>(
                // eslint-disable-next-line react/no-array-index-key
                <StyledTableRow key={i}>
                  <StyledTableCell>{row?.invoice_number || row?.debit_note_number}</StyledTableCell>
                  <StyledTableCell>{row?.debit_note_number ? row?.order_number : row?.type === 'Card' ? 'CARTÃO' : 'CC'}</StyledTableCell>
                  <StyledTableCell>{row?.cnpj}</StyledTableCell>
                  <StyledTableCell>{row?.created_at && moment(row?.created_at).format('DD/MM/YYYY')}</StyledTableCell>
                  <StyledTableCell>{row?.dataConciliation && moment(row?.dataConciliation).endOf('month').format('DD/MM/YYYY')}</StyledTableCell>
                  <StyledTableCell>{row?.date_payment && moment(row?.date_payment).format('DD/MM/YYYY')}</StyledTableCell>
                  <StyledTableCell>{row?.dataConciliation && moment(row?.dataConciliation).format('DD/MM/YYYY')}</StyledTableCell>
                  <StyledTableCell>{row?.bank}</StyledTableCell>
                  <StyledTableCell> {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                    .format(c?.valor)
                    .replace(',', '.')}</StyledTableCell>
                  <StyledTableCell>{(c?.categoria !== 'Reembolso de despesas com aumento de produtividade' && c?.categoria !== 'Descarga')? c?.categoria?.toUpperCase() : 'PRÊMIO'}</StyledTableCell>
                  <StyledTableCell>{row?.client}</StyledTableCell>
                </StyledTableRow>
              ))
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
