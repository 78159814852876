/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
// import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import BackupIcon from '@material-ui/icons/Backup';
import moment from 'moment-timezone';
import api from '../../service/axios/api';
import Loading from '../../components/loading/Loading';
import FlashContext from '../../components/contexts/FlashContext';

function createData(
  sequence_number,
  id,
  date_conciliation,
  file_send,
  file_return,
  date_created,
  invoice_number,
) {
  return {
    sequence_number,
    id,
    date_conciliation,
    file_send,
    file_return,
    date_created,
    invoice_number,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// eslint-disable-next-line no-unused-vars
export default function RpsTable({
  docs,
  // setDocs,
  page,
  setPage,
  itens,
  setItens,
  total,
  search,
}) {
  const classes = useStyles();
  const [myFile, setMyfile] = useState();
  const fileRps = useRef(null);
  const [tmp, setTmp] = useState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('Enviando arquivo...')
  const { notifySuccess, notifyError } = useContext(FlashContext);

  useEffect(() => {
    if (myFile) {
      // const reader = new FileReader();
      try {
        const file = myFile;
        const textType = /text.*/;

        if (file.type.match(textType)) {
          const reader = new FileReader();

          // eslint-disable-next-line no-unused-vars
          reader.onload = function (e) {
            const content = reader.result;
            const res = content.substring(36, 42);
            if (Number(res) === Number(tmp.sequence_number)) {
              setMsg("Enviando arquivo...")
              setLoading(true)
              api.rps
                .sendFile(myFile, tmp.id)
                .then(() => {
                  notifySuccess('Arquivo enviado com sucesso!');
                  search();
                })
                .catch(err => console.log(err))
                .finally(() => setLoading(false));
            } else {
              notifyError(
                `Este arquivo não pertence ao rps selecionado. Arquivo com o sequencial ${res} e rps com sequencial ${tmp.sequence_number}`,
              );
            }
          };

          reader.readAsText(file);
        } else {
          notifyError('Informe um arquivo RPS válido');
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [myFile]);

  useEffect(() => {
    const list = [];
    docs.forEach(doc => {
      list.push(
        createData(
          doc.sequence_number,
          doc.id,
          doc.date_reconciliation &&
            moment.utc(doc.date_reconciliation).format('DD/MM/yyyy'),
          doc.gcs_file_send,
          doc.gcs_file_return,
          doc.created_at && moment.utc(doc.created_at).format('DD/MM/yyyy'),
          doc.invoice_number
        ),
      );
    });
    setRows(list);
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(+event.target.value);
    setPage(0);
  };

  

  return (
    <>
      <Loading open={loading} msg={msg} />
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Número sequencial</StyledTableCell>
                <StyledTableCell>NFs</StyledTableCell>
                <StyledTableCell>Data Reconciliação</StyledTableCell>
                <StyledTableCell>Arquivo Enviado</StyledTableCell>
                <StyledTableCell>Arquivo retorno RPS</StyledTableCell>
                <StyledTableCell>Data Criação</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell>{row.sequence_number}</StyledTableCell>
                  <StyledTableCell>{
                  row.invoice_number?.map(r => {
                            return (
                                <p
                                  key={r.nf}
                                  style={{
                                    cursor: 'pointer',
                                    color: 'blue',
                                  }}
                                  className="modal-box-body-value-label"
                                  onClick={() => {
                                    window.open(r.link)
                                    // const a = document.createElement('a');
                                    // // eslint-disable-next-line prefer-template, no-useless-concat
                                    // a.href = 'data:text/plain;charset=UTF-8,' + '' + r.link;
                                    // a.download = r.link.substr(r.link.lastIndexOf('/') + 1);
                                    // console.log(a.download)
                                    // document.body.appendChild(a);
                                    // a.click();
                                    // document.body.removeChild(a);
                                  }}
                                >
                                  {r.nf}
                                </p>
                              );
                            })}</StyledTableCell>
                  <StyledTableCell>{row.date_conciliation}</StyledTableCell>
                  <StyledTableCell>
                    {row.file_send && (
                      <DescriptionIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() =>{ 
                          setMsg('Busncando arquivo...')
                          setLoading(true)
                          const myRequest = new Request(row.file_send)
                          fetch(myRequest)
                          .then(async (res) => {
                            const a = document.createElement('a');
                            const blob = await res.blob();
                            const url = window.URL.createObjectURL(blob);
                            a.href = url;
                            a.download = res.url.substring(46, res.url.length);
                            a.click();
                            window.URL.revokeObjectURL(url);
                          })
                          .catch((err)=> console.log(err))
                          .finally(()=> setLoading(false))
                        }}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.file_return ? (
                      <DescriptionIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setMsg('Buscando arquivo...')
                          setLoading(true)
                            const myRequest = new Request(row.file_return)
                            fetch(myRequest)
                            .then(async (res) => {
                              const a = document.createElement('a');
                              const blob = await res.blob();
                              const url = window.URL.createObjectURL(blob);
                              a.href = url;
                              a.download = res.url.substring(46, res.url.length);
                              a.click();
                              window.URL.revokeObjectURL(url);
                            })
                            .catch((err)=> console.log(err))
                            .finally(()=> setLoading(false))
                        }}
                      />
                    ) : (
                      <BackupIcon
                        style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => {
                          setTmp(row);
                          fileRps.current.click();
                        }}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{row.date_created}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          // count={rows.length}
          count={total}
          rowsPerPage={itens}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* MODAL DE EDIÇÃO */}
      <input
        ref={fileRps}
        type="file"
        style={{
          opacity: 0,
        }}
        // accept="media_type"
        onChange={() => setMyfile(fileRps.current.files[0])}
        className="form-control"
        id="inputNumSerie"
        placeholder="Arquivo DWG do projeto"
      />
    </>
  );
}
