/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef, useContext } from 'react';
import _ from 'lodash';

import { Grid, Box, TextField, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import Select from 'react-select';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import FlashContext from '../../components/contexts/FlashContext';
import routing from '../../service/routes/routing';
import Loading from '../../components/loading/Loading';
import api from '../../service/axios/api';
import styles from '../../styles/Layout.module.css';

import MovimentTable from './MovimentTable';

const customStyles = {
  control: provided => ({
    ...provided,
    width: 170,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};
const customStyles1 = {
  control: provided => ({
    ...provided,
    width: 125,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 10,
    margin: 0,
  },
  chip: {
    margin: 10,
  },
  textField: {
    // width: '90%',
    // // height: '40px',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // paddingTop: '2px',
    // paddingBottom: 0,
    // marginTop: 0,
    // fontWeight: 500,
    // backgroundColor: '#fff',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
    // color: 'white',
  },
});

function Moviment() {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  const [filter, setFilter] = useState({
    label: t('moviment.filters.card'),
    value: 'card',
  });
  const classes = useStyles();
  const [statusSelected, setStatusSelected] = useState({
    label: t('status.Processed'),
    value: 'Processed',
  });
  const searchRef = useRef(null);
  const history = useHistory();

  const { notifyError } = useContext(FlashContext);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState(0);
  const [itens, setItens] = useState(10);
  const [searchType, setSearchType] = useState({
    label: t('moviment.filters.proxy'),
    value: 'proxy',
  });
  const [loading, setLoading] = useState(false);
  const [chipData, setChipData] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedDateFim, handleDateChangeFim] = useState(new Date());
  const optionsFilter = [
    {
      label: t('moviment.filters.card'),
      value: 'card',
    },
    {
      label: t('moviment.filters.transfer'),
      value: 'transfer',
    },
  ];
  const optionsStatus = [
    {
      label: t('status.AwaitingCard'),
      value: 'AwaitingCard',
    },
    {
      label: t('status.Processing'),
      value: 'Processing',
    },
    {
      label: t('status.AwaitingPayment'),
      value: 'AwaitingPayment',
    },
    {
      label: 'Aguardando Status do Cartão',
      value: 'AwaitingBankStatus',
    },
    {
      label: t('status.Processed'),
      value: 'Processed',
    },
    {
      label: t('status.AwaitingCardRelease'),
      value: 'AwaitingCardRelease',
    },
    {
      label: t('status.Canceled'),
      value: 'Canceled',
    },
    {
      label: t('status.Received'),
      value: 'Received',
    },
    {
      label: t('status.Rejected'),
      value: 'Rejected',
    },
    {
      label: t('status.AutoProcessing'),
      value: 'AutoProcessing',
    },
    {
      label: t('status.AwaitingConciliation'),
      value: 'AwaitingConciliation',
    },
    {
      label: t('status.AwaitingProcessing'),
      value: 'AwaitingProcessing',
    },
    {
      label: t('status.Created'),
      value: 'Created',
    },
    {
      label: t('status.all'),
      value: '',
    },
  ];
  const [options, setOptions] = useState([
    {
      label: t('moviment.filters.name'),
      value: 'name',
    },
    {
      label: 'Nome Empresa',
      value: 'companyName',
    },
    {
      label: 'Faturado',
      value: 'invoiced',
    },
    {
      label: t('moviment.filters.cpf'),
      value: 'cpf',
    },
    {
      label: 'CNPJ',
      value: 'cnpj',
    },
    {
      label: t('moviment.filters.proxy'),
      value: 'proxy',
    },
    {
      label: t('moviment.filters.cardNumber'),
      value: 'cardNumber',
    },
    {
      label: t('moviment.filters.orderNumber'),
      value: 'orderNumber',
    },
    {
      label: 'NF',
      value: 'nf',
    },
    {
      label: 'ND',
      value: 'nd',
    },
    {
      label: 'Data conciliação',
      value: 'dateProcessing',
    },
  ]);
  const search = extra => {
    setLoading(true);
    setData([]);
    const cancel = new AbortController();
    api.moviment
      .get(
        extra,
        filter.value,
        statusSelected.value,
        chipData,
        itens,
        page,
        cancel,
      )
      .then(res => {
        setData(res.docs);
        setTotal(res.total);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const generatePlan = () => {
    const cancel = new AbortController();
    if(Number(total) > 180000){
      notifyError('Planilha com mais de 180.000 itens. Solicite ela para a equipe de TI.')
    }else{
      setLoading(true);
      api.moviment
        .getPlan(filter.value, statusSelected.value, chipData, cancel)
        .then(res => {
          const url = res.retorno.link;
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
    
  };

  useEffect(() => {
    search();
  }, [page, itens, statusSelected, filter]);

  const internSearch = btn => {
    if (searchType?.value !== '' && searchRef?.current?.value !== '') {
      addChip(btn);
    } else {
      search();
    }
  };

  const handleDelete = chipToDelete => () => {
    setOptions([
      ...options,
      { label: chipToDelete.by, value: chipToDelete.byType },
    ]);
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  const addChip = btn => {
    if (searchType) {
      if (searchType.value === 'dateProcessing') {
        const orig = _.cloneDeep(options);
        setChipData([
          ...chipData,
          {
            key: chipData.length + 1,
            label: `${moment(selectedDate).format('DD/MM/YYYY')} -
              ${moment(selectedDateFim).format('DD/MM/YYYY')}`,
            by: searchType.label,
            byType: searchType.value,
            date: selectedDate,
            dateFim: selectedDateFim,
          },
        ]);
        const myfind = orig.findIndex(e => e.value === searchType.value);
        orig.splice(myfind, 1);
        setSearchType(null);
        setOptions(orig);
        if (btn) {
          search({
            label: selectedDate,
            byType: searchType.value,
            date: selectedDate,
            dateFim: selectedDateFim,
          });
        }
      } else {
        const orig = _.cloneDeep(options);
        setChipData([
          ...chipData,
          {
            key: chipData.length + 1,
            label: searchRef.current.value,
            by: searchType.label,
            byType: searchType.value,
          },
        ]);
        const find = orig.findIndex(e => e.value === searchType.value);
        orig.splice(find, 1);
        setSearchType(null);
        // selectRef.current.select.clearValue();
        setOptions(orig);
        if (btn) {
          search({
            label: searchRef.current.value,
            byType: searchType.value,
          });
        }
        searchRef.current.value = '';
      }
    } else {
      notifyError(t('moviment.filters.errorTypeSearch'));
    }
  };
  const teclaEnter = e => {
    if (e.key === 'Enter') {
      internSearch(true);
    }
  };
  return (
    <>
      <Loading open={loading} msg={t('moviment.searchMsg')} />
      <Grid container spacing={3} style={{ marginTop: 50 }}>
        <p
          className={styles.textMenu}
          onKeyDown={() => history.push(routing.dashboard)}
          onClick={() => history.push(routing.dashboard)}
        >
          {t('initialMenu')}
        </p>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>{t('moviment.label')}</h4>
        </div>
        <Grid item xs={12} sm={5}>
          <Grid container spacing={0} style={{ justifyContent: 'start' }}>
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <label>{t('moviment.filters.productLabel')}:</label>
                <Select
                  styles={customStyles}
                  classNamePrefix="select"
                  value={filter}
                  isSearchable={false}
                  options={optionsFilter}
                  onChange={setFilter}
                  menuPortalTarget={document.body}
                  style={{ zIndex: 999 }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} style={{ marginLeft: 20 }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <label>{t('moviment.filters.statusLabel')}:</label>
                <Select
                  styles={customStyles}
                  classNamePrefix="select"
                  placeholder={t('moviment.filters.type')}
                  isSearchable={false}
                  options={optionsStatus}
                  onChange={setStatusSelected}
                  value={statusSelected}
                  menuPortalTarget={document.body}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={1} style={{ justifyContent: 'end' }}>
            {/* <Grid item xs={12} sm={3}> */}
            <Select
              fullWidth
              styles={customStyles1}
              onChange={setSearchType}
              value={searchType}
              classNamePrefix="select"
              placeholder={t('moviment.filters.type')}
              isSearchable={false}
              options={options}
              menuPortalTarget={document.body}
              style={{ zIndex: 999, marginRight: 5 }}
            />
            {/* </Grid> */}
            <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
              {searchType?.value === 'dateProcessing' ? (
                <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      style={{ backgroundColor: '#fff', marginRight: 5 }}
                      label="Data inicial"
                      size="small"
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      InputProps={{ readOnly: true }}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => handleDateChange(date)}
                    />
                  </div>
                  <div>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      style={{ backgroundColor: '#fff' }}
                      label="Data final"
                      size="small"
                      format="dd/MM/yyyy"
                      value={selectedDateFim}
                      InputProps={{ readOnly: true }}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => handleDateChangeFim(date)}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              ) : (
                <TextField
                  onKeyUpCapture={e => teclaEnter(e)}
                  inputRef={searchRef}
                  color="secondary"
                  width="400px"
                  className={classes.textField}
                  fullWidth
                  size="small"
                  style={{ maxHeight: 10, minWidth: 300 }}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
              <Button
                onClick={() => addChip(false)}
                variant="contained"
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                <AddIcon />
              </Button>
              <Button onClick={internSearch} variant="contained">
                {t('moviment.filters.searchBtn')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid item className={classes.root}>
            {chipData.map(dt => {
              let icon;
              return (
                <li key={data.key}>
                  <Chip
                    icon={icon}
                    label={`${dt.by} | ${dt.label}`}
                    onDelete={handleDelete(dt)}
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <MovimentTable
            docs={data}
            setDocs={setData}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
            total={total}
            generatePlan={generatePlan}
            product={filter.value}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Moviment;
