import { string, object, boolean } from 'yup';

const DeliveryAddressSchema = object().shape({
  isCentralizedDelivery: boolean().required('Campo obrigatório'),
  postalCode: string().when("isCentralizedDelivery", {
    is: false,
    then: string().required("Campo obrigatório")
  }),
  street: string().when("isCentralizedDelivery", {
    is: false,
    then: string().required("Campo obrigatório")
  }),
  number: string().when("isCentralizedDelivery", {
    is: false,
    then: string().required("Campo obrigatório")
  }),
  complement: string().when("isCentralizedDelivery", {
    is: false,
    then: string().required("Campo obrigatório")
  }),
  district: string().when("isCentralizedDelivery", {
    is: false,
    then: string().required("Campo obrigatório")
  }),
  city: string().when("isCentralizedDelivery", {
    is: false,
    then: string().required("Campo obrigatório")
  }),
  state: string().when("isCentralizedDelivery", {
    is: false,
    then: string().required("Campo obrigatório")
  }),
  receiver: string().required('Campo obrigatório'),
  deliveryAgent: string().required('Campo obrigatório'),
  deliveryService: string().required('Campo obrigatório'),
  companyPostalCode: string().required('Campo obrigatório'),
  companyStreet:string().required('Campo obrigatório'),
  companyNumber: string().required('Campo obrigatório'),
  companyComplement:string().required('Campo obrigatório'),
  companyDistrict:string().required('Campo obrigatório'),
  companyCity: string().required('Campo obrigatório'),
  companyState: string().required('Campo obrigatório'),
});

// export type DeliveryAddress = TypeOf<typeof DeliveryAddressSchema>

export default DeliveryAddressSchema;
