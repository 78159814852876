/* eslint-disable react/prop-types */
import React, { useState, useEffect, memo } from 'react';
import { Tooltip } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useExcelDownloder } from 'react-xls';

function MyExcelExport({ allData }) {
  const { ExcelDownloder, Type } = useExcelDownloder();
  const [data, setData] = useState();
  const [ran, setRan] = useState(Math.random());

  useEffect(() => {
    if (allData) {
      setData(allData);
    }
  }, [allData]);

  useEffect(() => {
    setRan(Math.random());
  }, [data]);

  return (
    <ExcelDownloder
      key={ran}
      data={data}
      style={{ marginLeft: 15, marginRight: -15 }}
      filename="Relatório de Receitas do período"
      type={Type.Link}
    >
      <h6
        style={{
          cursor: 'pointer',
        }}
      >
        <Tooltip title="Exportar para Excel" arrow>
          <GetAppIcon style={{color:"#fff"}} />
        </Tooltip>
      </h6>
    </ExcelDownloder>
  );
}

export default memo(MyExcelExport);
