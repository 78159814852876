/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */

import {
  Grid,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/pt-BR';

import api from '../../../../service/axios/api';
import { LowTable } from './LowTable';


export function Low({ setLoading }) {
  const [dataCategory, setdataCategory] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());


  useEffect(() => {
    setLoading(true);
    setdataCategory([])
    api.order.summaryLow(selectedDate.toISOString())
      .then((res) => {
        setdataCategory(res.data.retorno)
      })
      .catch((error) => { console.log(error) })
      .finally(() => setLoading(false))
  }, [selectedDate])


  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px", marginBottom: "40px" }}
      >
        <h3>Relatório liberação do dia</h3>
        <div style={{ marginTop: "8px", display: 'flex', gap: 5, alignItems: 'center' }}>
          <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              // views={['year', 'month']}
              variant="inline"
              maxDate={Date()}
              inputVariant="outlined"
              // style={{ backgroundColor: '#fff' }}
              size="small"
              format="dd/MM/yyyy"
              InputProps={{ readOnly: true }}
              InputAdornmentProps={{ position: 'start' }}
              value={selectedDate}
              onChange={date => handleDateChange(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
      </Grid>

      <Grid style={{ marginBottom: "20px", marginLeft: "10px", width: "100%" }}>
        <LowTable
          docs={dataCategory}
          selectedDate={selectedDate}
          setDocs={setdataCategory}
          setSelected={setSelected}
          selected={selected}
        />

      </Grid>
    </>
  );
}
