/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Box,
  Stepper,
  StepLabel,
  Step,
  Container,
  Dialog,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import styles from '../../../styles/Layout.module.css';
import Loading from '../../../components/loading/Loading';
import FlashContext from '../../../components/contexts/FlashContext';

import OtherOrder from './OtherOrder';
import LoadingNf from './LoadingNf';
import ReviewCard from './ReviewCard';
import SelectAccount from './SelectAccount';
import SalesCredit from './SalesCredit';
import ReviewEstoque from './ReviewEstoque';
import Contract2 from './Contract2';
import CatProd from './CatProd';
import SelectProd from './SelectProd';
import SpecOrder from './SpecOrder';
import CardExist from './CardExist';
import NfCreated from './NfCreated';
import QtdCards from './QtdCards';

import ReviewTransfer from './ReviewTransfer';
import ReviewAccount from './ReviewAccount';
import UploadPlan from './UploadPlan';
import UploadPlanTransfer from './UploadPlanTransfer';
import UploadPlanAccount from './UploadPlanAccount';
import Created from './Created';

import api from '../../../service/axios/api';

import MyButton from '../../../components/button/MyButton';
import DisplayError from './DisplayError';

// import './checkout.css';
const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      fill: '#41B490',
    },
    text: {
      fill: 'white',
    },
  }),
);

// eslint-disable-next-line react/prop-types
export default function Checkout({
  setOpenModal,
  orderId,
  details,
  closeModal,
  setErros,
  setOk,
  erros,
  docsOk
}) {
  console.log(orderId);
  const { notifyError } = useContext(FlashContext);
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const [msg, setMsg] = useState('');
  const [steps, setSteps] = useState(
    details?.product_type === 'Transfer'
      ? ['Transfer']
      : ['SelectProd', 'SpecOrder'],
  );
  const [activeStep, setActiveStep] = useState(0);
  const [myFile, setMyfile] = useState();
  const [orders, setOrders] = useState([]);

  // error
  const [trimError, setTrimError] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [tabelErrors, setTabelErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  // CATPROD
  const [catProd, setCatProd] = useState();
  // SpecOrder
  const [specOrder, setSpecOrder] = useState();
  const classes = useStyles();

  useEffect(() => {
    setContract({ value: details.contract_id, label: details.client_name });
  }, [details]);

  // QTDCARDS
  const [refQtdCards, setRefQtdCards] = useState();
  // const [refVlCards, setRefVlCards] = useState();

  // SALES_CREDIT
  const [valueCredit, setValueCredit] = useState('');
  // const refValueCredit = useRef(null);

  // TRANSFERÊNCIA
  // SELECT TRANSFER
  const [transferFile, setTransferFile] = useState();
  const [transferOrders, setTransferOrders] = useState([]);

  // SELECT ACCOUNT
  const [accountFile, setAccountFile] = useState();
  const [accountOrders, setAccountOrders] = useState([]);

  const [contract, setContract] = useState();
  const fileXls = useRef(null);

  const [orderName, setOrderName] = useState();
  const [nfComments, setNfComments] = useState();

  const [boleto, setBoleto] = useState(false);
  const [nf, setNf] = useState(false);

  const [paymentDate, setPaymentDate] = useState(new Date());

  // useEffect(() => {
  //   if (catProd === 'pre') {
  //     setSteps([
  //       'Contract',
  //       'Contract2',
  //       'CatProd',
  //       'SelectProd',
  //       'SpecOrder',
  //       'CardExist',
  //       'TaxCompany',
  //     ]);
  //   } else if (catProd === 'transfer') {
  //     setSteps([
  //       'SelectProd',
  //       'SpecOrder',
  //       'CardExist',
  //       'TaxCompany',
  //       'Created',
  //     ]);
  //   } else if (catProd === 'conta') {
  //     setSteps([
  //       'SelectProd',
  //       'SpecOrder',
  //       'CardExist',
  //       'TaxCompany',
  //       'Created',
  //     ]);
  //   }
  // }, [catProd]);

  const provision = myOrderId => {
    api.order
      .addProvision(
        myOrderId,
        valueCredit,
        details?.product_type !== 'Transfer' ? 'pre' : 'Transfer',
      )
      .then(() => {
        setLoad(false);
        closeModal();
      })
      .catch(erFile => {
        setLoad(false);
        notifyError(erFile?.message);
        // if (erFile.params.length > 0) {
        //   setTabelErrors(erFile.params);
        //   setTrimError(erFile.trimError);
        //   setErrorModal(true);
        // }
      });
  };
  const stoque = myOrderId => {
    api.order
      .addStoque(myOrderId, refQtdCards)
      .then(() => {
        setLoad(false);
        closeModal();
      })
      .catch(erFile => {
        setLoad(false);
        notifyError(erFile?.message);
        console.log(erFile);
      });
  };

  const handleNext = () => {
    if (steps.length === 1) {
      setMsg(t('OrderDetails.addItensToOrderMsg'));
      setLoad(true);
      api.order
        .addItems(transferFile, orderId, null, erros, docsOk)
        .then(rr => {
          if (rr.err) {
            notifyError(t('OrderDetails.reviewPlanMsg'));
          }
          setLoad(false);
          closeModal();
        })
        .catch(erFile => {
          setLoad(false);
          console.log(erFile);
          if (erFile.params.length > 0) {
            setTabelErrors(erFile.params);
            setTrimError(erFile.trimError);
            setErrorModal(true);
          }
        });
    } else if (specOrder === 'completo' && activeStep === 1) {
      setMsg(t('OrderDetails.addItensToOrderMsg'));
      setLoad(true);
      api.order
        .addItems(myFile, orderId, null, erros, docsOk)
        .then(rr => {
          if (rr.err) {
            notifyError(t('OrderDetails.reviewPlanMsg'));
          }
          setLoad(false);
          closeModal();
        })
        .catch(erFile => {
          setLoad(false);
          console.log(erFile);
          if (erFile.params.length > 0) {
            setTabelErrors(erFile.params);
            setTrimError(erFile.trimError);
            setErrorModal(true);
          }
        });
    } else if (activeStep === 1 && specOrder === 'credito') {
      setMsg(t('OrderDetails.addItensToOrderMsg'));
      setLoad(true);
      provision(orderId);
    } else if (activeStep === 1 && specOrder === 'estoque') {
      setMsg(t('OrderDetails.addItensToOrderMsg'));
      setLoad(true);
      stoque(orderId);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        // return <CatProd selected={catProd} setSelected={setCatProd} />;
        if (steps.length === 1) {
          return (
            <UploadPlanTransfer
              setLoad={setLoad}
              setMsg={setMsg}
              contract={contract}
              myFile={transferFile}
              setMyfile={setTransferFile}
              orders={transferOrders}
              setOrders={setTransferOrders}
              orderName={orderName}
              setOrderName={setOrderName}
              nfComments={nfComments}
              setNfComments={setNfComments}
              paymentDate={paymentDate}
              setPaymentDate={setPaymentDate}
              nf={nf}
              setNf={setNf}
              boleto={boleto}
              setBoleto={setBoleto}
              setErros={setErros}
              setOk={setOk}
            />
          );
        }
        return <SpecOrder selected={specOrder} setSelected={setSpecOrder} />;
      case 1:
        if (specOrder === 'completo') {
          return (
            <UploadPlan
              setLoad={setLoad}
              setMsg={setMsg}
              contract={contract}
              fileXls={fileXls}
              myFile={myFile}
              setMyfile={setMyfile}
              orders={orders}
              setOrders={setOrders}
              orderName={orderName}
              setOrderName={setOrderName}
              nfComments={nfComments}
              setNfComments={setNfComments}
              paymentDate={paymentDate}
              setPaymentDate={setPaymentDate}
              nf={nf}
              setNf={setNf}
              boleto={boleto}
              setBoleto={setBoleto}
              isNew={false}
              setErros={setErros}
              setOk={setOk}
            />
          );
        }
        if (specOrder === 'credito') {
          return (
            <SalesCredit
              myFile={myFile}
              setMyfile={setMyfile}
              orders={orders}
              setOrders={setOrders}
              valueCredit={valueCredit}
              setValueCredit={setValueCredit}
            />
          );
        }
        if (catProd === 'transfer' || catProd === 'conta') {
          return <LoadingNf handleNext={handleNext} />;
        }
        return (
          <QtdCards
            refQtdCards={refQtdCards}
            setRefQtdCards={setRefQtdCards}
            // setRefVlCards={setRefVlCards}
            // refVlCards={refVlCards}
            myFile={myFile}
            setMyfile={setMyfile}
            orders={orders}
            setOrders={setOrders}
          />
        );
      case 2:
        if (specOrder === 'completo' || specOrder === 'credito') {
          return (
            <ReviewCard
              orderId={orderId}
              orders={orders}
              contract={contract}
              valueCredit={valueCredit}
              myCnpj={1111}
              campaign="tal"
            />
          );
        }
        return <ReviewEstoque refQtdCards={refQtdCards} />;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleBack = () => {
    if (activeStep === 0) {
      setOpenModal(false);
    } /* else if (
      specOrder === 'estoque' ||
      (specOrder === 'credito' && activeStep === 4)
    ) {
      setActiveStep(activeStep - 2);
    } */ else {
      setActiveStep(activeStep - 1);
    }
  };

  const resend = () => {
    setLoading(true);
    if (transferFile) {
      api.order
        .addItems(transferFile, orderId, true, erros, docsOk)
        .then(() => {
          setLoad(false);
          setErrorModal(false);
          closeModal();
        })
        .catch(erFile => {
          setLoad(false);
          if (erFile.params.length > 0) {
            setTabelErrors(erFile.params);
            setTrimError(erFile.trimError);
            setErrorModal(true);
          }
        })
        .finally(() => setLoading(false));
    } else {
      api.order
        .addItems(myFile, orderId, true, erros, docsOk)
        .then(rr => {
          if (rr.err) {
            setLoad(false);
            notifyError(t('newOrder.reviewPlanMsg'));
          } else {
            setLoad(false);
            // setActiveStep(activeStep + 1);
            setErrorModal(false);
            closeModal();
          }
        })
        .catch(erFile => {
          setLoad(false);
          if (erFile.params.length > 0) {
            setTabelErrors(erFile.params);
            setTrimError(erFile.trimError);
            setErrorModal(true);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <Loading open={load} msg={msg} />
      <Container component="main" width={1192} height={776}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* <p className="new-category">Nova categoria de produto</p> */}
          <Stepper activeStep={activeStep} style={{ width: 505 }}>
            {steps.map(label =>
              catProd === 'pre' && activeStep < 7 ? (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        active: classes.icon,
                        text: classes.text,
                        completed: classes.icon,
                      },
                    }}
                  />
                </Step>
              ) : (
                (catProd === 'transfer' || catProd === 'conta' || !catProd) &&
                activeStep < 4 && (
                  <Step key={label}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          active: classes.icon,
                          text: classes.text,
                          completed: classes.icon,
                        },
                      }}
                    />
                  </Step>
                )
              ),
            )}
          </Stepper>
          <p
            className="x"
            style={{ cursor: 'pointer' }}
            onClick={() => setOpenModal(false)}
          >
            X
          </p>
        </div>
        {(catProd === 'transfer' || catProd === 'conta' || !catProd) &&
          activeStep < 4 && (
            <p className="text-checkout-inform">
              {t('OrderDetails.newOrderLabel')}
            </p>
          )}
        {catProd === 'pre' && activeStep < 7 && (
          <p className="text-checkout-inform">
            {t('OrderDetails.newOrderLabel')}
          </p>
        )}
        <>
          {/* {activeStep && ( */}
          <>
            {getStepContent(activeStep)}
            {catProd === 'pre' && activeStep < 7 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 50,
                  marginBottom: 30,
                }}
              >
                <h6
                  className={styles.textExit}
                  onClick={() => setOpenModal(false)}
                >
                  {t('OrderDetails.exit')}
                </h6>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {/* {activeStep !== 0 && ( */}
                  {/* <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Voltar
                </Button> */}
                  {/* // )} */}
                  {activeStep > 0 && (
                    <MyButton
                      style={{
                        width: 120,
                        height: 40,
                        borderRadius: 10,
                        backgroundColor: '#657588',
                      }}
                      onClick={handleBack}
                      label={t('OrderDetails.goBack')}
                    />
                  )}

                  <MyButton
                    style={{
                      width: 120,
                      height: 40,
                      borderRadius: 10,
                      marginLeft: 30,
                    }}
                    onClick={handleNext}
                    label={
                      activeStep < 1
                        ? t('OrderDetails.btnContinue')
                        : t('OrderDetails.btnSalve')
                    }
                  />
                </Box>
              </div>
            )}
            {(catProd === 'transfer' || catProd === 'conta' || !catProd) &&
              activeStep < 3 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: 50,
                    marginBottom: 30,
                  }}
                >
                  <h6
                    className={styles.textExit}
                    onClick={() => setOpenModal(false)}
                  >
                    {t('OrderDetails.exit')}
                  </h6>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* {activeStep !== 0 && ( */}
                    {/* <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Voltar
                </Button> */}
                    {/* // )} */}
                    {activeStep > 0 && (
                      <MyButton
                        style={{
                          width: 120,
                          height: 40,
                          borderRadius: 10,
                          backgroundColor: '#657588',
                        }}
                        onClick={handleBack}
                        label={t('OrderDetails.goBack')}
                      />
                    )}

                    <MyButton
                      style={{
                        width: 120,
                        height: 40,
                        borderRadius: 10,
                        marginLeft: 30,
                      }}
                      onClick={handleNext}
                      disabled={steps.length === 1 && !transferFile}
                      label={
                        steps.length === 1
                          ? t('OrderDetails.btnSalve')
                          : activeStep < 1
                          ? t('OrderDetails.btnContinue')
                          : t('OrderDetails.btnSalve')
                      }
                      // label={activeStep < 1 ? 'Continuar' : 'Salvar Pedido'}
                    />
                  </Box>
                </div>
              )}
          </>
          ,{/* )} */}
        </>
      </Container>
      <Dialog
        onClose={() => setErrorModal(false)}
        open={errorModal}
        fullWidth
        maxWidth="md"
      >
        <Loading open={loading} msg="Adicionando items ao pedido" />
        <DisplayError
          errors={tabelErrors}
          setModalError={setErrorModal}
          hasTrim={trimError}
          resend={resend}
        />
      </Dialog>
    </>
  );
}
