/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import _ from 'lodash';

import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment-timezone';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Select from 'react-select';

import { Pagination } from '@material-ui/lab';

import {
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  TableCell,
  styled,
} from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import Loading from '../../loading/Loading';
import api from '../../../service/axios/api';
import Status from '../../../service/translateStatus';
import Button from '../../button/MyButton';

import PermissionComponent from '../../permissionComponent/PermissionComponent';

import routing from '../../../service/routes/routing';

import SelectOptions from './SalesSelectOptions';

import './tableContract.css';
import UserRoles from '../../../service/acl/UserRoles';

const useStyles = makeStyles(theme =>
  createStyles({
    textNotification: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: 'auto',
      color: '#707070',
      '&:hover': {
        backgroundColor: '#41b490',
        color: '#fff',
      },
    },
  }),
);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const options = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

const EnhancedTableHead = props => {
  const { t } = useTranslation();
  const { onSelectAllClick, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell component="th">
          {t('contract.table.rz')}
        </StyledTableCell>
        <StyledTableCell component="th">Nome do responsável</StyledTableCell>
        <StyledTableCell component="th">
          {t('contract.table.product')}
        </StyledTableCell>
        <StyledTableCell component="th">Agente-Comercial</StyledTableCell>
        <StyledTableCell component="th">
          {t('contract.table.status')}
        </StyledTableCell>
        <StyledTableCell
          component="th"
          align="right"
          style={{ borderTopRightRadius: '5px' }}
        />
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  // order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  // orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = function (props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: '#f3f3f3',
          // (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  data,
  setData,
  setPage,
  setStatus,
  product,
  setProduct,
  setOrderBy,
  chipData,
  setChipData,
  find,
  page,
  itens,
  setItens,
  totalPages,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [menuOptions, setMenuOptions] = useState(null);

  const [duplicateId, setDuplicateId] = useState();
  const [loading, setLoading] = useState(false);
  const [cancel, setCancel] = useState();

  const [cancelAllCards, setCancelAllCards] = useState(false);

  const handleMenu = (row, event) => {
    setMenuOptions(row);
    setAnchorEl(event.currentTarget);
  };

  const translateStatus = status => {
    switch (status) {
      case 'SignedContract':
        return 'Contrato pendente de assinatura';
      case 'ContractPendingSign':
        return 'Contrato pendente de assinatura';
      case 'CanceledContract':
        return 'Contrato Cancelado';
      case 'SendedContract':
        return 'Contrato Enviado';
      default:
        return '';
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const duplicateInf = id => {
    setDuplicateId(id);
    setAnchorEl(null);
    setOpenDuplicate(true);
  };

  const cancelInf = id => {
    // setCancelId(id);
    setAnchorEl(null);
    setOpenCancel(true);
  };

  const duplicateOrder = () => {
    if (duplicateId) {
      api.order
        .duplicate(duplicateId)
        .then(res => {
          setDuplicateId();
          setOpenDuplicate(false);
        })
        .catch(err => console.log(err))
        .finally(() => window.location.reload());
    }
  };

  const changeContractStatus = () => {
    if (menuOptions) {
      setLoading(true);
      const status = menuOptions.status !== 'Ativo';
      api.contract
        .changeStatus(menuOptions.id, status)
        .then(res => {
          const orig = _.cloneDeep(data);
          const myFind = orig.find(o => o.id === menuOptions.id);
          if (myFind) {
            myFind.status = status;
          }
          setData(orig);
          if (cancelAllCards) {
            api.contract
              .cancelAllCards(menuOptions.id)
              .then(() => {
                setCancelAllCards(false);
                setOpenCancel(false);
              })
              .catch(err => {
                console.log(err);
              });
          } else {
            setOpenCancel(false);
          }
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  const [rows, setRows] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const listOrder = [];
    data.forEach(or => {
      listOrder.push({
        id: or.id,
        product: or.product,
        group: or?.group,
        agent: or?.agent,
        rz: or?.rz,
        responsible: or?.responsible,
        status: or?.status,
        all: or,
      });
    });
    setRows(listOrder);
  }, [data]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const contractDownload = (path, filename) => {
    const url = path;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
  }, [menuOptions]);

  return (
    <>
      {/* <Grid item> */}
      <SelectOptions
        setStatus={setStatus}
        setChipData={setChipData}
        chipData={chipData}
        find={find}
        setOrderBy={setOrderBy}
        product={product}
        setProduct={setProduct}
      />
      {/* </Grid> */}
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                handleMenu={handleMenu}
              />
              <TableBody>
                {rows ? (
                  rows.length > 0 ? (
                    rows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow tabIndex={-1} key={row.id}>
                          {/* <StyledTableCell>{row.group}</StyledTableCell> */}
                          <StyledTableCell>{row.rz}</StyledTableCell>
                          <StyledTableCell>{row.responsible}</StyledTableCell>
                          <StyledTableCell>
                            {row.product === 'Card'
                              ? t('status.Card')
                              : t('status.Transfer')}
                          </StyledTableCell>
                          <StyledTableCell>{row.agent}</StyledTableCell>
                          <StyledTableCell>
                            {translateStatus(row.status)}
                          </StyledTableCell>
                          <StyledTableCell component="th" align="right">
                            <MoreVertIcon
                              onClick={e => handleMenu(row, e)}
                              fontSize="small"
                              style={{ cursor: 'pointer' }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={9} align="center">
                        {t('contract.table.notRegistre')}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={9} align="center" />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="menu-appbar"
            style={{ borderRadius: '25px' }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <PermissionComponent role={UserRoles.CONTRACT_DOWNLOAD}>
              <MenuItem
                disabled={!menuOptions?.all?.contractLink}
                className={classes.textNotification}
                onClick={() =>
                  // contractDownload(menuOptions.all.contractLink, 'contrato.pdf')
                  window.open(menuOptions.all.contractLink, '_blank')
                }
              >
                {t('contract.table.download')}
              </MenuItem>
            </PermissionComponent>
            <MenuItem
              className={classes.textNotification}
              onClick={() =>
                history.push(routing.contractDuplicate(menuOptions.id))
              }
            >
              Duplicar contrato
            </MenuItem>
            <PermissionComponent role={UserRoles.CONTRACT_EDIT}>
              <MenuItem
                className={classes.textNotification}
                onClick={() =>
                  history.push(routing.ContractEdit(menuOptions.id))
                }
              >
                {t('contract.table.edit')}
              </MenuItem>
            </PermissionComponent>
            <MenuItem
              className={classes.textNotification}
              onClick={() =>
                history.push(routing.contractViewer(menuOptions.id))
              }
            >
              Detalhes
            </MenuItem>
            <PermissionComponent role={UserRoles.CONTRACT_DISABLE}>
              <MenuItem
                className={classes.textNotification}
                onClick={() => {
                  setOpenCancel(true);
                  setAnchorEl(null);
                }}
              >
                {menuOptions?.status === 'Active'
                  ? t('contract.dialog.disabled')
                  : t('contract.dialog.active')}{' '}
                {t('contract.table.contract')}
              </MenuItem>
            </PermissionComponent>
            <MenuItem
              className={classes.textNotification}
              onClick={() => history.push(routing.salesRemember)}
            >
              Solicitar assinatura
            </MenuItem>
          </Menu>
        </Paper>
      </Box>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 15,
        }}
        spacing={2}
        direction="row"
      >
        <Grid item>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Itens"
            value={itens}
            onChange={setItens}
            options={options}
          />
        </Grid>
        <Grid item>
          <Pagination
            size="large"
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'end',
          padding: 40,
        }}
      />
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        maxWidth="lg"
      >
        <Grid
          container
          spacing={1}
          style={{
            border: '2px solid #c5c5c5',
            borderRadius: 10,
            justifyContent: 'center',
            padding: 40,
          }}
        >
          conteudo
        </Grid>
      </Dialog>
      <Dialog
        onClose={() => setOpenDuplicate(!openDuplicate)}
        open={openDuplicate}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="simple-dialog-title">Pedido</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente duplicar o pedido?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenDuplicate(false)}
          >
            Cancelar
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={duplicateOrder}
            color="primary"
            variant="contained"
            label="Sim"
          />
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => {
          setOpenCancel(!openCancel);
          setCancelAllCards(false);
        }}
        open={openCancel}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg="Atualizando contrato..." />
        <DialogTitle id="simple-dialog-title">
          {t('contract.dialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('contract.dialog.bodyMsg1')}{' '}
            {menuOptions?.status === 'Ativo'
              ? t('contract.dialog.disabled')
              : t('contract.dialog.active')}{' '}
            {t('contract.dialog.bodyMsg2')} {menuOptions?.rz}?<br />
            <br />
            {menuOptions?.status === 'Ativo' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checkedIcon={<BpCheckedIcon />}
                  icon={<BpIcon />}
                  onClick={() => setCancelAllCards(!cancelAllCards)}
                  checked={cancelAllCards}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
                {t('contract.dialog.bodyMsg3')}
              </div>
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => {
              setOpenCancel(false);
              setCancelAllCards(false);
            }}
          >
            {t('contract.dialog.btnCancel')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={changeContractStatus}
            color="primary"
            variant="contained"
            label={t('contract.dialog.btnYes')}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
