/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect, useRef, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Box,
  Stepper,
  StepLabel,
  Step,
  Container,
  Dialog,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import styles from '../../../styles/Layout.module.css';
import FlashContext from '../../../components/contexts/FlashContext';

import Loading from '../../../components/loading/Loading';
import routing from '../../../service/routes/routing';

// import Contract from './Contract';
import OtherOrder from './OtherOrder';
import LoadingNf from './LoadingNf';
import ReviewCard from './ReviewCard';
// import SelectTransfer from './SelectTransfer';
import SelectAccount from './SelectAccount';
import SalesCredit from './SalesCredit';
import CardsVinc from './CardVinc';
import ReviewEstoque from './ReviewEstoque';
import Contract2 from './Contract2';
import CatProd from './CatProd';
import SelectProd from './SelectProd';
import SpecOrder from './SpecOrder';
import SpecTransferOrder from './SpecTransferOrder';
import CardExist from './CardExist';
import NfCreated from './NfCreated';
import QtdCards from './QtdCards';
import DisplayError from './DisplayError';

import ReviewTransfer from './ReviewTransfer';
import ReviewAccount from './ReviewAccount';
// import TaxCompany from './TaxCompany';
// import TaxOwner from './TaxOwner';
import UploadPlan from './UploadPlan';
import UploadPlanTransfer from './UploadPlanTransfer';
import UploadPlanAccount from './UploadPlanAccount';
// import CardLimits from './CardLimits';
// import Review from './Review';
import Created from './Created';

import api from '../../../service/axios/api';

import MyButton from '../../../components/button/MyButton';
import MovimentSearch from './MovimentSearch';
import { TransferDcp } from './TransferDcp';
import { Service } from './Service';
import { ReviewService } from './ReviewService';

// import './checkout.css';
const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      fill: '#41B490',
    },
    text: {
      fill: 'white',
    },
  }),
);

// eslint-disable-next-line react/prop-types
export default function Checkout({ setOpenModal }) {
  const history = useHistory();
  const [trimError, setTrimError] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [tabelErrors, setTabelErrors] = useState([]);
  const [errorLoading, setErrorLoading] = useState(false);
  const { notifyError } = useContext(FlashContext);
  const [load, setLoad] = useState(false);
  const [msg, setMsg] = useState('');
  const [orderId, setOrderId] = useState();
  const [steps, setSteps] = useState([
    'Contract',
    'Contract2',
    'CatProd',
    'SelectProd',
    'SpecOrder',
    'CardExist',
    'TaxCompany',
  ]);
  const [docErrors, setDocErrors] = useState([])
  const [docOk, setDocOk] = useState([])

  const [activeStep, setActiveStep] = useState(0);
  const [myFile, setMyfile] = useState();
  const [orders, setOrders] = useState([]);
  const [typeCard, setTypeCard] = useState();
  // CATPROD
  const [catProd, setCatProd] = useState();
  // SpecOrder
  const [specOrder, setSpecOrder] = useState();
  const [card, specCard] = useState({ label: 'JoyCard', value: 0 });
  const classes = useStyles();

  // QTDCARDS
  const [refQtdCards, setRefQtdCards] = useState();
  const [refVlCards, setRefVlCards] = useState();

  // SALES_CREDIT
  const [valueCredit, setValueCredit] = useState('');
  // const refValueCredit = useRef(null);

  // TRANSFERÊNCIA
  // SELECT TRANSFER
  const [transferFile, setTransferFile] = useState();
  const [transferOrders, setTransferOrders] = useState([]);

  // SELECT ACCOUNT
  const [accountFile, setAccountFile] = useState();
  const [accountOrders, setAccountOrders] = useState([]);

  const [contract, setContract] = useState();
  const [campaign, setCampaign] = useState();
  const [cnpj, setCnpj] = useState();
  const [optCompanys, setOptCompanys] = useState([]);
  const fileXls = useRef(null);

  const [clientsDcp, setClientsDcp] = useState([]);
  const [baseYear, setBaseYear] = useState({
    label: '2023',
    value: 2023,
  });
  const [allPf, setAllPf] = useState(false);
  const [allPj, setAllPj] = useState(false);

  // Nome do pedido
  const [orderName, setOrderName] = useState();
  const [nfComments, setNfComments] = useState();
  const [paymentDate, setPaymentDate] = useState(Date());
  const [boleto, setBoleto] = useState(false);
  const [nf, setNf] = useState('NF');
  const [service, setService] = useState();
  const [valueService, setValueService] = useState();

  // CARTÃO COM VINCULO
  const [cardVinc, setCardVinc] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (steps.length === 0) {
      if (catProd === 'pre') {
        setSteps([
          'Contract',
          'Contract2',
          'CatProd',
          'SelectProd',
          'SpecOrder',
          'CardExist',
          'TaxCompany',
        ]);
      } else if (catProd === 'transfer') {
        setSteps([
          'SelectProd',
          'SpecOrder',
          'CardExist',
          'TaxCompany',
          'TaxCompany',
          'Created',
        ]);
      } else if (catProd === 'conta') {
        setSteps([
          'SelectProd',
          'SpecOrder',
          'CardExist',
          'TaxCompany',
          'Created',
        ]);
      } else if (catProd === 'service') {
        setSteps([
          'SelectProd',
          'SpecOrder',
          'CardExist',
          'TaxCompany',
          'Created',
        ]);
      }
    }
  }, [steps]);

  useEffect(() => {
    if (catProd && steps.length > 0) {
      setSteps([]);
    }

  }, [catProd]);


  const provision = myOrderId => {
    api.order
      .addProvision(myOrderId, valueCredit, catProd)
      .then(i => {
        setLoad(false);
        setActiveStep(activeStep + 1);
      })
      .catch(erFile => {
        setLoad(false);
        if (erFile.message) {
          notifyError(erFile.message);
        }
      });
  };
  const stoque = myOrderId => {
    api.order
      .addStoque(myOrderId, refQtdCards, refVlCards)
      .then(() => {
        setLoad(false);
        setActiveStep(activeStep + 1);
      })
      .catch(erFile => {
        setLoad(false);
        notifyError(erFile?.message);
      });
  };

  const vinculo = myOrderId => {
    api.order
      .addVinc(myOrderId, cardVinc)
      .then(() => {
        setLoad(false);
        setActiveStep(activeStep + 1);
      })
      .catch(erFile => {
        setLoad(false);
        // console.log(erFile);
        notifyError(erFile?.message);
      });
  };

  const handleNext = () => {
    if (catProd === 'transfer' || catProd === 'conta' || catProd === 'pre') {
      if (activeStep === 3 && catProd === 'pre' && !contract) {
        notifyError(t('newOrder.companyMsg'));
      } else if (catProd === 'pre' && activeStep === 5) {
        if (orderId) {
          notifyError('Falha na criação do pedido');
          setLoad(false);
        } else if (specOrder === 'completo' && !myFile) {
          notifyError(t('newOrder.upLoadFileMsg'));
        } else if (specOrder === 'DCP') {
          setMsg(t('newOrder.createMsg'));
          setLoad(true);
          api.order
            .newOrder(
              typeCard,
              contract.value,
              contract.cnpj,
              orderName,
              nfComments,
              paymentDate,
              nf,
              boleto,
              null,
            )
            .then(r => {
              setOrderId(r.retorno.orderId);
              setMsg(t('newOrder.addItensMsg'));
              if (myFile) {
                api.order
                  .addItems(myFile, r.retorno.orderId, null, docErrors, docOk)
                  .then(rr => {
                    if (rr.err) {
                      setLoad(false);
                      notifyError(t('newOrder.reviewPlanMsg'));
                      history.push(routing.orderDetails(r.retorno.orderId));
                    } else {
                      setLoad(false);
                      setActiveStep(activeStep + 1);
                    }
                  })
                  .catch(erFile => {
                    setLoad(false);
                    if (erFile.params.length > 0) {
                      setTabelErrors(erFile.params);
                      setTrimError(erFile.trimError);
                      setErrorModal(true);
                    }
                  });
              } else {
                api.order
                  .addItemsDcp(
                    clientsDcp,
                    r.retorno.orderId,
                    catProd === 'pre' ? 'Card' : 'Transfer',
                    baseYear.value,
                    allPf && allPj ? 3 : allPj ? 2 : allPf ? 1 : 0,
                  )
                  .then(() => {
                    setLoad(false);
                    setActiveStep(activeStep + 1);
                  })
                  .catch(erFile => {
                    setLoad(false);
                    if (erFile.params.length > 0) {
                      setTabelErrors(erFile.params);
                      setTrimError(erFile.trimError);
                      setErrorModal(true);
                    }
                  });
              }
            })
            .catch(err => {
              setLoad(false);
              console.error(err);
              if (err.message) {
                notifyError(err?.message);
              }
            });
        } else {
          setMsg(t('newOrder.createMsg'));
          setLoad(true);
          api.order
            .newOrder(
              typeCard,
              contract.value,
              contract.cnpj,
              orderName,
              nfComments,
              paymentDate,
              nf,
              boleto,
              card.value,
            )
            .then(r => {
              setOrderId(r.retorno.orderId);
              setMsg(t('newOrder.addItensMsg'));
              if (specOrder === 'credito') {
                provision(r.retorno.orderId);
              } else if (specOrder === 'estoque') {
                stoque(r.retorno.orderId);
              } else if (specOrder === 'vinculo') {
                vinculo(r.retorno.orderId);
              } else {
                api.order
                  .addItems(myFile, r.retorno.orderId, null, docErrors, docOk)
                  .then(rr => {
                    if (rr.err) {
                      setLoad(false);
                      notifyError(t('newOrder.reviewPlanMsg'));
                      history.push(routing.orderDetails(r.retorno.orderId));
                    } else {
                      setLoad(false);
                      setActiveStep(activeStep + 1);
                    }
                  })
                  .catch(erFile => {
                    setLoad(false);
                    if (erFile?.params?.length > 0) {
                      setTabelErrors(erFile.params);
                      setTrimError(erFile.trimError);
                      setErrorModal(true);
                    }
                    notifyError(erFile?.message);
                  });
              }
            })
            .catch(err => {
              setLoad(false);
              console.error(err);
              notifyError(err?.message);
            });
        }
      } else if (activeStep === 1 && catProd === 'transfer' && !specOrder) {
        // notifyError(t('newOrder.companyMsg'));
        notifyError('Escolha o tipo do pedido de transferência');
      } else if (activeStep === 2 && catProd === 'transfer' && !contract) {
        notifyError(t('newOrder.companyMsg'));
      } else if (activeStep === 3 && catProd === 'transfer') {
        if (orderId) {
          notifyError('Falha na criação do pedido');
          setLoad(false);
        } else if (specOrder === 'completo' || specOrder === 'DCP') {
          if (transferFile) {
            setMsg(t('newOrder.createMsg'));
            setLoad(true);
            api.order
              .newOrder(
                typeCard,
                contract.value,
                contract.cnpj,
                orderName,
                nfComments,
                paymentDate,
                nf,
                boleto,
                null,
              )
              .then(r => {
                setOrderId(r.retorno.orderId);
                setMsg(t('newOrder.addItensMsg'));
                console.log(transferFile, r.retorno.orderId, null, docErrors, docOk)
                api.order
                  .addItems(transferFile, r.retorno.orderId, null, docErrors, docOk)
                  .then(() => {
                    setLoad(false);
                    setActiveStep(activeStep + 1);
                  })
                  .catch(erFile => {
                    setLoad(false);
                    if (erFile.params.length > 0) {
                      setTabelErrors(erFile.params);
                      setTrimError(erFile.trimError);
                      setErrorModal(true);
                    }
                  });
              })
              .catch(err => {
                setLoad(false);
                if (err.message) {
                  notifyError(err?.message);
                }
              });
          } else if (specOrder === 'DCP') {
            setMsg(t('newOrder.createMsg'));
            setLoad(true);
            api.order
              .newOrder(
                typeCard,
                contract.value,
                contract.cnpj,
                orderName,
                nfComments,
                paymentDate,
                nf,
                boleto,
                null,
              )
              .then(r => {
                setOrderId(r.retorno.orderId);
                setMsg(t('newOrder.addItensMsg'));
                if (myFile) {
                  api.order
                    .addItems(myFile, r.retorno.orderId, null, docErrors, docOk)
                    .then(rr => {
                      if (rr.err) {
                        setLoad(false);
                        notifyError(t('newOrder.reviewPlanMsg'));
                        history.push(routing.orderDetails(r.retorno.orderId));
                      } else {
                        setLoad(false);
                        setActiveStep(activeStep + 1);
                      }
                    })
                    .catch(erFile => {
                      setLoad(false);
                      if (erFile.params.length > 0) {
                        setTabelErrors(erFile.params);
                        setTrimError(erFile.trimError);
                        setErrorModal(true);
                      }
                    })
                    .finally(() => setLoad(false));
                } else {
                  api.order
                    .addItemsDcp(
                      clientsDcp,
                      r.retorno.orderId,
                      catProd === 'pre' ? 'Card' : 'Transfer',
                      baseYear.value,
                      allPf && allPj ? 3 : allPj ? 2 : allPf ? 1 : 5,
                    )
                    .then(() => {
                      setLoad(false);
                      setActiveStep(activeStep + 1);
                    })
                    .catch(erFile => {
                      setLoad(false);
                      if (erFile.params.length > 0) {
                        setTabelErrors(erFile.params);
                        setTrimError(erFile.trimError);
                        setErrorModal(true);
                      }
                    });
                }
              })
              .catch(err => {
                setLoad(false);
                if (err.message) {
                  notifyError(err?.message);
                }
              });
          } else {
            notifyError(t('newOrder.uploadFileMsg'));
          }
        } else if (valueCredit !== '') {
          setMsg(t('newOrder.createMsg'));
          setLoad(true);
          api.order
            .newOrder(
              typeCard,
              contract.value,
              contract.cnpj,
              orderName,
              nfComments,
              paymentDate,
              nf,
              boleto,
              null,
            )
            .then(r => {
              setOrderId(r.retorno.orderId);
              setMsg(t('newOrder.addItensMsg'));
              provision(r.retorno.orderId);
            })
            .catch(err => {
              setLoad(false);
              if (err.message) {
                notifyError(err.message);
              }
            });
        } else {
          notifyError('Informe o valor do crédito a ser comprado');
        }
      } else if (activeStep === 3 && catProd === 'pre' && !cnpj) {
        notifyError(t('newOrder.cnpjMsg'));
      } else if (activeStep === 2 && !specOrder) {
        notifyError(t('newOrder.typeErrorMsg'));
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (catProd === 'service') {
      console.log(activeStep);
      if (activeStep === 2) {
        if (service) {
          if (valueService) {
            if (Number(valueService) > 0) {
              setMsg(t('newOrder.createMsg'));
              setLoad(true);
              api.order
                .newOrderSingleinvoice(
                  contract.value,
                  contract.cnpj,
                  orderName,
                  nfComments,
                  paymentDate,
                  boleto,
                  service.value,
                  valueService,
                )
                .then(res => {
                  setOrderId(res.retorno.orderId);
                  setActiveStep(activeStep + 1);
                })
                .catch(err => {
                  console.log(err);
                  if (err.message) {
                    notifyError(err.message);
                  }
                })
                .finally(() => {
                  setLoad(false);
                });
            } else {
              notifyError('Valor da nota deve ser maior que 0');
            }
          } else {
            notifyError('Informe o valor da nota fiscal');
          }
        } else {
          notifyError('Selecione um serviço para a emissão da nota');
        }
      } else {
        setActiveStep(activeStep + 1);
      }
    }
  };

  useEffect(() => {
    if (activeStep === 0) {
      setCampaign(null);
      setOptCompanys([]);
      setCnpj(null);
      setContract(null);
    }
  }, [catProd]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <CatProd selected={catProd} setSelected={setCatProd} />;
      case 1:
        if (catProd === 'transfer') {
          return (
            <SpecTransferOrder
              selected={specOrder}
              setSelected={setSpecOrder}
            />
          );
        }
        if (catProd === 'conta') {
          return (
            <SelectAccount
              setMyfile={setAccountFile}
              myFile={accountFile}
              setOrders={setAccountOrders}
            />
          );
        }
        if (catProd === 'service') {
          return (
            <Contract2
              contract={contract}
              type={catProd}
              setContract={setContract}
              setLoad={setLoad}
              setMsg={setMsg}
              campaign={campaign}
              setCampaign={setCampaign}
              cnpj={cnpj}
              setCnpj={setCnpj}
              optCompanys={optCompanys}
              setOptCompanys={setOptCompanys}
              typeCard={typeCard}
            />
          );
        }
        return <SelectProd setTypeCard={setTypeCard} />;
      case 2:
        if (catProd === 'transfer') {
          return (
            <Contract2
              contract={contract}
              type={catProd}
              setContract={setContract}
              setLoad={setLoad}
              setMsg={setMsg}
              campaign={campaign}
              setCampaign={setCampaign}
              cnpj={cnpj}
              setCnpj={setCnpj}
              optCompanys={optCompanys}
              setOptCompanys={setOptCompanys}
              typeCard={typeCard}
            />
          );
        }
        if (catProd === 'conta') {
          return (
            <UploadPlanAccount
              setLoad={setLoad}
              setMsg={setMsg}
              contract={contract}
              myFile={accountFile}
              setMyfile={setAccountFile}
              orders={accountOrders}
              setOrders={setAccountOrders}
            />
          );
        }
        if (catProd === 'service') {
          return (
            <Service
              myFile={myFile}
              setMyfile={setMyfile}
              orders={orders}
              setOrders={setOrders}
              valueCredit={valueCredit}
              setValueCredit={setValueCredit}
              orderName={orderName}
              setOrderName={setOrderName}
              nfComments={nfComments}
              setNfComments={setNfComments}
              paymentDate={paymentDate}
              setPaymentDate={setPaymentDate}
              nf={nf}
              setNf={setNf}
              boleto={boleto}
              setBoleto={setBoleto}
              setValueService={setValueService}
              valueService={valueService}
              service={service}
              setService={setService}
              isNew
            />
          );
        }
        return (
          <SpecOrder selected={specOrder} setSelected={setSpecOrder} isNew />
        );
      case 3:
        if (catProd === 'transfer' && specOrder === 'completo') {
          return (
            <UploadPlanTransfer
              setLoad={setLoad}
              setMsg={setMsg}
              contract={contract}
              myFile={transferFile}
              setMyfile={setTransferFile}
              orders={transferOrders}
              setOrders={setTransferOrders}
              orderName={orderName}
              setOrderName={setOrderName}
              nfComments={nfComments}
              setNfComments={setNfComments}
              paymentDate={paymentDate}
              setPaymentDate={setPaymentDate}
              nf={nf}
              setNf={setNf}
              boleto={boleto}
              setBoleto={setBoleto}
              setOk={setDocOk}
              setErros={setDocErrors}
              erros={docErrors}
              isNew
            />
          );
        }
        if (catProd === 'transfer' && specOrder === 'credito') {
          return (
            <SalesCredit
              myFile={myFile}
              setMyfile={setMyfile}
              orders={orders}
              setOrders={setOrders}
              valueCredit={valueCredit}
              setValueCredit={setValueCredit}
              orderName={orderName}
              setOrderName={setOrderName}
              nfComments={nfComments}
              setNfComments={setNfComments}
              paymentDate={paymentDate}
              setPaymentDate={setPaymentDate}
              nf={nf}
              setNf={setNf}
              boleto={boleto}
              setBoleto={setBoleto}
              isNew
            />
          );
        }
        if (catProd === 'transfer' && specOrder === 'DCP') {
          return (
            <TransferDcp
              contract={contract}
              myFile={myFile}
              setMyfile={setMyfile}
              orders={orders}
              setOrders={setOrders}
              orderName={orderName}
              setOrderName={setOrderName}
              nfComments={nfComments}
              setNfComments={setNfComments}
              paymentDate={paymentDate}
              setPaymentDate={setPaymentDate}
              nf={nf}
              setNf={setNf}
              boleto={boleto}
              setBoleto={setBoleto}
              setRight={setClientsDcp}
              right={clientsDcp}
              baseYear={baseYear}
              setBaseYear={setBaseYear}
              setAllPf={setAllPf}
              allPf={allPf}
              setAllPj={setAllPj}
              allPj={allPj}
              isNew
            />
          );
        }
        if (catProd === 'conta') {
          return <ReviewAccount orders={accountOrders} />;
        }
        if (catProd === 'service') {
          return (
            <ReviewService
              orders={transferOrders}
              contract={contract}
              orderId={orderId}
              myCnpj={cnpj}
              valueCredit={valueCredit}
              specOrder={specOrder}
            />
          );
        }
        return (
          <Contract2
            contract={contract}
            type={catProd}
            setContract={setContract}
            setLoad={setLoad}
            setMsg={setMsg}
            campaign={campaign}
            setCampaign={setCampaign}
            cnpj={cnpj}
            setCnpj={setCnpj}
            optCompanys={optCompanys}
            setOptCompanys={setOptCompanys}
            typeCard={typeCard}
          />
        );
      case 4:
        if (catProd === 'transfer') {
          return (
            <ReviewTransfer
              orders={transferOrders}
              contract={contract}
              orderId={orderId}
              myCnpj={cnpj}
              valueCredit={valueCredit}
              specOrder={specOrder}
            />
          );
        }
        if (catProd === 'service') {
          return (
            <Created
              orderId={orderId}
              setOpenModal={setOpenModal}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setLoad={setLoad}
              setMsg={setMsg}
            />
          );
        }
        return (
          <CardExist specOrder={specOrder} card={card} specCard={specCard} />
        );
      case 5:
        if (catProd === 'transfer' || catProd === 'conta') {
          return (
            <Created
              orderId={orderId}
              setOpenModal={setOpenModal}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setLoad={setLoad}
              setMsg={setMsg}
            />
          );
        }
        if (specOrder === 'completo' && catProd === 'pre') {
          return (
            <UploadPlan
              setLoad={setLoad}
              setMsg={setMsg}
              contract={contract}
              fileXls={fileXls}
              myFile={myFile}
              setMyfile={setMyfile}
              orders={orders}
              setOrders={setOrders}
              orderName={orderName}
              setOrderName={setOrderName}
              nfComments={nfComments}
              setNfComments={setNfComments}
              paymentDate={paymentDate}
              setPaymentDate={setPaymentDate}
              nf={nf}
              setNf={setNf}
              boleto={boleto}
              setBoleto={setBoleto}
              setOk={setDocOk}
              setErros={setDocErrors}
              erros={docErrors}
              isNew
            />
          );
        }
        if (specOrder === 'credito' && catProd === 'pre') {
          return (
            <SalesCredit
              myFile={myFile}
              setMyfile={setMyfile}
              orders={orders}
              setOrders={setOrders}
              valueCredit={valueCredit}
              setValueCredit={setValueCredit}
              orderName={orderName}
              setOrderName={setOrderName}
              nfComments={nfComments}
              setNfComments={setNfComments}
              paymentDate={paymentDate}
              setPaymentDate={setPaymentDate}
              nf={nf}
              setNf={setNf}
              boleto={boleto}
              setBoleto={setBoleto}
              isNew
            />
          );
        }
        if (specOrder === 'vinculo' && catProd === 'pre') {
          return (
            <CardsVinc
              myFile={myFile}
              setMyfile={setMyfile}
              orders={orders}
              setOrders={setOrders}
              valueCredit={valueCredit}
              setValueCredit={setValueCredit}
              orderName={orderName}
              setOrderName={setOrderName}
              nfComments={nfComments}
              setNfComments={setNfComments}
              paymentDate={paymentDate}
              setPaymentDate={setPaymentDate}
              nf={nf}
              setNf={setNf}
              boleto={boleto}
              setBoleto={setBoleto}
              setCardVinc={setCardVinc}
              cardVinc={cardVinc}
              setOk={setDocOk}
              isNew
            />
          );
        }
        if (catProd === 'pre' && specOrder === 'DCP') {
          return (
            <TransferDcp
              contract={contract}
              myFile={myFile}
              setMyfile={setMyfile}
              orders={orders}
              setOrders={setOrders}
              orderName={orderName}
              setOrderName={setOrderName}
              nfComments={nfComments}
              setNfComments={setNfComments}
              paymentDate={paymentDate}
              setPaymentDate={setPaymentDate}
              nf={nf}
              setNf={setNf}
              boleto={boleto}
              setBoleto={setBoleto}
              setRight={setClientsDcp}
              right={clientsDcp}
              baseYear={baseYear}
              setBaseYear={setBaseYear}
              setAllPf={setAllPf}
              allPf={allPf}
              setAllPj={setAllPj}
              allPj={allPj}
              isNew
            />
          );
        }
        if (catProd === 'service') {
          return <LoadingNf handleNext={handleNext} />;
        }
        // if (catProd === 'transfer' || catProd === 'conta') {
        //   return <LoadingNf handleNext={handleNext} />;
        // }
        return (
          <QtdCards
            refQtdCards={refQtdCards}
            setRefQtdCards={setRefQtdCards}
            setRefVlCards={setRefVlCards}
            refVlCards={refVlCards}
            myFile={myFile}
            setMyfile={setMyfile}
            orders={orders}
            setOrders={setOrders}
            orderName={orderName}
            setOrderName={setOrderName}
            nfComments={nfComments}
            setNfComments={setNfComments}
            paymentDate={paymentDate}
            setPaymentDate={setPaymentDate}
            nf={nf}
            setNf={setNf}
            boleto={boleto}
            setBoleto={setBoleto}
            isNew
          />
        );

      case 6:
        if (catProd === 'transfer' || catProd === 'conta') {
          return <LoadingNf handleNext={handleNext} />;
        }
        if (
          (specOrder === 'completo' ||
            specOrder === 'credito' ||
            specOrder === 'vinculo') &&
          catProd === 'pre'
        ) {
          return (
            <ReviewCard
              orderId={orderId}
              type={typeCard}
              orders={orders}
              contract={contract}
              myCnpj={cnpj}
              valueCredit={valueCredit}
              campaign={campaign}
            />
          );
        }
        if (catProd === 'service') {
          return <NfCreated handleNext={handleNext} />;
        }
        return (
          <ReviewEstoque
            refQtdCards={refQtdCards}
            type={typeCard}
            orderId={orderId}
            contract={contract}
            myCnpj={cnpj}
            campaign={campaign}
            specOrder={specOrder}
          />
        );

      case 7:
        if (catProd === 'transfer' || catProd === 'conta') {
          return <NfCreated handleNext={handleNext} />;
        }
        if (catProd === 'service') {
          return (
            <OtherOrder
              setOpenModal={setOpenModal}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          );
        }
        return (
          <Created
            orderId={orderId}
            setOpenModal={setOpenModal}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setLoad={setLoad}
            setMsg={setMsg}
          />
        );

      case 8:
        if (catProd === 'transfer' || catProd === 'conta') {
          return (
            <OtherOrder
              setOpenModal={setOpenModal}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          );
        }
        return <LoadingNf handleNext={handleNext} />;
      case 9:
        return <NfCreated handleNext={handleNext} />;
      case 10:
        return (
          <OtherOrder
            setOpenModal={setOpenModal}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  }

  const handleBack = () => {
    if (activeStep === 0) {
      setOpenModal(false);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const resend = () => {
    setErrorLoading(true);
    if (catProd === 'transfer') {
      if (specOrder === 'credito') {
        provision(orderId);
      } else {
        console.log(transferFile, r.retorno.orderId, null, docErrors, docOk)
        api.order
          .addItems(transferFile, orderId, true, docErrors, docOk)
          .then(() => {
            setLoad(false);
            setErrorModal(false);
            setActiveStep(activeStep + 1);
          })
          .catch(erFile => {
            setLoad(false);
            if (erFile.params.length > 0) {
              setTabelErrors(erFile.params);
              setTrimError(erFile.trimError);
              setErrorModal(true);
            }
          })
          .finally(() => setErrorLoading(false));
      }
    } else {
      api.order
        .addItems(myFile, orderId, true, docErrors, docOk)
        .then(rr => {
          if (rr.err) {
            setLoad(false);
            notifyError(t('newOrder.reviewPlanMsg'));
            history.push(routing.orderDetails(orderId));
          } else {
            setLoad(false);
            setActiveStep(activeStep + 1);
          }
        })
        .catch(erFile => {
          console.log(erFile);
          setLoad(false);
          if (erFile.params.length > 0) {
            setTabelErrors(erFile.params);
            setTrimError(erFile.trimError);
            setErrorModal(true);
          }
        })
        .finally(() => setErrorLoading(false));
    }
  };

  return (
    <>
      <Loading open={load} msg={msg} />
      <Container component="main" width={1192} height={776}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Stepper activeStep={activeStep} style={{ width: 505 }}>
            {steps.map((label, i) =>
              catProd === 'pre' && activeStep < 7 ? (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        active: classes.icon,
                        text: classes.text,
                        completed: classes.icon,
                      },
                    }}
                  />
                </Step>
              ) : (catProd === 'transfer' || catProd === 'conta' || !catProd) &&
                activeStep < 5 ? (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        active: classes.icon,
                        text: classes.text,
                        completed: classes.icon,
                      },
                    }}
                  />
                </Step>
              ) : (
                catProd === 'service' &&
                activeStep < 4 && (
                  <Step key={label}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          active: classes.icon,
                          text: classes.text,
                          completed: classes.icon,
                        },
                      }}
                    />
                  </Step>
                )
              ),
            )}
          </Stepper>
          <p
            className="x"
            style={{ cursor: 'pointer' }}
            onClick={() => setOpenModal(false)}
          >
            X
          </p>
        </div>
        {(catProd === 'transfer' || catProd === 'conta' || !catProd) &&
          activeStep < 5 && (
            <p className="text-checkout-inform">{t('newOrder.insert')}</p>
          )}
        {catProd === 'pre' && activeStep < 7 && (
          <p className="text-checkout-inform">{t('newOrder.insert')}</p>
        )}
        {catProd === 'service' && activeStep < 4 && (
          <p className="text-checkout-inform">{t('newOrder.insert')}</p>
        )}
        <>
          {getStepContent(activeStep)}
          {catProd === 'pre' && activeStep < 7 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 50,
                marginBottom: 30,
              }}
            >
              <h6
                className={styles.textExit}
                onClick={() => setOpenModal(false)}
              >
                Sair
              </h6>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep > 0 && activeStep < 6 && (
                  <MyButton
                    style={{
                      width: 120,
                      height: 40,
                      borderRadius: 10,
                      backgroundColor: '#657588',
                    }}
                    onClick={handleBack}
                    label="Voltar"
                  />
                )}

                <MyButton
                  style={{
                    width: 120,
                    height: 40,
                    borderRadius: 10,
                    marginLeft: 30,
                  }}
                  onClick={handleNext}
                  label={activeStep < 6 ? 'Continuar' : 'Salvar Pedido'}
                />
              </Box>
            </div>
          )}
          {(catProd === 'transfer' || catProd === 'conta' || !catProd) &&
            activeStep < 5 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 50,
                  marginBottom: 30,
                }}
              >
                <h6
                  className={styles.textExit}
                  onClick={() => setOpenModal(false)}
                >
                  Sair
                </h6>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep > 0 && activeStep < 4 && (
                    <MyButton
                      style={{
                        width: 120,
                        height: 40,
                        borderRadius: 10,
                        backgroundColor: '#657588',
                      }}
                      onClick={handleBack}
                      label="Voltar"
                    />
                  )}

                  <MyButton
                    style={{
                      width: 120,
                      height: 40,
                      borderRadius: 10,
                      marginLeft: 30,
                    }}
                    onClick={handleNext}
                    label={activeStep < 6 ? 'Continuar' : 'Salvar Pedido'}
                  />
                </Box>
              </div>
            )}
          {catProd === 'service' && activeStep < 4 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 50,
                marginBottom: 30,
              }}
            >
              <h6
                className={styles.textExit}
                onClick={() => setOpenModal(false)}
              >
                Sair
              </h6>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep > 0 && activeStep < 4 && (
                  <MyButton
                    style={{
                      width: 120,
                      height: 40,
                      borderRadius: 10,
                      backgroundColor: '#657588',
                    }}
                    onClick={handleBack}
                    label="Voltar"
                  />
                )}

                <MyButton
                  style={{
                    width: 120,
                    height: 40,
                    borderRadius: 10,
                    marginLeft: 30,
                  }}
                  onClick={handleNext}
                  label={activeStep < 6 ? 'Continuar' : 'Salvar Pedido'}
                />
              </Box>
            </div>
          )}
        </>
      </Container>
      <Dialog
        onClose={() => setErrorModal(false)}
        open={errorModal}
        fullWidth
        maxWidth="md"
      >
        <Loading open={errorLoading} msg="Adicioando items ao pedido..." />
        <DisplayError
          errors={tabelErrors}
          setModalError={setErrorModal}
          hasTrim={trimError}
          resend={resend}
        />
      </Dialog>
    </>
  );
}
