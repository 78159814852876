/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
  // Tooltip,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  styled,
  Checkbox,
  DialogContent,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
// import GetAppIcon from '@material-ui/icons/GetApp';

// import { useExcelDownloder } from 'react-xls';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';

import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Edit } from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import VincContract from './contracts/VincContract';
import Status from '../../service/translateStatus';
import Loading from '../../components/loading/Loading';

import FlashContext from '../../components/contexts/FlashContext';

import api from '../../service/axios/api';
import ContractsTable from './contracts/ContractsTable';

function createData(id, name, fileFull, fileFront, fileBack) {
  // const density = population / size;
  return {
    id,
    name,
    fileFull,
    fileFront,
    fileBack,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
    maxWidth: 120,
    overflow: 'auto',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

// eslint-disable-next-line no-unused-vars
export default function CardsTable({
  docs,
  page,
  setPage,
  itens,
  setItens,
  total,
  selected,
  setSelected,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openVinc, setOpenVinc] = useState(false);
  // const [selected, setSelected] = React.useState([]);
  const [cardId, setCardId] = useState();
  const [contracts, setContracts] = useState([]);
  const isSelected = id => selected.indexOf(id) !== -1;

  useEffect(() => {
    if (docs) {
      const list = [];
      // status,
      // cpf,
      // name,
      // proxy,
      // cardNumber,

      docs.forEach(d => {
        list.push(
          createData(
            d.id,
            d.name,
            d.file_full,
            d.file_front,
            d.file_back,
            // d.account_holder,
          ),
        );
      });
      setRows(list);
    }
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  const disableCheckbox = e => {
    let myret = true;
    switch (e) {
      case 'Error':
        myret = false;
        break;
      case 'Queued':
        myret = false;
        break;
      case 'AwaitingCardStatusCheck':
        myret = false;
        break;
      default:
        myret = true;
        break;
    }
    return myret;
  };
  const contractsViewer = id => {
    setOpen(true);
    setCardId(id);
    const cancel = new AbortController();
    api.cards
      .getContracts(cancel, id)
      .then(r => {
        const list = [];
        r.forEach(iContract => {
          iContract.legalEntities.forEach(entities => {
            entities.LegalEntity.contract_id = iContract.contract_id;
            entities.LegalEntity.campaign = iContract?.Contract?.campaign;
            entities.LegalEntity.product_type =
              iContract?.Contract?.product_type;
            entities.LegalEntity.is_active = iContract?.Contract?.is_active;
            list.push(entities.LegalEntity);
          });
        });
        setContracts(list);
      })
      .catch(err => console.log(err));
  };

  const vincContracts = id => {
    setCardId(id);
    setOpenVinc(true);
  };

  const find = () => {
  };
  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Nome</StyledTableCell>
                <StyledTableCell>Imagem Frente/Verso</StyledTableCell>
                <StyledTableCell>Imagem Frente</StyledTableCell>
                <StyledTableCell>Imagem Verso</StyledTableCell>
                <StyledTableCell align="right">Contratos</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <StyledTableRow key={i}>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell
                      onClick={() => window.open(row.fileFull, '_blank')}
                      style={{ cursor: 'pointer' }}
                    >
                      {row.fileFull}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() => window.open(row.fileFront, '_blank')}
                      style={{ cursor: 'pointer' }}
                    >
                      {row.fileFront}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() => window.open(row.fileBack, '_blank')}
                      style={{ cursor: 'pointer' }}
                    >
                      {row.fileBack}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div>
                        <VisibilityIcon
                          style={{ marginRight: 10, cursor: 'pointer' }}
                          onClick={() => contractsViewer(row.id)}
                        />
                        <LinkIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => vincContracts(row.id)}
                        />
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="simple-dialog-title">Contratos vinculados</DialogTitle>
        <DialogContent>
          <ContractsTable
            docs={contracts}
            search={find}
            cardId={cardId}
            handleClose={setOpen}
          />
        </DialogContent>

        <DialogActions>
          <Button
            style={{ height: 40 }}
            onClick={() => setOpen(false)}
            color="primary"
            variant="contained"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpenVinc(!open)}
        open={openVinc}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="simple-dialog-title">Vincular Contratos</DialogTitle>
        <DialogContent>
          <VincContract
            setOpenModal={setOpenVinc}
            cardId={cardId}
            search={find}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
