/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */

import React, { useState, useRef, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import { TextField, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
// import api from '../../../../service/axios/api';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import FlashContext from '../../components/contexts/FlashContext';

const customStyles1 = {
  control: provided => ({
    ...provided,
    width: 125,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

const customStylesSerpro = {
  control: provided => ({
    ...provided,
    width: 300,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 10,
    margin: 0,
  },
  chip: {
    margin: 10,
  },
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
    // color: 'white',
  },
}));

function SelectOption({ chipData, setChipData, find }) {
  const { t } = useTranslation();
  const [searchType, setSearchType] = useState(null);
  const [searchTypeSerpro, setSearchTypeSerpro] = useState(null);
  const [selectedDateIni, setSelectedDateIni] = useState(new Date())
  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date())
  const { notifyError } = useContext(FlashContext);
  // const [chipData, setChipData] = useState([]);
  const classes = useStyles();
  const searchRef = useRef(null);

  const internSearch = () => {
    if ((searchType?.value !== 'status_serpro' && searchRef?.current?.value !== '')||(searchType?.value === 'status_serpro' && !!searchTypeSerpro)) {
      includeSearch(true);
    } else {
      find();
    }
  };

  const [options, setOptions] = useState([
    {
      label: 'Cliente',
      value: 'name',
    },
    {
      label: 'CPF',
      value: 'cpf',
    },
    {
      label: 'Status Serpro',
      value: 'status_serpro',
    },
    {
      label: 'Data consulta inicial',
      value: 'date_serpro_start',
    },
    {
      label: 'Data consulta final',
      value: 'date_serpro_end',
    },
  ]);

  const optionsSerpro = [
    {
      label: 'Regular',
      value: 'Regular',
    },
    {
      label: 'Suspensa',
      value: 'Suspensa',
    },
    {
      label: 'Titular Falecido',
      value: 'Titular Falecido',
    },
    {
      label: 'Pendente de Regularização',
      value: 'Pendente de Regularização',
    },
    {
      label: 'Cancelada por Multiplicidade',
      value: 'Cancelada por Multiplicidade',
    },
    {
      label: 'Cancelada de Ofício',
      value: 'Cancelada de Ofício',
    },
  ]

  const handleDelete = chipToDelete => () => {
    setOptions([
      ...options,
      { label: chipToDelete.by, value: chipToDelete.byType },
    ]);
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  const includeSearch = btn => {
    try {
      const orig = _.cloneDeep(options);
      setChipData([
        ...chipData,
        {
          key: chipData.length + 1,
          label:
            searchType.value === 'status_serpro'
              ? searchTypeSerpro?.label
                : searchType.value === 'date_serpro_start' ? moment(selectedDateIni).format('DD/MM/YYYY')
                : searchType.value === 'date_serpro_end' ? moment(selectedDateEnd).format('DD/MM/YYYY')
                :searchRef?.current?.value,
          by: searchType.label,
          byType: searchType.value,
          date: searchType.value === 'date_serpro_start' ? selectedDateIni : searchType.value === 'date_serpro_end' ? selectedDateEnd : new Date(),
        },
      ]);
      const myfind = orig.findIndex(e => e.value === searchType.value);
      orig.splice(myfind, 1);
      setSearchType(null);
      setSearchTypeSerpro(null)
      setOptions(orig);
      if (btn) {
        find({
          label:
            searchType.value === 'status_serpro'
              ? searchTypeSerpro?.label
                : searchType.value === 'date_serpro_start' ? moment(selectedDateIni).format('DD/MM/YYYY')
                : searchType.value === 'date_serpro_end' ? moment(selectedDateEnd).format('DD/MM/YYYY')
                :searchRef?.current?.value,
          by: searchType.label,
          byType: searchType.value,
          date: searchType.value === 'date_serpro_start' ? selectedDateIni : searchType.value === 'date_serpro_end' ? selectedDateEnd : new Date(),
        });
      }

      if(searchRef?.current?.value){
        searchRef.current.value = '';
      }
    } catch (e) {
      console.log(e)
      notifyError("Selecione o tipo")
    }
  };

  const addChip = () => {
    if (searchType) {
      includeSearch(false);
    } else {
      // notifyError(t('contract.filters.typeSearchMsg'));
    }
  };

  const teclaEnter = e => {
    if (e.key === 'Enter') {
      internSearch();
    }
  };
  return (
    <>
      <Grid
        container
        style={{
          justifyContent: 'end',
          alignItems: 'center',
          marginBottom: 30,
          marginTop: 15,
        }}
      >
        <Grid item xs={4} sm={8}>
          <Grid container spacing={1} style={{ justifyContent: 'end' }}>
            <Grid item>
              <Select
                fullWidth
                styles={customStyles1}
                onChange={setSearchType}
                value={searchType}
                classNamePrefix="select"
                placeholder={t('contract.filters.type')}
                isSearchable={false}
                options={options}
                menuPortalTarget={document.body}
                style={{ zIndex: 999 }}
              />
            </Grid>
            <Grid item style={{ display: 'flex', justifyContent: 'center' }}>

              {searchType?.value === 'status_serpro' ? 
              <Select
              fullWidth
              styles={customStylesSerpro}
              onChange={setSearchTypeSerpro}
              value={searchTypeSerpro}
              classNamePrefix="select"
              placeholder={t('contract.filters.type')}
              isSearchable={false}
              options={optionsSerpro}
              menuPortalTarget={document.body}
              style={{ zIndex: 999 }}
            /> :
              searchType?.value === 'date_serpro_start'?
              <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                style={{ backgroundColor: '#fff' }}
                // label="With keyboard"
                size="small"
                format="dd/MM/yyyy"
                value={selectedDateIni}
                InputProps={{ readOnly: true }}
                InputAdornmentProps={{ position: 'start' }}
                onChange={date => setSelectedDateIni(date)}
              />
            </MuiPickersUtilsProvider>
              : 
              searchType?.value === 'date_serpro_end'?
              <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                style={{ backgroundColor: '#fff' }}
                // label="With keyboard"
                size="small"
                format="dd/MM/yyyy"
                value={selectedDateEnd}
                InputProps={{ readOnly: true }}
                InputAdornmentProps={{ position: 'start' }}
                onChange={date => setSelectedDateEnd(date)}
              />
            </MuiPickersUtilsProvider>: <TextField
                onKeyUpCapture={e => teclaEnter(e)}
                inputRef={searchRef}
                color="secondary"
                width="400px"
                className={classes.textField}
                fullWidth
                size="small"
                style={{ maxHeight: 10, minWidth: 300 }}
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />}
              <Button
                onClick={addChip}
                variant="contained"
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                <AddIcon />
              </Button>
              <Button onClick={internSearch} variant="contained">
                {t('contract.filters.btnSearch')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} sm={6}>
        <Grid item className={classes.root}>
          {chipData.map((dt, i) => {
            let icon;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={i}>
                <Chip
                  icon={icon}
                  label={`${dt.by} | ${dt.label}`}
                  onDelete={handleDelete(dt)}
                  className={classes.chip}
                />
              </li>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}

export default SelectOption;
