/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */

import {
  Dialog,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/pt-BR';
import TableDialog from './TableDialog';
import Button from '../../../../components/button/MyButton';
import ResumTable from './ResumTable';
import api from '../../../../service/axios/api';
import Loading from '../../../../components/loading/Loading';


export default function Resum({ setLoading }) {
  const [dataCategory, setdataCategory] = useState([]);
  const [dataCompare, setdataCompare] = useState([]);
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [openDataDialog, setopenDataDialog] = useState(new Date());
  const [openDataDialogF, setopenDataDialogF] = useState(new Date());
  const [DialogLoading, setDialogLoading] = useState(false);
  const [tablekey, setkey] = useState([])


  useEffect(() => {
    setLoading(true);
    api.order.summaryResum(selectedDate.toISOString())
      .then((res) => {
        setdataCategory(res.data.retorno.listCategory)
        setLoading(false)
      })
      .catch((error) => { console.log(error) })
  }, [selectedDate])

  const BuscaMetodos = () => {
    setDialogLoading(true)
    api.order.summaryResum(openDataDialog.toISOString(), openDataDialogF.toISOString())
      .then((res) => {
        setdataCompare(res.data.retorno.listCompare)
        const controle = Object.keys(res.data.retorno.listCompare[0])
        setkey(controle)
        setDialogLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setDialogLoading(false)
      })
  }

  useEffect(() => {
    BuscaMetodos()
  }, [])


  const handleEdit = () => {
    setOpenModalPayment(true);
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px", marginBottom: "40px" }}
      >
        <h3>Relatório mensal</h3>
        <div style={{ marginTop: "8px", display: 'flex', gap: 5, alignItems: 'center' }}>
          <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              views={['year', 'month']}
              variant="inline"
              maxDate={Date()}
              inputVariant="outlined"
              // style={{ backgroundColor: '#fff' }}
              size="small"
              format="MM/yyyy"
              InputProps={{ readOnly: true }}
              InputAdornmentProps={{ position: 'start' }}
              value={selectedDate}
              onChange={date => handleDateChange(date)}
            />
          </MuiPickersUtilsProvider>
          <Button
            style={{ height: 45 }}
            onClick={handleEdit}
            color="primary"
            variant="contained"
            label="Comparar Valores"
          />
        </div>
      </Grid>

      <Grid style={{ marginBottom: "20px", marginLeft: "10px", width: "100%" }}>
        <ResumTable
          docs={dataCategory}
          setDocs={setdataCategory}
          setSelected={setSelected}
          selected={selected}
        />

      </Grid>

      <Dialog
        onClose={() => setOpenModalPayment(false)}
        open={openModalPayment}
        fullWidth
        maxWidth="md"

      >
        <Loading open={DialogLoading} msg="Buscando relatório..." />
        <DialogTitle id="dialog-resum-title">
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: "5px"
            }}
          >
            <Grid>Comparação mensal</Grid>
          </Grid>

        </DialogTitle>
        <Grid
          style={{
            display: 'flex',
            marginTop: "5px",
            marginLeft: "260px"
          }}
        >
          <h1 style={{ fontSize: "15px" }}>Data Inicial</h1>
          <h1 style={{ fontSize: "15px", marginLeft: "150px" }}>Data Final</h1>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: "25%",
            marginTop: "10px"

          }}>
          <Grid>
            <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                views={['year', 'month']}
                variant="inline"
                maxDate={Date()}
                inputVariant="outlined"
                style={{ backgroundColor: '#fff' }}
                size="small"
                format="MM/yyyy"
                InputProps={{ readOnly: true }}
                InputAdornmentProps={{ position: 'start' }}
                value={openDataDialog}
                onChange={date => setopenDataDialog(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            style={{
              marginLeft: "3px"
            }}>
            <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils} >
              <KeyboardDatePicker
                autoOk
                views={['year', 'month']}
                variant="inline"
                maxDate={Date()}
                inputVariant="outlined"
                style={{ backgroundColor: '#fff' }}
                size="small"
                format="MM/yyyy"
                InputProps={{ readOnly: true }}
                InputAdornmentProps={{ position: 'start' }}
                value={openDataDialogF}
                onChange={date => setopenDataDialogF(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid style={{ marginLeft: "290px" }}>
          <Button
            style={{ height: 40, margin: "30px", marginLeft: "100px" }}
            onClick={BuscaMetodos}
            color="primary"
            variant="contained"
            label="Comparar" />
        </Grid>
        <Grid style={{ margin: "20px" }}>
          <TableDialog style={{ size: "50%" }}
            docs={dataCompare}
            setDocs={setdataCompare}
            tablekey={tablekey}
            setSelected={setSelected}
            selected={selected}
          />
        </Grid>
      </Dialog >
    </>
  );
}
