/* eslint-disable camelcase */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';

import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
} from '@material-ui/core';
import ExportExcel from './ExportExcel';


function createData(invoice_number,order_number,client_name,amount,date_payment, date_conciliation) {
  return {
    invoice_number,
    order_number,  
    client_name,
    amount,
    date_payment,
    date_conciliation,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#303474",
    color: "white",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function ReportTable({
  docs,
}) {
  const [dataExcel, setDataExcel] = useState();
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (docs) {
      const list = [];
      const listE =[];
      docs.forEach(d => {
        list.push(
          createData(
            d.invoice_number,
            d.order_number,  
            d.client_name,
            d.amount,
            d.date_payment, 
            d.date_conciliation             
          ),
        );
        listE.push({
          'Nota fiscal': d.invoice_number,
          'Pedido': d.order_number,
          'Cliente': d.client_name,
          'Valor Pago': Number(d.amount),
          'Data pagamento':moment(d.date_payment).format('DD/MM/YYYY')
        });

      });
      setDataExcel({Relatorio: listE})
      setRows(list);
    }
  },[docs]);
   
  const mountDataExcel = d => {
    return <ExportExcel allData={d} />;
  };

  return (
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Nota Fiscal</StyledTableCell>
                <StyledTableCell>Pedido</StyledTableCell>
                <StyledTableCell>Cliente</StyledTableCell>
                <StyledTableCell>Valor conciliado</StyledTableCell>
                <StyledTableCell>Data conciliação</StyledTableCell>
                <StyledTableCell> <div style={{ display: 'flex'}}>
                    Data pagamento {dataExcel && mountDataExcel(dataExcel)}
                  </div></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.invoice_number}>
                <StyledTableCell>{row.invoice_number}</StyledTableCell>
                <StyledTableCell>{row.order_number}</StyledTableCell>
                <StyledTableCell>{row.client_name}</StyledTableCell>
                <StyledTableCell> {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
                  .format(row.amount)
                  .replace(',', '.')}
                </StyledTableCell>
                <StyledTableCell>
                  {moment(row.date_conciliation).format('DD/MM/YYYY')}
                </StyledTableCell>
                <StyledTableCell>
                  {moment(row.date_payment).format('DD/MM/YYYY')}
                </StyledTableCell>
              </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
  );
}
