/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import GetApp from '@material-ui/icons/GetApp';
import {
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
} from '@material-ui/core';
import api from '../../../../service/axios/api';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#303474",
    color: "white",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// eslint-disable-next-line import/prefer-default-export
export function LiberationTable({
  docs,
  page,
  setPage,
  itens,
  setItens,
  total,
  templateDownload,
}) {
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Tipo Produto</StyledTableCell>
              <StyledTableCell>NF/ND</StyledTableCell>
              <StyledTableCell>Faturado</StyledTableCell>
              <StyledTableCell>Data Liberação</StyledTableCell>
              <StyledTableCell>Data conciliação</StyledTableCell>
              <StyledTableCell>Banco</StyledTableCell>
              <StyledTableCell>Proxy</StyledTableCell>
              <StyledTableCell>Premiado CPF/CNPJ</StyledTableCell>
              <StyledTableCell>Premiado Nome</StyledTableCell>
              <StyledTableCell> <div style={{ display: 'flex', justifyContent: 'center' }}>
                Valor <GetApp onClick={templateDownload} style={{cursor: 'pointer'}}/>
              </div></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.map((row) => {
              return (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{row.product_type}</StyledTableCell>
                  <StyledTableCell>{row.invoice_number}</StyledTableCell>
                  <StyledTableCell>{row.company_name}</StyledTableCell>
                  <StyledTableCell>{row.date_processing && moment(row.date_processing).format('DD/MM/YYYY HH:mm')}</StyledTableCell>
                  <StyledTableCell>{row.date_conciliation && moment(row.date_conciliation).format('DD/MM/YYYY HH:mm')}</StyledTableCell>
                  <StyledTableCell>{row.bank_number}</StyledTableCell>
                  <StyledTableCell>{row.proxy}</StyledTableCell>
                  <StyledTableCell>{row.cpf_cnpj}</StyledTableCell>
                  <StyledTableCell>{row.name}</StyledTableCell>
                  <StyledTableCell> {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                    .format(row.subtotal)
                    .replace(',', '.')}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
        component="div"
        count={total}
        rowsPerPage={itens}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
