/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import { Button, DialogActions, Grid, TextField } from '@material-ui/core';
import Select from 'react-select';
import React, { useState, useContext } from 'react';
import MyButton from '../../components/button/MyButton';
import SelectAsyncPaginate from './SelectAsyncUsers';
import FlashContext from '../../components/contexts/FlashContext';
import Loading from '../../components/loading/Loading';
import api from '../../service/axios/api';

const styleProps = {
  style: {
    height: '32px',
    padding: '0 14px',
  },
};

const stateOptions = [
  {
    label: 'AC',
    value: 'AC',
  },
  {
    label: 'AP',
    value: 'AP',
  },
  {
    label: 'AM',
    value: 'AM',
  },
  {
    label: 'BA',
    value: 'BA',
  },
  {
    label: 'CE',
    value: 'CE',
  },
  {
    label: 'DF',
    value: 'DF',
  },
  {
    label: 'ES',
    value: 'ES',
  },
  {
    label: 'GO',
    value: 'GO',
  },
  {
    label: 'MA',
    value: 'MA',
  },
  {
    label: 'MT',
    value: 'MT',
  },
  {
    label: 'MS',
    value: 'MS',
  },
  {
    label: 'MG',
    value: 'MG',
  },
  {
    label: 'PA',
    value: 'PA',
  },
  {
    label: 'PB',
    value: 'PB',
  },
  {
    label: 'PR',
    value: 'PR',
  },
  {
    label: 'PE',
    value: 'PE',
  },
  {
    label: 'PI',
    value: 'PI',
  },
  {
    label: 'RJ',
    value: 'RJ',
  },
  {
    label: 'RN',
    value: 'RN',
  },
  {
    label: 'RS',
    value: 'RS',
  },
  {
    label: 'RO',
    value: 'RO',
  },
  {
    label: 'RR',
    value: 'RR',
  },
  {
    label: 'SC',
    value: 'SC',
  },
  {
    label: 'SP',
    value: 'SP',
  },
  {
    label: 'SE',
    value: 'SE',
  },
  {
    label: 'TO',
    value: 'TO',
  },
];

export default function NewCompanyForm({ cancelNew }) {
  const { notifyError } = useContext(FlashContext);
  const [agent, setAgent] = useState();
  const [company, setCompany] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [responsible, setResponsible] = useState('');
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [celphone, setCelphone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState();
  const [postalCode, setPostalCode] = useState('');

  const [loading, setLoading] = useState(false);
  // const [msg, setMsg] = useState('');

  const linkUser = async companyId => {
    for (let i = 0; i < agent.length; i++) {
      await api.companyAgent.linkUser(agent[i].value, companyId);
    }
    cleanAll();
  };

  const createCompany = () => {
    setLoading(true);
    api.companyAgent
      .create(
        company,
        cnpj,
        responsible,
        cpf,
        phone,
        celphone,
        email,
        postalCode,
        address,
        addressNumber,
        complement,
        district,
        city,
        uf?.value,
      )
      .then(res => {
        if (agent) {
          if (agent.length > 0) {
            linkUser(res.data.retorno.id);
          }
        } else {
          cleanAll();
        }
      })
      .catch(err => {
        notifyError(err.message);
        setLoading(false);
      });
  };

  const cleanAll = () => {
    setLoading(false);
    setCompany('');
    setCnpj('');
    setResponsible('');
    setCpf('');
    setAddress('');
    setAddressNumber('');
    setComplement('');
    setCity('');
    setUf();
    setAgent();
    cancelNew();
  };

  return (
    <>
      <Loading open={loading} msg="Cadastrando nova empresa..." />
      <form onSubmit={createCompany}>
        <Grid
          container
          spacing={1}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            padding: 5,
          }}
        >
          <Grid item xs={12} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <h6>Nome Empresa</h6>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={styleProps}
                  value={company}
                  onChange={e => setCompany(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h6>CNPJ:</h6>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={styleProps}
                  value={cnpj}
                  onChange={e => setCnpj(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h6>Responsável:</h6>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={styleProps}
                  value={responsible}
                  onChange={e => setResponsible(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h6>CPF:</h6>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={styleProps}
                  value={cpf}
                  onChange={e => setCpf(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <h6>Telefone:</h6>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={styleProps}
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <h6>Celular:</h6>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={styleProps}
                  value={celphone}
                  onChange={e => setCelphone(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <h6>E-mail:</h6>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={styleProps}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <h6>Endereço:</h6>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={styleProps}
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <h6>Número:</h6>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={styleProps}
                  value={addressNumber}
                  onChange={e => setAddressNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h6>Complemento:</h6>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={styleProps}
                  value={complement}
                  onChange={e => setComplement(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <h6>Bairro:</h6>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={styleProps}
                  value={district}
                  onChange={e => setDistrict(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <h6>Cidade:</h6>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={styleProps}
                  value={city}
                  onChange={e => setCity(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <h6 style={{ marginBottom: 3 }}>UF:</h6>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={uf}
                  onChange={setUf}
                  placeholder=""
                  menuPlacement="auto"
                  options={stateOptions}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <h6>CEP:</h6>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  inputProps={styleProps}
                  value={postalCode}
                  onChange={e => setPostalCode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <h6 style={{ marginBottom: 3 }}>Agentes:</h6>
                <SelectAsyncPaginate
                  value={agent}
                  onChange={setAgent}
                  menuPlacement="auto"
                />
                {/* <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder=""
                // value={itens}
                // onChange={setItens}
                menuPlacement="auto"
                options={[]}
              /> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* </form> */}
        <DialogActions>
          <Button onClick={cleanAll} color="secondary">
            Cancelar
          </Button>
          <MyButton
            style={{ width: 120, height: 40 }}
            onClick={createCompany}
            label="CADASTRAR"
          />
        </DialogActions>
      </form>
    </>
  );
}
