/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import SelectOptions from './SelectOptions';
import ExtractTable from './ExtractTable';
import api from '../../../../service/axios/api';

export default function Entries({ setLoading, setMsg }) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [itens, setItens] = useState(10);
  const [selected, setSelected] = useState([]);
  const [bankSelected, setBankSelected] = useState({
    label: 'Todos',
    value: '',
  });

  const [chipData, setChipData] = useState([]);
  console.log(chipData)
  const searchData = extra => {
    const cancel = new AbortController();
    setLoading(true);
    setMsg('Buscando extrato...');
    api.statement
      .get(extra, chipData, bankSelected, cancel, itens, page + 1)
      .then(res => {
        setData(res.docs);
        setTotal(res.total);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    searchData();
  }, [page, itens, bankSelected]);

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ display: 'flex', justifyContent: 'start' }}
      >
        <h3>Relatório Entradas</h3>
      </Grid>
      <SelectOptions
        setStatus={setBankSelected}
        setChipData={setChipData}
        chipData={chipData}
        find={searchData}
      />

      <Grid item xs={12} sm={12}>
        <ExtractTable
          docs={data}
          setDocs={setData}
          page={page}
          setPage={setPage}
          itens={itens}
          setItens={setItens}
          total={total}
          setSelected={setSelected}
          selected={selected}
          searchData={searchData}
        />
      </Grid>
    </>
  );
}
