/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import * as React from 'react';
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  styled,
  TableCell,
  Fade,
  Button
} from '@material-ui/core';
import Select from 'react-select';
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import styles from '../../../styles/Layout.module.css';

const options = [
  {
    label: 'JoyCard',
    value: 1,
  },
];

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#eb3417',
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#ffbfbd',
      color: '#ffbfbd',
    },
  },
}))(TableRow);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#41b490',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#41b490',
  },
});

export default function DisplayErrorDocument({ errors, setModalError }) {
  const { t } = useTranslation();
  return (
    <div style={{maxHeight: '400px', textAlign: 'center'}}>
      <h6 className={styles.labelGridSecond} style={{color: 'red', marginLeft: 15, textAlign: 'center'}}>
        Os seguintes erros foram encontrados em sua planilha e serão <b>RETIRADOS</b> do pedido
      </h6>
      <Grid
        container
        spacing={1}
        direction="column"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: 10,
        }}
      >
        <Grid item>
        <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
        <TableHead>
      <TableRow>
        <StyledTableCell component="th">Nome</StyledTableCell>
        <StyledTableCell component="th">Documento</StyledTableCell>
        <StyledTableCell component="th">Motivo</StyledTableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        {errors?.map((p)=>(
           <StyledTableRow tabIndex={-1} key={p.doc}>
           <StyledTableCell
             component="th"
            //  id={labelId}
             scope="row"
           >
             {p.name}
           </StyledTableCell>
           <StyledTableCell>
            {p.doc}
           </StyledTableCell>
           <StyledTableCell>
            {p.motivo}
           </StyledTableCell>
           </StyledTableRow>
        ))}
        </TableBody>
        </Table>
        </TableContainer>
         
        </Grid>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'end', margin: 15}}>
          <Button color="primary" variant='contained' onClick={()=> setModalError(false)}>
          Fechar
        </Button>
      </div>
    </div>
  );
}
