/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// import { useHistory } from 'react-router-dom';

import {
  Button,
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  DialogContent,
  // Grid,
  Checkbox,
  styled,
} from '@material-ui/core';
// import mastercard from './img/mastercard.svg';
// import moment from 'moment-timezone';

import api from '../../service/axios/api';
// import routing from '../../service/routes/routing';
import Loading from '../../components/loading/Loading';

import Status from '../../service/translateStatus';
import FlashContext from '../../components/contexts/FlashContext';
import CardDetails from './DetailsCards';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

function createData(
  id,
  cpf,
  name,
  proxy,
  order,
  process,
  conciliation,
  qtd,
  value,
  status,
  brand,
  product,
  all,
) {
  return {
    id,
    name,
    cpf,
    status,
    proxy,
    order,
    process,
    conciliation,
    qtd,
    value,
    brand,
    product,
    all,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

// const StyledTableCell = withStyles(() => ({
//   head: {
//     // backgroundColor: theme.palette.common.black,
//     // color: theme.palette.common.white,
//   },
//   body: {
//     fontSize: 14,
//   },
// }))(TableCell);

// const StyledTableRow = withStyles(theme => ({
//   root: {
//     '&:nth-of-type(odd)': {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
// }))(TableRow);
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

function EnhancedTableHead(props) {
  // eslint-disable-next-line no-unused-vars
  const { onSelectAllClick, numSelected, rowCount, onRequestSort } = props;
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell component="th">
          {t('baseCards.table.brand')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {t('baseCards.table.product')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {t('baseCards.table.name')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {t('baseCards.table.cpf')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {t('baseCards.table.proxy')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {t('baseCards.table.status')}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Checkbox
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

const cancelStyle = {
  cursor: 'pointer',
  color: '#657588',
  fontWeight: 'bold',
  fontFamily: 'Open Sans',
  marginTop: 5,
};

export default function CardsTable({
  docs,
  page,
  setPage,
  itens,
  setItens,
  total,
  openCancel,
  setOpenCancel,
  openActiv,
  setOpenActiv,
  openBlock,
  setOpenBlock,
  selected,
  setSelected,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  // const [selected, setSelected] = useState([]);
  const { notifyError, notifySuccess } = useContext(FlashContext);
  const [openDetails, setOpenDetails] = useState(false);
  const [details, setDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    const list = [];
    docs.forEach(d => {
      list.push(
        createData(
          d.id,
          d.Individual?.cpf,
          d.Individual?.name,
          d.proxy,
          d.pedido,
          d.processamento,
          d.conciliacao,
          d.qtd,
          d.valor,
          d.status,
          d.brand,
          d.product,
          d,
        ),
      );
    });
    setRows(list);
  }, [docs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setItens(event.target.value);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const isSelected = id => selected.indexOf(id) !== -1;

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  function alterData(st) {
    const orig = _.cloneDeep(rows);
    selected.forEach(s => {
      const found = orig.find(e => e.id === s);
      found.status = st;
      setRows(orig);
    });
  }

  const cancelAction = action => {
    notifySuccess(t('baseCards.table.actionCancelMsg'));
    if (action === 'cancel') {
      setOpenCancel(false);
    } else if (action === 'activ') {
      setOpenActiv(false);
    } else if (action === 'block') {
      setOpenBlock(false);
    }
  };

  const blockCards = () => {
    setMsg(t('baseCards.table.blockCardMsg'));
    setLoading(true);
    api.cards
      .changeStatus(selected, 'Blocked')
      .then(() => {
        alterData('Blocked');
        setOpenBlock(false);
        notifySuccess(t('baseCards.table.blockCardSeccessMsg'));
      })
      .catch(err => {
        notifyError(err.message);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const activCards = () => {
    setMsg(t('baseCards.table.ativCardMsg'));
    setLoading(true);
    api.cards
      .changeStatus(selected, 'Active')
      .then(() => {
        alterData('Active');
        setOpenActiv(false);
        notifySuccess(t('baseCards.table.ativCardSuccessMsg'));
      })
      .catch(err => {
        notifyError(err.message);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const canceledCards = () => {
    setMsg(t('baseCards.table.cancelCardMsg'));
    setLoading(true);
    api.cards
      .changeStatus(selected, 'Canceled')
      .then(() => {
        alterData('Canceled');
        setOpenCancel(false);
        notifySuccess(t('baseCards.table.cancelCardSuccessMsg'));
      })
      .catch(err => {
        notifyError(err.message);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function returnTextCanc(text) {
    const tmp = rows.find(r => r.id === text);
    return (
      <p>{`${t('baseCards.table.textCancel')} "${tmp.proxy}"
      ${t('baseCards.table.textCancel1')} "${tmp.name}", ${t(
        'baseCards.table.textCancel2',
      )} "${tmp.cpf}"?`}</p>
    );
  }

  function returnTextAtiv(text) {
    const tmp = rows.find(r => r.id === text);
    return (
      <p>{`${t('baseCards.table.textAtiv')} "${tmp.proxy}"
      ${t('baseCards.table.textCancel1')} "${tmp.name}", ${t(
        'baseCards.table.textCancel2',
      )} "${tmp.cpf}"?`}</p>
    );
  }

  function returnTextBlock(text) {
    const tmp = rows.find(r => r.id === text);
    return (
      <p>{`${
        tmp.status === 'Blocked'
          ? t('baseCards.table.textAtiv')
          : t('baseCards.table.textBlock')
      } "${tmp.proxy}"
      ${t('baseCards.table.textCancel1')} "${tmp.name}", ${t(
        'baseCards.table.textCancel2',
      )} "${tmp.cpf}"?`}</p>
    );
  }

  const viewDetails = dt => {
    setDetails(dt);
    setOpenDetails(true);
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, i) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${i}`;

                return (
                  <StyledTableRow
                    tabIndex={-1}
                    key={row.id}
                    style={{ cursor: 'pointer' }}
                    onDoubleClick={() => viewDetails(row.all)}
                  >
                    <StyledTableCell>
                      {row.brand === 'mastercard' ? (
                        <img
                          src="./img/mastercard.svg"
                          alt="mastercard"
                          style={{ height: 40 }}
                        />
                      ) : row.brand === 'visa' ? (
                        <img
                          src="./img/visa.svg"
                          alt="visa"
                          style={{ height: 40 }}
                        />
                      ) : row.brand === 'cabal' ? (
                        <img
                          src="./img/cabal.svg"
                          alt="cabal"
                          style={{ height: 40 }}
                        />
                      ) : null}
                    </StyledTableCell>
                    <StyledTableCell>{row.product}</StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.cpf}</StyledTableCell>
                    <StyledTableCell>{row.proxy}</StyledTableCell>
                    <StyledTableCell>{row.status === 'AwaitingRelease' ? 'Aguardando Status do cartão' :Status(row.status, t)}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Checkbox
                        color="primary"
                        onClick={event => handleClick(event, row.id)}
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
          component="div"
          count={total}
          rowsPerPage={itens}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* MODAL DE CANCELAMENTO */}
      <Dialog
        onClose={() => cancelAction('cancel')}
        open={openCancel}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg={msg} />
        <DialogTitle id="simple-dialog-title">
          {t('baseCards.table.cancelTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selected.map(s => returnTextCanc(s))}
            {/* {`Você confirma a "ATIVAÇÃO" do cartão de Proxy "${'192031823123'}"
            pertencente a "${'Nome'}", CPF "${'22222222222'}"?`} */}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6 style={cancelStyle} onClick={() => cancelAction('cancel')}>
            {t('baseCards.table.cancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={canceledCards}
            color="primary"
            variant="contained"
          >
            {t('baseCards.table.yesBtn')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL DE ATIVAÇÃO */}
      <Dialog
        onClose={() => cancelAction('activ')}
        open={openActiv}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg={msg} />
        <DialogTitle id="simple-dialog-title">
          {t('baseCards.table.ativTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selected.map(s => returnTextAtiv(s))}
            {/* {`Você confirma o "CANCELAMENTO" do cartão de Proxy "${'192031823123'}"
            pertencente a "${'Nome'}", CPF "${'22222222222'}"?`} */}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6 style={cancelStyle} onClick={() => cancelAction('activ')}>
            {t('baseCards.table.cancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={activCards}
            color="primary"
            variant="contained"
          >
            {t('baseCards.table.yesBtn')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL DE BLOQUEIO */}
      <Dialog
        onClose={() => cancelAction('block')}
        open={openBlock}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg={msg} />
        <DialogTitle id="simple-dialog-title">
          {t('baseCards.table.blockTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selected.map(s => returnTextBlock(s))}
            {/* {`Você confirma o "BLOQUEIO" do cartão de Proxy "${'192031823123'}"
            pertencente a "${'Nome'}", CPF "${'22222222222'}"?`} */}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6 style={cancelStyle} onClick={() => cancelAction('block')}>
            {t('baseCards.table.cancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={blockCards}
            color="primary"
            variant="contained"
          >
            {t('baseCards.table.yesBtn')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL DE DETALHES */}
      <Dialog
        onClose={() => setOpenDetails(false)}
        open={openDetails}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <CardDetails details={details} />
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              height: 40,
              width: 120,
              backgroundColor: '#41b490',
              color: 'white',
            }}
            onClick={() => setOpenDetails(false)}
            variant="contained"
          >
            {t('baseCards.table.okBtn')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
