/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */

/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Filter from './Filter';
import { OverviewTable } from './OverviewTable';
import api from '../../../../service/axios/api';
import FlashContext from '../../../../components/contexts/FlashContext';


export function Overview({ setLoading, setMsg }) {

    const [dataCategory, setdataCategory] = useState([]);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [itens, setItens] = useState(10);
    const [selected, setSelected] = useState([]);
    const { notifyError } = useContext(FlashContext)
    const [chipData, setChipData] = useState([]);
    const [hasRemainingAmount, sethasRemainingAmount] = useState("all");


    useEffect(() => {
        searchData()
    }, []);

    const searchData = extra => {
        setLoading(true)
        api.order.summaryOverview(chipData, page + 1, itens, extra, hasRemainingAmount)
            .then((res) => {
                setTotal(res.data.total)
                setdataCategory(res.data.docs)
                setLoading(false)
            })
            .catch((error) => { console.log(error) })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        searchData();
    }, [page, itens, hasRemainingAmount]);

    const templateDownload = () => {
        setMsg('Gerando planilha....');
        setLoading(true);
        api.order
            .summaryOverviewXls(chipData)
            .then(res => {
                const url = res.data.retorno.link;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'carga_template.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                if (err?.response?.data) {
                    if (err.response.data.Problems) {
                        err.response.data.Problems.forEach((e) => {
                            notifyError(e.Message);
                        });
                    } else {
                        notifyError(err.response.data.Message);
                    }
                } else if (err.message) {
                    if (err.message === "Network Error") {
                        notifyError(
                            "Erro de conexão, verifique sua conexão com a internet e tente novamente"
                        );
                    } else {
                        notifyError(err.message);
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Grid
                item
                xs={12}
                sm={12}
                style={{ display: 'flex', justifyContent: 'start' }}
            >
                <h3>Resumo</h3>
            </Grid>
            <Filter
                setChipData={setChipData}
                chipData={chipData}
                find={searchData}
                sethasRemainingAmount={sethasRemainingAmount}
                hasRemainingAmount={hasRemainingAmount}
                setPage={setPage}
            />
            <OverviewTable
                docs={dataCategory}
                setDocs={setdataCategory}
                page={page}
                setPage={setPage}
                itens={itens}
                setItens={setItens}
                total={total}
                setSelected={setSelected}
                selected={selected}
                templateDownload={templateDownload}
            />
        </>
    )
}
