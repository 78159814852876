/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
} from '@material-ui/core';
import ExportExcelR from './ExportExcelR';

function createData(monthA, monthB, category) {
  return {
    monthA,
    monthB,
    category
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {

  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#303474",
    color: "white",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function TableDialog({
  docs,
  tablekey,
}) {
  const [dataExcel, setDataExcel] = useState();
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [totmonth1, settotmonth1] = useState(0);
  const [totmonth2, settotmonth2] = useState(0);
  const listE = [];

  useEffect(() => {
    if (docs && tablekey?.length < 1) {
      // notifyError('Escolha meses diferentes');
    } else {
      const list = [];
      docs.forEach(d => {
        list.push(
          createData(
            Number.isNaN(d[tablekey[1]]) ? 0 : (d[tablekey[1]]),
            Number.isNaN(d[tablekey[2]]) ? 0 : (d[tablekey[2]]),
            d.category,
          ),
        );

        if (tablekey[1]) {
          listE.push({
            [tablekey[1]]: Number(Number.isNaN(d[tablekey[1]]) ? 0 : (d[tablekey[1]])),
            [tablekey[2]]: Number(Number.isNaN(d[tablekey[2]]) ? 0 : (d[tablekey[2]])),
            'Categoria': d.category,
          })
        } else {
          listE.push({
            'Mes Inicial': Number(d[tablekey[1]]),
            'Categoria': d.category,
          })
        }
      });
      setRows(list)
      totalmonth(list)
    }
  }, [docs, tablekey]);

  const totalmonth = (list) => {
    let controle = 0
    for (let i = 0; i < list.length; i++) {
      controle += Number(list[i].monthA)
    }
    settotmonth1(controle)
    controle = 0
    for (let i = 0; i < list.length; i++) {
      controle += Number(list[i].monthB)
    }
    settotmonth2(controle)
    listE.push({
      [`Total ${tablekey[1]}`]: totmonth1,
      [`Total ${tablekey[2]}`]: controle,
    })
    setDataExcel({ Relatorio: listE })

  }

  const mountDataExcel = d => {
    return <ExportExcelR allData={d} />;
  };
  return (
    <Paper className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            {tablekey[1] && <StyledTableCell style={{ backgroundColor: "#dad9f5", color: "#000000", border: "2px" }}>Total: {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
              .format(totmonth1)
              .replace(',', '.')}</StyledTableCell>}
            {tablekey[2] && <StyledTableCell style={{ backgroundColor: "#dad9f5", color: "#000000", border: "2px" }}>Total: {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
              .format(totmonth2)
              .replace(',', '.')}</StyledTableCell>}
            <TableRow>
              <StyledTableCell>{tablekey[1]}</StyledTableCell>
              {tablekey[2] && <StyledTableCell>{(tablekey[2])}</StyledTableCell>}
              <StyledTableCell> <div style={{ display: 'flex', justifyContent: 'center' }}>
                Categoria {tablekey[1] && dataExcel && mountDataExcel(dataExcel)}
              </div></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <StyledTableRow key={i}>
                  <StyledTableCell> {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                    .format(row.monthA)
                    .replace(',', '.')}</StyledTableCell>
                  {tablekey[2] &&
                    <StyledTableCell> {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                      .format(row.monthB)
                      .replace(',', '.')}</StyledTableCell>}
                  <StyledTableCell>{row.category}</StyledTableCell>
                </StyledTableRow>
              );
            })}

          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
