/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
} from '@material-ui/core';

function createData(amount, category) {
  return {
    amount,
    category,
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 400,
  },
  table: {
    minWidth: 500,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#303474",
    color: "#fff",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);



const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function TableTop({
  docs,
}) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (docs) {
      const list = [];
      docs.forEach(d => {
        list.push(
          createData(
            d.amount,
            d.category
          ),
        );
      });
      setRows(list);
    }
  }, [docs])

  return (
    <Paper className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Categoria</StyledTableCell>
              <StyledTableCell>Valor</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <StyledTableRow key={row.category}>
                  <StyledTableCell>{row.category}</StyledTableCell>
                  <StyledTableCell> {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                    .format(row.amount)
                    .replace(',', '.')}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
