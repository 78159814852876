/* eslint-disable no-nested-ternary */
/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';

import {
    TextField,
    Grid,
    Button,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import AddIcon from '@material-ui/icons/Add';
import FlashContext from '../../../../components/contexts/FlashContext';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: 10,
        margin: 0,
    },
    chip: {
        margin: 10,
    },
    myTextField: {
        backgroundColor: 'none',
        boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
        border: 'none',
        color: '#717171',
        fontWeight: 'bold',
        fontFamily: 'Open Sans',
    },
    text: {
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        color: '#717171',
    },
    input: {
        backgroundColor: '#fff',
        marginTop: 0,
    },
}));

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function Filter({ chipData, setChipData, find, sethasRemainingAmount, setPage }) {
    const { t } = useTranslation();
    const local = useLocation();
    const [searchType, setSearchType] = useState();
    const { notifyError } = useContext(FlashContext);
    const [selectedDate, handleDateChange] = useState(new Date());
    const classes = useStyles();
    const searchRef = useRef(null);
    const [equal, setEqual] = useState(true)
    const [closeMonth, setCloseMonth] = useState(false)
    const [formatDate, setFormatDate] = useState('dd/MM/yyyy')
    const [valueRadio, setvalueRadio] = useState(
        local.search.substring(local.search.indexOf('?') + 1, 3) || 'all',
    )

    const handleDelete = chipToDelete => () => {
        setOptions([
            ...options,
            { label: chipToDelete.by, value: chipToDelete.byType },
        ]);
        setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
    };

    const internSearch = () => {
        if (searchType?.valeu !== '' && searchRef?.current?.value !== '') {
            includeSearch(true);
        } else {
            find();
        }
    };

    const [options, setOptions] = useState([
        {
            label: 'CNPJ',
            value: 'companyCnpj',
        },
        {
            label: 'Cliente',
            value: 'companyName',
        },
        {
            label: 'Data referência',
            value: 'date',
        },
        {
            label: 'Pedido',
            value: 'orderNumber',
        },
        {
            label: 'ND',
            value: 'debitNote',
        },
        {
            label: 'NF',
            value: 'invoice',
        },
    ]);


    const includeSearch = btn => {
        try {
            const orig = _.cloneDeep(options);
            setChipData([
                ...chipData,
                {
                    key: chipData.length + 1,
                    label: searchType.value === 'date' ? moment(selectedDate).format('DD/MM/YYYY') : (searchRef?.current?.value.trim()),
                    by: searchType.label,
                    byType: searchType.value,
                    date: selectedDate,
                },
            ]);
            const myfind = orig.findIndex(e => e.value === searchType.value);
            orig.splice(myfind, 1);
            setSearchType(null);
            setOptions(orig);
            if (btn) {
                find({
                    label: searchType.value === 'date' ? moment(selectedDate).format('DD/MM/YYYY') : (searchRef?.current?.value.trim()),
                    byType: searchType.value,
                    date: selectedDate
                });
            }

        } catch (e) {
            notifyError("Escolha um Tipo")
        }
        searchRef.current.value = '';

    };

    const addChip = () => {
        if (searchType) {
            includeSearch(false);
        } else {
            notifyError(t('contract.filters.typeSearchMsg'));
        }
    };

    const teclaEnter = e => {
        if (e.key === 'Enter') {
            internSearch();
        }
    };

    const handleTypeClient = e => {
        setvalueRadio(e)
        switch (e) {
            case "withValue":
                sethasRemainingAmount(true)
                break;
            case "noValue":
                sethasRemainingAmount(false)
                break;
            case "all":
                sethasRemainingAmount('')
                break;
            default:
                setPage(0);
        }
    };


    return (
        <><Grid
            container
            style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 30,
                marginTop: 15,
            }}
        >
            <Grid item xs={12} sm={4}>
                <Grid container spacing={0} style={{ justifyContent: 'start', marginLeft: "10px" }}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={valueRadio}
                            onChange={e => handleTypeClient(e.target.value)}
                        >
                            <FormControlLabel
                                value="all"
                                control={
                                    <Radio classes={{
                                        root: classes.radio,
                                        checked: classes.checked,
                                    }}
                                    />
                                } label="Todas" />
                            <FormControlLabel
                                value="noValue"
                                control={
                                    <Radio classes={{
                                        root: classes.radio,
                                        checked: classes.checked,
                                    }}
                                    />
                                } label="Zeradas" />
                            <FormControlLabel
                                value="withValue"
                                control={<Radio classes={{
                                    root: classes.radio,
                                    checked: classes.checked,
                                }}
                                />
                                } label="Com saldo" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={4} sm={8}>
                <Grid container spacing={1} style={{ justifyContent: 'end' }}>
                    <Grid item>
                        <Select
                            fullWidth
                            onChange={setSearchType}
                            value={searchType}
                            classNamePrefix="select"
                            placeholder={t('contract.filters.type')}
                            isSearchable={false}
                            options={options}
                            menuPortalTarget={document.body}
                            style={{ zIndex: 999 }} />
                    </Grid>
                    <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                        {searchType?.value === 'date' ? (
                            <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // open={formatDate === 'dd/MM/yyyy'}
                                    open={closeMonth}
                                    onClick={() => {
                                        console.log('clicke')
                                        setCloseMonth(true)
                                    }}
                                    disableFuture
                                    autoOk
                                    variant="inline"
                                    inputVariant="outlined"
                                    style={{ backgroundColor: '#fff' }}
                                    views={formatDate === 'dd/MM/yyyy' ? ['date'] : ['month']}
                                    size="small"
                                    format={formatDate}
                                    value={selectedDate}
                                    InputProps={{ readOnly: true }}
                                    InputAdornmentProps={{ position: 'start' }}
                                    onMonthChange={async (date) => {
                                        if (date.getMonth() === new Date().getMonth()) {
                                            // eslint-disable-next-line no-lonely-if
                                            if(!equal){
                                                setFormatDate('dd/MM/yyyy')
                                                setEqual(true)
                                                setCloseMonth(false)
                                                await sleep(500)
                                                setCloseMonth(true)
                                            }
                                            
                                        } else {
                                            // eslint-disable-next-line no-lonely-if
                                            if (equal) {
                                                setEqual(false)
                                                setFormatDate('MM/yyyy')
                                                handleDateChange(date)
                                                setCloseMonth(false)
                                                await sleep(500)
                                                setCloseMonth(true)
                                            }
                                        }
                                    }}
                                    onChange={date => {
                                        handleDateChange(date)
                                        setCloseMonth(false)
                                    }} />
                            </MuiPickersUtilsProvider>
                        ) : (
                            <TextField
                                onKeyUpCapture={e => teclaEnter(e)}
                                inputRef={searchRef}
                                color="secondary"
                                width="400px"
                                className={classes.textField}
                                fullWidth
                                size="small"
                                style={{ maxHeight: 10, minWidth: 300 }}
                                variant="outlined"
                                InputProps={{
                                    className: classes.input,
                                }} />
                        )}

                        <Button
                            onClick={addChip}
                            variant="contained"
                            style={{ marginLeft: 5, marginRight: 5 }}
                        >
                            <AddIcon />
                        </Button>
                        <Button onClick={internSearch} variant="contained">
                            {t('contract.filters.btnSearch')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid > <Grid item xs={4} sm={6}>
                <Grid item className={classes.root}>
                    {chipData.map((dt, i) => {
                        let icon;

                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={i}>
                                <Chip
                                    icon={icon}
                                    label={`${dt.by} | ${dt.label}`}
                                    onDelete={handleDelete(dt)}
                                    className={classes.chip}
                                />
                            </li>
                        );
                    })}
                </Grid>
            </Grid>
        </>

    );
}

export default Filter;
