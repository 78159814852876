/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useContext } from 'react';
import Select from 'react-select';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';

import { TextField, Grid, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import AddIcon from '@material-ui/icons/Add';
import FlashContext from '../../contexts/FlashContext';

const customStyles1 = {
  control: provided => ({
    ...provided,
    width: 125,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 10,
    margin: 0,
  },
  chip: {
    margin: 10,
  },
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
  },
  noBorder: {
    border: 'none',
  },
}));

function SelectOptions({
  setStatus,
  chipData,
  setChipData,
  find,
  setOrderBy,
  product,
  setProduct,
}) {
  const { t } = useTranslation();
  const [searchType, setSearchType] = useState();
  const { notifyError, notifySuccess } = useContext(FlashContext);
  // const [chipData, setChipData] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const classes = useStyles();
  const searchRef = useRef(null);

  const internSearch = () => {
    if (searchType?.valeu !== '' && searchRef?.current?.value !== '') {
      includeSearch(true);
    } else {
      find();
    }
  };

  const statusOptions = [
    {
      label: 'Aguardando liberação do cartão',
      value: 'AwaitingCardRelease',
    },
    {
      label: 'Aguardando conciliação',
      value: 'AwaitingConciliation',
    },
    {
      label: 'Aguardando agendamento bancário',
      value: 'AwaitingBankScheduling',
    },
    {
      label: t('Order.filters.status.awaitingSettlement'),
      value: 'AwaitingSettlement',
    },
    {
      label: t('Order.filters.status.awaitingPayment'),
      value: 'AwaitingPayment',
    },
    {
      label: 'Aguardando Processamento',
      value: 'AwaitingProcessing',
    },
    {
      label: t('Order.filters.status.canceled'),
      value: 'Canceled',
    },
    {
      label: t('Order.filters.status.created'),
      value: 'Created',
    },
    {
      label: t('Order.filters.status.error'),
      value: 'Error',
    },
    {
      label: t('Order.filters.status.errors'),
      value: 'Errors',
    },
    {
      label: t('Order.filters.status.settled'),
      value: 'Settled',
    },
    {
      label: 'Processado',
      value: 'Processed',
    },
    {
      label: 'Processamento automático',
      value: 'AutoProcessing',
    },
    {
      label: 'Rejeitado',
      value: 'Rejected',
    },
    {
      label: 'Recebido',
      value: 'Received',
    },
    {
      label: 'Todos',
      value: '',
    },
  ];

  const orderOptions = [
    {
      label: 'Notas',
      value: 'invoice',
    },
    {
      label: 'Pedidos',
      value: 'order_number',
    },
  ];

  const [options, setOptions] = useState([
    {
      label: 'Nome',
      value: 'name',
    },
    {
      label: 'Nome PJ',
      value: 'namepj',
    },
    {
      label: 'CPF',
      value: 'cpf',
    },
    {
      label: 'CNPJ',
      value: 'cnpj',
    },
  ]);

  const handleDelete = chipToDelete => () => {
    setOptions([
      ...options,
      { label: chipToDelete.by, value: chipToDelete.byType },
    ]);
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  const includeSearch = btn => {
    if (searchType) {
      const orig = _.cloneDeep(options);
      setChipData([
        ...chipData,
        {
          key: chipData.length + 1,
          label: searchRef.current.value,
          by: searchType.label,
          byType: searchType.value,
          date: selectedDate,
        },
      ]);
      const myfind = orig.findIndex(e => e.value === searchType.value);
      orig.splice(myfind, 1);
      setSearchType(null);
      setOptions(orig);
      if (btn) {
        find({
          label: searchRef.current.value,
          byType: searchType.value,
          date: selectedDate,
        });
      }
      searchRef.current.value = '';
    } else {
      notifyError('Informe o tipo de busca');
    }
  };

  const addChip = () => {
    if (searchType) {
      includeSearch(false);
    } else {
      notifyError(t('Order.filters.searchErrorMessage'));
    }
  };

  const teclaEnter = e => {
    if (e.key === 'Enter') {
      internSearch();
    }
  };

  return (
    <>
      <Grid
        container
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          // marginBottom: 30,
          // marginTop: 15,
        }}
      >
        <Grid item xs={12} sm={4}>
          <Grid container spacing={0} style={{ justifyContent: 'start' }}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ marginLeft: 15, display: 'flex' }}
            >
              {/* <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <label>Ordenar</label>
              </Box>
              <Select
                styles={customStyles1}
                classNamePrefix="select"
                placeholder={t('Order.filters.type')}
                isSearchable={false}
                options={orderOptions}
                onChange={setOrderBy}
                menuPortalTarget={document.body}
              /> */}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{ marginLeft: 10 }}
              >
                {/* <Box flexGrow={1}> */}
                <label style={{ marginRight: 10 }}>
                  {t('Order.filters.statusLabel')}
                </label>
              </Box>
              {/* <Box ml={1} mt={1}> */}
              <Select
                // fullWidth
                styles={customStyles1}
                classNamePrefix="select"
                placeholder={t('Order.filters.type')}
                isSearchable={false}
                options={statusOptions}
                onChange={setStatus}
                // value={statusSelected}
                menuPortalTarget={document.body}
              // style={{ zIndex: 999, width: 600 }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={8}>
          <Grid container spacing={1} style={{ justifyContent: 'end' }}>
            <Grid item>
              <Select
                fullWidth
                styles={customStyles1}
                onChange={setSearchType}
                value={searchType}
                classNamePrefix="select"
                placeholder={t('Order.filters.type')}
                isSearchable={false}
                options={options}
                menuPortalTarget={document.body}
                style={{ zIndex: 999 }}
              />
            </Grid>
            <Grid
              item
              // xs={12}
              // sm={6}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {searchType?.value === 'datePayment' ? (
                <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    style={{ backgroundColor: '#fff' }}
                    // label="With keyboard"
                    size="small"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    InputProps={{ readOnly: true }}
                    InputAdornmentProps={{ position: 'start' }}
                    onChange={date => handleDateChange(date)}
                  />
                </MuiPickersUtilsProvider>
              ) : (
                <TextField
                  onKeyUpCapture={e => teclaEnter(e)}
                  inputRef={searchRef}
                  color="secondary"
                  width="400px"
                  className={classes.textField}
                  fullWidth
                  size="small"
                  style={{ maxHeight: 10, minWidth: 300 }}
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                    notchedOutline: classes.noBorder,
                  }}
                />
              )}

              <Button
                onClick={addChip}
                variant="contained"
                style={{ marginLeft: 5, marginRight: 5 }}
              >
                <AddIcon />
              </Button>
              <Button
                // style={{ marginLeft: 10, marginRight: 5 }}
                onClick={internSearch}
                variant="contained"
              >
                {/* {t('Product.table.category')} */}
                {t('Order.filters.btn')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} sm={6}>
        <Grid item className={classes.root}>
          {chipData.map((dt, i) => {
            let icon;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={i}>
                <Chip
                  icon={icon}
                  label={`${dt.by} | ${dt.label}`}
                  onDelete={handleDelete(dt)}
                  className={classes.chip}
                />
              </li>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}

export default SelectOptions;
