/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';

import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
} from '@material-ui/core';
import ExportExcelR from './ExportExcelR';

function createData(category, amount) {
  return {
    category,
    amount
  };
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  table: {
    minWidth: 1100,
  },
});

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#303474",
    color: "white",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function ResumTable({
  docs,
}) {
  const [dataExcel, setDataExcel] = useState();
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (docs) {
      const list = [];
      const listE = [];
      docs.forEach(d => {
        list.push(
          createData(
            d.category,
            d.amount
          ),
        );
        listE.push({
          'Nota fiscal': d.invoice_number,
          'Pedido': d.order_number,
          'Cliente': d.client_name,
          'Valor Pago': new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(d.amount),
          'Data': moment(d.paymentDate).format('DD/MM/YYYY')
        });

      });
      setDataExcel({ Relatorio: listE })
      setRows(list);
    }
  }, [docs]);

  const mountDataExcel = d => {
    return <ExportExcelR allData={d} />;
  };

  return (
    <Paper className={classes.root}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Valor</StyledTableCell>
              <StyledTableCell> <div style={{ display: 'flex', justifyContent: 'center' }}>
                Categoria {dataExcel && mountDataExcel(dataExcel)}
              </div></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <StyledTableRow key={i}>
                  <StyledTableCell>{row.category}</StyledTableCell>
                  <StyledTableCell> {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                    .format(row.amount)
                    .replace(',', '.')}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
