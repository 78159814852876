import React from 'react';
import {
  Box /* CircularProgress */,
  Grid,
  Typography,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import useStyles from './Loading.styles';

// eslint-disable-next-line react/prop-types
function SpinnerPage({ open, msg }) {
  const classes = useStyles();
  return (
    // <Box
    //   height="100vh"
    //   display="flex"
    //   justifyContent="center"
    //   alignItems="center"
    // >
    //   <CircularProgress color="secondary" />
    // </Box>
    <Backdrop style={{width:"100%"}} className={classes.backdrop} id="backdrop" open={open}>
      {/* <Box
        width="100vw"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src="./img/login/icon.svg"
          alt="icon"
          width="100"
          height="100"
          className={classes.logo}
        />
        <Box component="span" pl={7} />
        <img src="./img/login/text.svg" alt="name" width="200" height="100" />
      </Box>
      <Box>
        {msg && <Typography className={classes.tipografy}>{msg}</Typography>}
      </Box> */}
      <Grid
        container
        spacing={0}
        style={{ alignItems: 'center', justifyContent: 'center' }}
        id="name1"
      >
        <Grid
          item
          xs={12}
          alignItems="center"
          // justifyContent="center"
          container
          spacing={3}
          id="name2"
        >
          {/* <CircularProgress className={classes.circularColor} size={80} /> */}
          {/* <img className="image" alt="itFast" src="./itfast_aguia.png" /> */}
          <Box
            width="100vw"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img
              src="/img/login/icon.svg"
              alt="icon"
              width="100"
              height="100"
              className={classes.logo}
            />
            <img
              src="/img/login/Logo_Control_E_Text.png"
              alt="name"
              width="200"
              height="50"
            />
          </Box>
        </Grid>
        <Grid
          // className={classes.text}
          style={{ marginTop: 20 }}
          item
          xs={12}
          container
          // justifyContent="center"
          spacing={3}
        >
          <Box
            width="100vw"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {msg && (
              <Typography className={classes.tipografy}>{msg}</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Backdrop>
  );
}

export default SpinnerPage;
