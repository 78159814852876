/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ButtonGroup } from '@material-ui/core';
import Loading from '../../components/loading/Loading';
import Daile from './reportcomp/Dayle/Daile';
import Resum from './reportcomp/Resum/Resum';
import { Liberation } from './reportcomp/Liberation/Liberation';
import { Low } from './reportcomp/Low/Low';
import { Overview } from './reportcomp/Overview/Overview';

export function ReportAdmin() {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('Buscando arquivos...');
  const [pageType, setPageType] = useState(0);

  return (
    <>
      <Loading open={loading} msg={msg} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ButtonGroup color="primary">
            <Button
              onClick={() => setPageType(0)}
              variant={pageType === 0 ? 'contained' : 'outlined'}
            >
              DIÁRIO
            </Button>
            <Button
              onClick={() => setPageType(1)}
              variant={pageType === 1 ? 'contained' : 'outlined'}
            >
              MENSAL
            </Button>
            <Button
              onClick={() => setPageType(2)}
              variant={pageType === 2 ? 'contained' : 'outlined'}
            >
              LIBERAÇÕES
            </Button>
            <Button
              onClick={() => setPageType(3)}
              variant={pageType === 3 ? 'contained' : 'outlined'}
            >
              LIBERAÇÕES DO DIA
            </Button>
            <Button
              onClick={() => setPageType(4)}
              variant={pageType === 4 ? 'contained' : 'outlined'}
            >
              RESUMO
            </Button>
          </ButtonGroup>
        </Grid>

        {pageType === 0 && <Daile setLoading={setLoading} setMsg={setMsg} />}
        {pageType === 1 && <Resum setLoading={setLoading} setMsg={setMsg} />}
        {pageType === 2 && <Liberation setLoading={setLoading} setMsg={setMsg} />}
        {pageType === 3 && <Low setLoading={setLoading} setMsg={setMsg} />}
        {pageType === 4 && <Overview setLoading={setLoading} setMsg={setMsg} />}
      </Grid>
    </>
  );
}
