/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import './checkout.css';
import { Grid } from '@material-ui/core';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import { makeStyles, createStyles } from '@material-ui/core/styles';
// import Button from '@mui/material/Button';

const useStyles = makeStyles(theme =>
  createStyles({
    myButton: {
      color: '#2a3176',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
    },
    myGrid: {
      backgroundColor: '#f4f4f4',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
    },
    myGridSelected: {
      backgroundColor: '#41b490',
      margin: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      cursor: 'pointer',
    },
    myLabel: {
      padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
    },
    myLabelSelected: {
      padding: theme.spacing(6),
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
);

export default function UserType({ selected, setSelected, userClass }) {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={1}
      style={{
        border: '2px solid #c5c5c5',
        borderRadius: 10,
        justifyContent: 'center',
        padding: 40,
      }}
    >
      {/* {userClass !== 'cliente' && ( */}
      <Grid
        className={selected === 'adm' ? classes.myGridSelected : classes.myGrid}
        item
        xs={12}
        sm={8}
        md={2}
        lg={2}
        xl={2}
        onClick={() => setSelected('adm')}
      >
        {/* <Item sx={selected === 'element' && { backgroundColor:
          '#41b490', color: '#fff' }}>ADM</Item> */}
        <p
          className={
            selected === 'adm' ? classes.myLabelSelected : classes.myLabel
          }
        >
          ADM
        </p>
        {/* <Button className={classes.myButton} variant="text">ADM</Button> */}
      </Grid>
      {/* // )} */}

      <Grid
        className={
          selected === 'operador' ? classes.myGridSelected : classes.myGrid
        }
        item
        xs={12}
        sm={8}
        md={2}
        lg={2}
        xl={2}
        onClick={() => setSelected('operador')}
      >
        {/* <Button className={classes.myButton} variant="text">Operador</Button> */}
        <p
          className={
            selected === 'operador' ? classes.myLabelSelected : classes.myLabel
          }
        >
          Operador
        </p>
        {/* <Item sx={selected === 'franquia' && {
          backgroundColor: '#41b490', color: '#fff', marginRight: 30 }}>Operador</Item> */}
      </Grid>
    </Grid>
  );
}
