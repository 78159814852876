/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import ReportTable from './ReportTable';
import api from '../../../../service/axios/api';
import TableTop from './TableTop';

export default function Daile({ setLoading, setMsg }) {
  const [dat, setData] = useState([]);
  const [dataTop, setDataTop] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());

  useEffect(() => {
    setLoading(true);
    api.order.summaryDay(selectedDate.toISOString())
      .then((res) => { 
        setData(res.data.retorno.details) 
        setDataTop(res.data.retorno.topCategories)
        setLoading(false);
       })
      .catch((error) => { console.log(error) })
  }, [selectedDate])

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ display: 'flex', justifyContent: "space-between", width:"100% "}}
      >
        <h3>Relatório Diário</h3>
        <div>
        <h6>Data conciliação</h6>
        <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils} style={{ float: "right " }}>
          <KeyboardDatePicker
            autoOk
            variant="inline"
            maxDate={Date()}
            inputVariant="outlined"
            style={{ backgroundColor: '#fff' }}
            size="small"
            format="dd/MM/yyyy"
            InputProps={{ readOnly: true }}
            InputAdornmentProps={{ position: 'start' }}
            value={selectedDate}
            onChange={date => handleDateChange(date)}
          />
        </MuiPickersUtilsProvider>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} style={{ marginBottom: "20px" }}  >

        <h1 style={{ fontSize: "20px", marginTop: "20px", color: "#bbbbbd", fontStyle: "italic" }}>Maiores receitas do período</h1>
        <div style={{ display: "inline-block", width: "100%" }}>
          <TableTop
            docs={dataTop}
          />
        </div>
      </Grid>

      <Grid style={{ marginBottom: "20px", marginLeft:"10px", width: "100%" }}>
      <h1 style={{ fontSize: "20px", marginTop: "20px", color: "#bbbbbd", fontStyle: "italic" }}>Receitas do período</h1>
        <ReportTable
          docs={dat}
        />
      </Grid>
    </>
  );
}
