/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dialog, Avatar, MenuItem, Menu, Grid } from '@material-ui/core';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import MyInput from '../../components/input/MyInput';
import Button from '../../components/button/MyButton';
import LoginInform from './LoginInform';
import routing from '../../service/routes/routing';
import api from '../../service/axios/api';
import FlashContext from '../../components/contexts/FlashContext';
import './login.css';
import Loading from '../../components/loading/Loading';

const Login = function (props) {
  const query = new URLSearchParams(props.location.search);
  const [anchorEl, setAnchorEl] = useState(null);
  const [steep, setSteep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [msg, setMsg] = useState('');
  const history = useHistory();
  const [email, setEmail] = useState();
  const refUser = useRef(null);
  const refCode = useRef(null);
  const refPassword = useRef(null);
  const [language, setLanguage] = useState();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [required2fa, setRequired2fa] = useState(false);
  const [typeLogin, setTypeLogin] = useState();
  const auth = getAuth();
  const { t } = useTranslation();
  const [loadingMsg, setLoadingMsg] = useState(('Loading'));

  const userLocale = navigator?.languages?.length
    ? navigator.languages[0]
    : navigator.language;

  useEffect(() => {
    if (query) {
      const data = query.get('token');

      if (data) {
        api.mySession.set(data).then(() => {
          if (
            api.currentUser?.user?.role?.name === 'GerenteGM' ||
            api.currentUser?.user?.role?.name === 'GerenteLoja'
          ) {
            history.push(routing.fidelitydashboard);
          } else {
            history.push(routing.dashboard);
          }
        });
      }
    }

    api.language
      .get()
      .then(l => {
        if (l) {
          setLanguage(l);
        } else if (userLocale) {
          api.language.set(userLocale.substring(0, 2));
          setLanguage(userLocale.substring(0, 2));
          window.location.reload();
        } else {
          setLanguage('pt');
        }
      })
      .catch(() => {
        setLanguage('pt');
      });
  }, []);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguage = lng => {
    api.language.set(lng);
    window.location.reload();
    handleClose();
  };
  const { notifyError } = useContext(FlashContext);



  useEffect(() => {
    if (required2fa) {
      const recap = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: response => {
          },
        },
        auth,
      );
    }
  }, [required2fa]);
  const handleNext = e => {
    if (e === 'SMS') {
      setTypeLogin('sms');
      setLoadingMsg(t('MessageSend'));
      setLoading(true);

      api.user
        .sen2Fa('sms', userId)
        .then(() => {
          setLoading(false);
          setMsg(e);
          setOpenModal(true);
        })
        .catch(err => {
          console.log(err);
        });
    }
    if (e === 'e-mail') {
      setTypeLogin('email');
      setLoadingMsg(t('MessageSendEmail'));
      setLoading(true);
      api.user
        .sen2Fa('email', userId)
        .then(() => {
          setMsg(e);
          setOpenModal(true);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleEnter = () => {
    setLoadingMsg(t('Logging'));
    setLoading(true);
    api.user
      .verify2fa2(typeLogin, refCode.current.value, email, userId)
      .then(() => {
        setLoading(false);
        if (
          api.currentUser?.user?.role?.name === 'GerenteGM' ||
          api.currentUser?.user?.role?.name === 'GerenteLoja'
        ) {
          history.push(routing.fidelitydashboard);
        } else {
          history.push(routing.dashboard);
        }
      })
      .catch(error => {
        setLoading(false);
        notifyError(error.message);
      });
  };

  const handleLogin = () => {
    setLoading(true);
    setEmail(refUser.current.value);
    api.user
      .login(refUser.current.value, refPassword.current.value)
      .then(res => {
        setUserId(res.data.userId);
        if (res.data.message === '2FA required') setRequired2fa(true);
        setSteep(steep + 1);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (error.problems) {
          if (error.problems.length > 0) {
            error.problems.forEach(p => {
              notifyError(p.msg);
            });
          }
        } else {
          notifyError(error?.message);
        }
      });
  };

  const enterPress = e => {
    if (e === 'Usuário') refPassword.current.focus();
    if (e === 'Senha') handleLogin();
    if (e === 'Código') handleEnter();
  };


  const forgotPassword = () => {
    history.push(routing.forgotPassword);
  };

  const resend2FA = () => {
    setSteep(steep - 1);
    setTypeLogin(typeLogin === 'email' ? 'sms' : 'email');
    setLoadingMsg(t('MessageSend'));
    setLoading(true);

    api.user
      .sen2Fa(typeLogin === 'email' ? 'sms' : 'email', userId)
      .then(() => {
        setLoading(false);
        setMsg(typeLogin === 'email' ? 'SMS' : 'Email');
        setOpenModal(true);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      <div id="recaptcha-container" name="recaptcha-container" />
      <Loading open={loading} msg={loadingMsg} />
      <div className="base">
        <div className="lateral">
          <img loading='hight' src="img/controlEcosystemnova3.png" alt="logo" className="logo" />
          <div className="textPosition">
            {steep === 0 && (
              <>
                <h4 className="textWelcome">
                  {t('Welcome')}
                </h4>
                <h4 className="textWelcome"> Control EcoSystem!</h4>
                <h4 className="textWelcome">{t('Login')}</h4>
              </>
            )}
            {steep >= 1 && (
              <h4 className="textWelcome">{t('Label2')}</h4>
            )}
          </div>
        </div>
        <div className="baseLogin">
          {steep === 0 && (
            <div className="login1">
              <p className="textLogin">Login</p>
              <MyInput
                myRef={refUser}
                icon="user"
                label={t('User')}
                enterPress={enterPress}
              />
              <MyInput
                myRef={refPassword}
                type="password"
                icon="password"
                label={t('Password')}
                enterPress={enterPress}
              />

              <p
                className="textForgot"
                style={{ marginBottom: 100, cursor: 'pointer' }}
                onClick={forgotPassword}
                onKeyDown={forgotPassword}
              >
                {t('Forgot')}
              </p>
              <Button onClick={handleLogin} label={t('Continue')} />
            </div>
          )}
          {steep === 1 && (
            <div className="login1">
              <p className="textLogin">{t('Label1')}</p>
              <Button
                style={{ marginBottom: 20, width: 592 }}
                onClick={() => handleNext('e-mail')}
                label="Email"
              />
              <Button
                style={{ marginBottom: 20, width: 592 }}
                onClick={() => handleNext('SMS')}
                label="SMS"
              />
            </div>
          )}
          {steep === 2 && (
            <div className="login1">
              <h5 className="textLogin">{t('Label3')}:</h5>
              <MyInput
                myRef={refCode}
                type="password"
                icon="password"
                label={t('Code')}
                enterPress={enterPress}
              />
              <p>
                Reenviar código por {typeLogin === 'email' ? 'SMS' : 'E-mail'}?{' '}
                <span
                  style={{ fontWeight: 'bold', cursor: 'pointer' }}
                  onClick={resend2FA}
                >
                  Clique aqui
                </span>
              </p>
              <Button
                style={{ marginBottom: 20 }}
                onClick={handleEnter}
                label={t('Label4')}
              />
            </div>
          )}
          <footer
            style={{
              textAlign: 'center',
              position: 'absolute',
              bottom: 10,
              height: '2.5rem',
              width: '100%',
            }}
          >
            <div>
              <h6 style={{ margin: '0px' }}>
                Todos direitos reservados para Promotech
              </h6>
              <a
                style={{ marginBottom: '20px' }}
                href="https://elementcontrol.azurewebsites.net"
                target="_blank"
                rel="noreferrer"
              >
                DESENVOLVEDORES
              </a>
            </div>
          </footer>
        </div>
        <Grid className="baseAvatar">
          {language === 'en' && (
            <Avatar
              alt="Remy Sharp"
              src="./img/eua.webp"
              style={{ marginTop: 20, marginLeft: -60 }}
              onClick={handleMenu}

            />
          )}
          {language === 'es' && (

            <Avatar
              alt="Remy Sharp"
              src="./img/espanha.webp"
              style={{ marginTop: 20, marginLeft: -60 }}
              onClick={handleMenu}
              set

            />

          )}
          {language === 'pt' && (
            <Avatar
              alt="Remy Sharp"
              src="./img/brasil.webp"
              style={{ marginTop: 20, marginLeft: -60 }}
              onClick={handleMenu}
            />
          )}
          {!language && (
            <Avatar
              alt="Remy Sharp"
              src="./img/brasil.webp"
              style={{ marginTop: 20, marginLeft: -60 }}
              onClick={handleMenu}
            />
          )}
          <Avatar
            alt="Remy Sharp"
            src="./img/whats.webp"
            style={{ marginTop: 20, marginLeft: -60, cursor: 'pointer' }}
            onClick={() =>
              window.open(
                'https://api.whatsapp.com/send/?phone=551131810646&text&type=phone_number&app_absent=0',
                '_blank',
              )
            }
          />
        </Grid>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {language !== 'en' && (
            <MenuItem
              onClick={() => handleLanguage('en')}
            >
              <Avatar
                alt="English"
                src="./img/eua.webp"
                style={{ width: 30, height: 30, marginRight: 15 }}
              />
              English
            </MenuItem>
          )}

          {language !== 'pt' && (
            <MenuItem
              onClick={() => handleLanguage('pt')}
            >
              <Avatar
                alt="Portugues"
                src="./img/brasil.webp"
                style={{ width: 30, height: 30, marginRight: 15 }}
              />
              Português
            </MenuItem>
          )}

          {language !== 'es' && (
            <MenuItem
              onClick={() => handleLanguage('es')}
            >
              <Avatar
                alt="Spanish"
                src="./img/espanha.webp"
                style={{ width: 30, height: 30, marginRight: 15 }}
              />
              Spanish
            </MenuItem>
          )}
        </Menu>
      </div>

      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        maxWidth="lg"
      >
        <LoginInform
          msg={msg}
          setSteep={setSteep}
          steep={steep}
          setOpen={setOpenModal}
        />
      </Dialog>
    </>
  );
};

export default Login;
