/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import moment from 'moment/moment';
import FlashContext from '../../components/contexts/FlashContext';
import routing from '../../service/routes/routing';
import styles from '../../styles/Layout.module.css';
import Loading from '../../components/loading/Loading';

import api from '../../service/axios/api';

const customStyles = {
  control: provided => ({
    ...provided,
    marginTop: -10,
    width: 150,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
  }),
};

const useStyles = makeStyles(() =>
  createStyles({
    textNotification: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      // lineHeight: 'auto',
      fontSize: 14,
      color: '#707070',
    },
    myLabel: {
      // padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
      marginLeft: 20,
      marginBottom: 20,
    },
    data: {
      marginTop: -10,
      color: '#717171',
      fontWeight: 500,
      fontFamily: 'Open Sans',
    },
    labelData: {
      color: '#717171',
      fontFamily: 'Open Sans',
    },
    gridItem: {
      marginTop: -40,
      '&& .MuiGrid-item': {
        paddingTop: 0,
        maxHeight: 60,
      },
    },
  }),
);

export default function Users() {
  const classes = useStyles();
  const { notifySuccess, notifyError } = useContext(FlashContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState();
  const [msg, setMsg] = useState('Buscando detalhes...');

  const [edit, setEdit] = useState(false);

  const [newStatus, setNewStatus] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [newMobile, setNewMobile] = useState('');
  const [newCpf, setNewCpf] = useState('');
  const [twoFaType, setTwoFaType] = useState('');

  const find = () => {
    if (api.currentUser.id) {
      setLoading(true);
      const cancel = new AbortController();
      api.user
        .getById(api.currentUser.id, cancel)
        .then(res => {
          setLoading(false);
          setUser(res.retorno);
          setNewCpf(res.retorno.cpf);
          setNewMobile(res.retorno.mobile);
          setNewPhone(res.retorno.phone);
          setNewStatus(
            res.retorno.is_active
              ? { label: 'Ativo', value: true }
              : { label: 'Desativado', value: false },
          );
          setTwoFaType(
            res.retorno.twoFaType === '1'
              ? { label: 'Email', value: '1' }
              : { label: 'SMS', value: '2' },
          );
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
        });

      // twoFaType
    }
  };
  useEffect(() => {
    find();
  }, []);

  const updateUser = () => {
    setMsg('Atualizando perfil...');
    setLoading(true);
    api.user
      .editUser(
        user?.email,
        newCpf,
        newStatus.value,
        newMobile,
        newPhone,
        twoFaType.value,
      )
      .then(() => {
        notifySuccess('Usuário editado com sucesso');
        find();
        setEdit(false);
      })
      .catch(err => notifyError(err.msg))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loading open={loading} msg={msg} />
      {/* {console.log(api.currentUser.user.role.name === 'GerenteGM')} */}
      <div className="col-sm-12" style={{ marginTop: 50, padding: 50 }}>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>{user?.name}</h4>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              history.push(
                api.currentUser.user.role.name === 'GerenteGM'
                  ? routing.fidelitydashboard
                  : routing.dashboard,
              )
            }
          >
            Voltar
          </Button>
        </div>
        <Grid
          container
          className={classes.gridItem}
          spacing={1}
          style={{
            marginTop: 15,
            padding: 40,
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: '#E1E1E6',
            borderRadius: 8,
            paddingBottom: 10,
          }}
        >
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>CPF</h6>
            {/* {!edit ? ( */}
            <p className={classes.data}>{user?.cpf}</p>
            {/* ) : (
              <TextField
                value={newCpf}
                onChange={e => setNewCpf(e.target.value)}
              />
            )} */}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>E-mail</h6>
            <p className={classes.data}>{user?.email}</p>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>Telefone</h6>
            {!edit ? (
              <p className={classes.data}>
                {user?.phone === 'null' ? 'Não cadastrado' : user?.phone}
              </p>
            ) : (
              <TextField
                value={newPhone}
                onChange={e => setNewPhone(e.target.value)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>Celular</h6>
            {!edit ? (
              <p className={classes.data}>{user?.mobile}</p>
            ) : (
              <TextField
                value={newMobile}
                onChange={e => setNewMobile(e.target.value)}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>Status</h6>
            {/* <p className={classes.data}>
              {user?.is_active ? 'Ativo' : 'Desativado'}
            </p> */}
            {/* {!edit ? ( */}
            <p className={classes.data}>
              {user?.is_active ? 'Ativo' : 'Desativado'}
            </p>
            {/* ) : (
              // <TextField value={user?.is_active ? 'Ativo' : 'Desativado'} />
              <Select
                width="300"
                styles={customStyles}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Status"
                value={newStatus}
                onChange={setNewStatus}
                isSearchable={false}
                options={[
                  { label: 'Ativo', value: true },
                  { label: 'Desativado', value: false },
                ]}
              />
            )} */}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>Tipo usuário</h6>
            <p className={classes.data}>
              {user?.role_id === 1 ? 'Administrador' : 'Cliente'}
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>Duplo Fator</h6>
            {!edit ? (
              <p className={classes.data}>
                {user?.twoFaType === '1' ? 'Email' : 'SMS'}
              </p>
            ) : (
              <Select
                width="300"
                styles={customStyles}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Status"
                value={twoFaType}
                onChange={setTwoFaType}
                isSearchable={false}
                options={[
                  { label: 'Email', value: '1' },
                  { label: 'SMS', value: '2' },
                ]}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <h6 className={classes.labelData}>Criado em</h6>
            <p className={classes.data}>
              {user && moment(user.created_at).format('LLL')}
            </p>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              color="primary"
              style={{ background: !edit ? '' : 'rgb(181, 27, 0)' }}
              variant="contained"
              onClick={() => setEdit(!edit)}
            >
              {!edit ? 'Editar' : 'Cancelar'}
            </Button>
            {edit && (
              <Button
                color="primary"
                style={{ marginLeft: 10 }}
                variant="contained"
                onClick={updateUser}
              >
                Salvar
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
