/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import {
  Grid,
  makeStyles,
  // MenuItem,
  // TextField,
  // Typography,
} from '@material-ui/core';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ContractForm from '../components/forms/ContractForm';
import CommercialAgentForm from '../components/forms/CommercialAgentForm';
import routing from '../../../service/routes/routing';
import styles from '../../../styles/Layout.module.css';
// eslint-disable-next-line no-unused-vars
import api from '../../../service/axios/api';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  selectField: {
    minWidth: '200px',
  },
}));

function CreateContract() {
  const router = useHistory();
  const classes = useStyles();
  // const { id } = useParams();
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [option, setOption] = useState('client');
  // const handleChange = event => {
  //   const { value } = event.target;
  //   setOption(value);
  // };

  // const onSuccessContract = contract => axios.post('/api/contracts', contract);

  const cleanCEP = dt => {
    const res = dt.replace('-', '');
    const final = res.replaceAll('.', '');
    return final.trim();
  };

  const cleanValue = vl => {
    const res = vl?.replace('R$', ' ');
    const final = res?.replaceAll(',', '.');
    return final?.trim();
  };

  const onSuccessContract = contract => 
    api.contract.create(
      true,
      contract.company.contacts,
      [
        {
          cnpj: contract.company.cnpj,
          company_name: contract.company.companyName,
          trading_name: contract.company.tradingName,
          website: contract.company.website,
          comments: contract.company.comments,
          phone: contract.company.phone,
          mobile: contract.company.mobile,
          email: contract.company.email,
          postal_code: cleanCEP(contract.deliveryAddress.companyPostalCode),
          street_name: contract.deliveryAddress.companyStreet,
          number: contract.deliveryAddress.companyNumber,
          complement: contract.deliveryAddress.companyComplement,
          district: contract.deliveryAddress.companyDistrict,
          city: contract.deliveryAddress.companyCity,
          state: contract.deliveryAddress.companyState,
          is_default: true,
          is_active: true,
          allow_invoice: true,
        },
      ],
      {
        postal_code: cleanCEP(
          contract.deliveryAddress.isCentralizedDelivery
            ? contract.deliveryAddress?.companyPostalCode
            : contract.deliveryAddress?.postalCode,
        ),
        street_name: contract.deliveryAddress.isCentralizedDelivery
          ? contract.deliveryAddress?.companyStreet
          : contract.deliveryAddress?.street,
        number: contract.deliveryAddress.isCentralizedDelivery
          ? contract.deliveryAddress?.companyNumber
          : contract.deliveryAddress?.number,
        complement: contract.deliveryAddress.isCentralizedDelivery
          ? contract.deliveryAddress?.companyComplement
          : contract.deliveryAddress?.complement,
        district: contract.deliveryAddress.isCentralizedDelivery
          ? contract.deliveryAddress?.companyDistrict
          : contract.deliveryAddress?.district,
        city: contract.deliveryAddress.isCentralizedDelivery
          ? contract.deliveryAddress?.companyCity
          : contract.deliveryAddress?.city,
        state: contract.deliveryAddress.isCentralizedDelivery
          ? contract.deliveryAddress?.companyState
          : contract.deliveryAddress?.state,
        recipient: contract.deliveryAddress.isCentralizedDelivery
          ? contract.deliveryAddress?.receiver
          : contract.deliveryAddress?.receiver,
      },
      [],
      contract.productDetails.campaign.isAdministrationRate,
      contract.productDetails.product === 'prepaid_card' ? 'Card' : 'Transfer',
      contract.deliveryAddress.isCentralizedDelivery,
      true,
      contract.revenue.bankAccountId,
      contract.revenue.serviceCodeId,
      contract.company?.campaign?.label,
      contract.company?.groupName, // -> Nome GRUPO
      cleanValue(contract.productDetails.campaign.annualEstimatedAward),
      contract.revenue.currency,
      contract.revenue.signatureDate,
      contract.revenue.expirationDate,
      contract.productDetails.campaign.totalCommissionRate,
      contract.productDetails.campaign.rechargeFrequency, // periodo de reajuste numero
      contract.productDetails.salesChannel,
      contract.revenue.calculation === 'normal',
      contract.revenue.paymentMethod === 'transfer',
      contract.revenue.isInvoiceTopup,
      // Condições comerciais
      cleanValue(contract.productDetails.campaign.administrationRate),
      cleanValue(contract.productDetails.campaign.administrationFee),
      0.0, // exchangeFee -- precisa implementar ,
      true, // applyAdministrationRate - precisa implementar
      // Obrigatórios se product_type = "card"
      contract.productDetails.issuer,
      contract.productDetails.productBrand,
      contract.productDetails.isChipCard,
      contract.productDetails.isInternationalCard,
      false, // isDisposable - preccisa implementar
      !contract.productDetails.isGiftCard, // allowWithdraw
      cleanValue(
        contract.productDetails.companyOperatingFees.balanceTransferFee,
      ),
      cleanValue(contract.productDetails.operatorFees.markupRate),
      // Pagos pelo Cliente Element
      cleanValue(contract.productDetails.campaign.issueFee),
      cleanValue(
        contract.productDetails.companyOperatingFees.minimumLoadAmount,
      ),
      cleanValue(
        contract.productDetails.companyOperatingFees.belowMinimumLoadFee,
      ),
      cleanValue(contract.productDetails.companyOperatingFees.emergencyLoadFee),
      cleanValue(
        contract.productDetails.companyOperatingFees.specialHandlingFee,
      ),
      // Pagos pelo Premiado
      cleanValue(contract.productDetails.operatorFees.monthlyFee),
      cleanValue(contract.productDetails.operatorFees.unlockFee),
      cleanValue(contract.productDetails.operatorFees.reissueFee),
      cleanValue(contract.productDetails.operatorFees.chargebackFee),
      cleanValue(contract.productDetails.companyOperatingFees.chargebackRate),
      cleanValue(contract.productDetails.operatorFees.atmWithdrawFee),
      9.0, // bankWithdrawFee - precisa implementar
      // Obrigatórios se product_type = "transfer"
      true, // isTed - precisa implementar
      cleanValue(contract.productDetails.operatorFees.transferFee),
      contract.productDetails.product === 'prepaid_card'
        ? cleanValue(
            contract.productDetails.operatorFees.rejectedTransactionFee,
          )
        : cleanValue(
            contract.productDetails.companyOperatingFees.rejectedTransactionFee,
          ),
      // Obrigatórios se product_type = "check"
      cleanValue(contract.productDetails.operatorFees.checkFee),
      // Obrigatórios se product_type = "voucher"
      cleanValue(contract.productDetails.operatorFees.voucherFee),
      // Entrega
      contract.deliveryAddress.deliveryAgent,
      contract.deliveryAddress.deliveryService,
      '', // comments - precisa implementar
      '', // signeeName - verificar se precisa implementar
      '', // signeeCpf - verificar se precisa implementar ,
      '', // signeePost - verificar se precisa implementar ,
      '', // area - ROLIM NÃO SABE PRA QUE SERVE,
      contract.productDetails.commission_calculation,
      cleanValue(contract.productDetails.operatorFees.virtualMonthlyFee),
      cleanValue(contract.productDetails.operatorFees.virtualUnlockFee),
      cleanValue(contract.productDetails.operatorFees.virtualReissueFee),
      cleanValue(contract.productDetails.operatorFees.virtualChargebackFee),
      cleanValue(contract.productDetails.companyOperatingFees.virtualChargebackRate),
      cleanValue(contract.productDetails.operatorFees.virtualAtmWithdrawFee),
      cleanValue(contract.productDetails.operatorFees.virtualMarkupRate),
      cleanValue(contract.productDetails.operatorFees.virtualIrFee),
      cleanValue(contract.productDetails.companyOperatingFees.virtualSpecialHandlingFee),
      cleanValue(contract.productDetails.companyOperatingFees.virtualMinimumLoadAmount),
      cleanValue(contract.productDetails.companyOperatingFees.virtualBelowMinimumLoadFee),
      cleanValue(contract.productDetails.companyOperatingFees.virtualEmergencyLoadFee),
      cleanValue(contract.productDetails.companyOperatingFees.virtualBalanceTransferFee),
      cleanValue(contract.productDetails.companyOperatingFees.virtualTransferFee),
      cleanValue(contract.productDetails.companyOperatingFees.virtualIssueFee),
      contract.productDetails.typeCard,
    );
    // cleanValue(contract.productDetails.operatorFees.virtualMarkupRate),
  const onSuccessCommercialAgent = commercialAgent =>
    axios.post('/api/commercial-agents', commercialAgent);

  const callback = () => {
    router.push('/allContracts');
  };

  const callbackCommercialAgent = () => {
    router.push('/commercial-agents');
  };

  return (
    <>
      <Grid
        className={classes.header}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginTop: 50 }}
      >
        <p
          className={styles.textMenu}
          onKeyDown={() => router.push(routing.allContracts)}
          onClick={() => router.push(routing.allContracts)}
        >
          {t('initialMenu')}
        </p>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h4 className={styles.textTitle}>{t('newContract.label')}</h4>
        </div>
        {/* <Typography variant="h4" component="h2" gutterBottom>
          Novo contrato
        </Typography> */}
        {/* <TextField
          className={classes.selectField}
          select
          label="Tipo de contratante"
          variant="outlined"
          size="small"
          value={option}
          onChange={handleChange}
        >
          <MenuItem value="client">Cliente</MenuItem>
          <MenuItem value="agent">Agente Comercial</MenuItem>
        </TextField> */}
      </Grid>
      {option === 'client' && (
        <ContractForm
          control
          onSuccess={onSuccessContract}
          code={201}
          titleModal={t('newContract.success.successMsg')}
          actionModal={callback}
        />
      )}
      {option === 'agent' && (
        <CommercialAgentForm
          onSuccess={onSuccessCommercialAgent}
          code={201}
          titleModal="Agente comercial inserido com sucesso"
          actionModal={callbackCommercialAgent}
        />
      )}
    </>
  );
}

export default CreateContract;
