/* eslint-disable import/prefer-default-export */
/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
// import { alpha, styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment-timezone';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Select from 'react-select';
import MaterialSelect from '@material-ui/core/Select';
import { Pagination } from '@material-ui/lab';
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  TableCell,
  styled,
  InputBase,
} from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import _ from 'lodash';
import PermissionComponent from '../../permissionComponent/PermissionComponent';
import api from '../../../service/axios/api';
import Status from '../../../service/translateStatus';
import Button from '../../button/MyButton';
import Loading from '../../loading/Loading';
import FlashContext from '../../contexts/FlashContext';
import routing from '../../../service/routes/routing';

import SelectOptions from './SelectOptions1';

import './tableOrder.css';
import UserRoles from '../../../service/acl/UserRoles';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: 240,
    backgroundColor: 'none',
    boxShadow: 'none',
    // border: 'none',
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: (provided, state) => ({
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
  menuPortal: base => ({ ...base, zIndex: 99999 }),
};

const useStyles = makeStyles(theme =>
  createStyles({
    textNotification: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: 'auto',
      color: '#707070',
      '&:hover': {
        backgroundColor: '#41b490',
        color: '#fff',
      },
    },
  }),
);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const options = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

const EnhancedTableHead = props => {
  const { t: header } = useTranslation();
  const { onSelectAllClick, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell component="th">
          {/* {header('Order.table.order')} */}
          Data
        </StyledTableCell>
        <StyledTableCell component="th">
          {/* {header('Order.table.numberNf')} */}
          Período
        </StyledTableCell>
        <StyledTableCell component="th">
          {/* {header('Order.table.payment')} */}
          Razão Social
        </StyledTableCell>
        <StyledTableCell component="th">
          {/* {header('Order.table.payment')} */}
          CNPJ
        </StyledTableCell>
        <StyledTableCell component="th">
          {/* {header('Order.table.payment')} */}
          Nome da Campanha
        </StyledTableCell>
        <StyledTableCell component="th">
          {/* {header('Order.table.rz')} */}
          Quantidade Registros
        </StyledTableCell>
        <StyledTableCell component="th">
          {/* {header('Order.table.orderName')} */}
          Valor
        </StyledTableCell>
        <StyledTableCell component="th">
          {/* {header('Order.table.product')} */}
          NF
        </StyledTableCell>
        <StyledTableCell component="th">
          {/* {header('Order.table.value')} */}
          Status
        </StyledTableCell>
        {/* <StyledTableCell component="th">
          {header('Order.table.created')}
        </StyledTableCell>
        <StyledTableCell component="th">
          {header('Order.table.status')}
        </StyledTableCell> */}
        {/* <StyledTableCell padding="checkbox"> */}
        {/* <Checkbox
            // color="primary"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        {/* </StyledTableCell> */}
        <StyledTableCell component="th" align="right">
          {/* <MoreVertIcon /> */}
        </StyledTableCell>
        <StyledTableCell component="th" />
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  // order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  // orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = function (props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: '#f3f3f3',
          // (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export function TableReport({
  orders,
  totalPages,
  setPage,
  page,
  itens,
  setItens,
  setStatus,
  product,
  setProduct,
  setOrderBy,
  chipData,
  setChipData,
  find,
  setLoading,
  setMsg,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { notifyError, notifySuccess } = useContext(FlashContext);
  const [selected, setSelected] = React.useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [menuOptions, setMenuOptions] = useState(null);

  const [duplicateId, setDuplicateId] = useState();
  const [cancelId, setCancelId] = useState();

  const [openModalMultiPayment, setOpenModalMultiPayment] = useState(false);
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [bankId, setBankId] = useState(0);
  const [banks, setBanks] = useState([]);
  const [orderForPayment, setOrderForPayment] = useState();
  const [orderForMultPayment, setOrderForMultPayment] = useState([]);
  const [errors, setErrors] = useState([]);

  const [allBanks, setAllBanks] = useState(false);
  const [allDate, setAllDate] = useState(false);

  async function multiPayment(payOrders) {
    for (const pay in payOrders) {
      try {
        await api.order.payment(
          payOrders[pay].pd,
          payOrders[pay].datePayment,
          payOrders[pay].bank,
        );
        setSelected([]);
        notifySuccess(`NF ${payOrders[pay].nf} paga!`);
      } catch (e) {
        notifyError(`Erro ao informar pagamento da NF: ${payOrders[pay].nf}`);
        setErrors([...errors, e]);
      }
    }
    find();
    setAllBanks(false);
    setAllDate(false);
    setOpenModalMultiPayment(false);
    setPaymentLoading(false);
  }

  const informPayment = order => {
    if (selected.length > 1) {
      const list = [];
      selected.forEach(s => {
        list.push({
          nf: s?.invoiceNumber,
          pd: s.pd,
          datePayment: new Date(),
          bank: { label: '', value: 0 },
        });
        setOrderForMultPayment(list);
      });
      setOpenModalMultiPayment(true);
    } else {
      setOrderForPayment(order);
      setOpenModalPayment(true);
    }
  };

  const handleMenu = (row, event) => {
    setMenuOptions(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const duplicateInf = id => {
    setDuplicateId(id);
    setAnchorEl(null);
    setOpenDuplicate(true);
  };

  const cancelInf = id => {
    setCancelId(id);
    setAnchorEl(null);
    setOpenCancel(true);
  };

  const duplicateOrder = () => {
    if (duplicateId) {
      api.order
        .duplicate(duplicateId)
        .then(res => {
          setDuplicateId();
          setOpenDuplicate(false);
        })
        .catch(err => console.log(err))
        .finally(() => window.location.reload());
    }
  };

  const cancelOrder = () => {
    if (cancelId) {
      setCancelLoading(true);
      api.order
        .cancel(cancelId)
        .then(res => {
          setCancelId();
          setOpenCancel(false);
        })
        .catch(err => {
          notifyError(err?.message);
        })
        .finally(() => {
          setCancelLoading(false);
          find();
        });
      // .finally(() => window.location.reload());
    }
  };

  const [rows, setRows] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    const cancel = new AbortController();
    const query = '?status=Active';
    api.banks
      .get(cancel, query)
      .then(res => {
        const list = [];
        res.retorno.forEach((b, i) => {
          if (i === 0) setBankId({ label: b.bank, value: b.id });
          list.push({ label: b.bank, value: b.id });
        });
        setBanks(list);
      })
      .catch(err => console.log(err));
  }, []);

  const cnpjMask = data => {
    return data
      .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
      .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  useEffect(() => {
    const listOrder = [];
    orders.forEach(or => {
      listOrder.push({
        id: or.id,
        name: or.order_name,
        period: or.report_interval,
        qtd: or.qtd_itens,
        nf: or.invoice_pdf && (
          <div
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => window.open(or.invoice_pdf, '_blank')}
          >
            {or.invoice_number}
          </div>
        ),
        campaign: or.campaign || 'SEM CAMPANHA',
        rs: or.name,
        vlr: `R$ ${or.amount}`,
        status: or.status,
        cnpj: cnpjMask(or.cnpj),
        all: or,
        invoiceNumber: or.invoice_number,
        created: or.created_at,
      });
    });
    setRows(listOrder);
  }, [orders]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    // const selectedIndex = selected.indexOf(row.id);
    const selectedIndex = selected.indexOf(selected.find(s => s.id === row.id));
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setSelected([]);
    setPage(newPage);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <>
      {/* <Grid item> */}
      <SelectOptions
        setStatus={setStatus}
        setChipData={setChipData}
        chipData={chipData}
        find={find}
        setOrderBy={setOrderBy}
        product={product}
        setProduct={setProduct}
        setSelectec={setSelected}
      />
      {/* </Grid> */}
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                handleMenu={handleMenu}
              />
              <TableBody>
                {rows ? (
                  rows.length > 0 ? (
                    rows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow tabIndex={-1} key={row.id}>
                          <StyledTableCell>
                            {row.created &&
                              moment
                                .utc(row.created)
                                // .tz('America/Sao_Paulo')
                                .format('DD/MM/YYYY')}
                          </StyledTableCell>
                          <StyledTableCell>{row.period}</StyledTableCell>
                          <StyledTableCell>{row.rs}</StyledTableCell>
                          <StyledTableCell>{row.cnpj}</StyledTableCell>
                          <StyledTableCell>{row.campaign}</StyledTableCell>
                          <StyledTableCell>{row.qtd}</StyledTableCell>
                          <StyledTableCell>{row.vlr}</StyledTableCell>
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                          // padding="none"
                          >
                            <p
                              // key={r.nd_number}
                              style={{ cursor: 'pointer', color: 'blue' }}
                              className="modal-box-body-value-label"
                            // onClick={() => window.open(r.link_debit)}
                            >
                              {row.nf}
                            </p>
                            {/* {api.currentUser?.user?.role?.id !== '1' &&
                              row.db &&
                              row.db.length > 0 &&
                              row.db.map(r => {
                                return (
                                  <p
                                    key={r.nd_number}
                                    style={{ cursor: 'pointer' }}
                                    className="modal-box-body-value-label"
                                    onClick={() => window.open(r.link_debit)}
                                  >
                                    {r.nd_number}
                                  </p>
                                );
                              })} */}
                            {api.currentUser?.user?.role?.id !== '1'
                              ? row.db &&
                              row.db.length > 0 &&
                              row.db.map(r => {
                                return (
                                  <p
                                    key={r.nd_number}
                                    style={{
                                      cursor: 'pointer',
                                      color: 'blue',
                                    }}
                                    className="modal-box-body-value-label"
                                    onClick={() => window.open(r.gcs_pdf)}
                                  >
                                    {r.nd_number}
                                  </p>
                                );
                              })
                              : api.currentUser?.user?.role?.id !== '3' &&
                              row.db &&
                              row.db.length > 0 &&
                              row.db.map(r => {
                                if (r.gcs_pdf) {
                                  return (
                                    <p
                                      key={r.nd_number}
                                      style={{
                                        cursor: 'pointer',
                                        color: 'blue',
                                      }}
                                      className="modal-box-body-value-label"
                                      onClick={() => window.open(r.gcs_pdf)}
                                    >
                                      {r.nd_number}
                                    </p>
                                  );
                                }
                              })}
                          </StyledTableCell>
                          <StyledTableCell>
                            {Status(row.status, t)}
                          </StyledTableCell>
                          <StyledTableCell padding="checkbox">
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {row.status === 'AwaitingPayment' && (
                                <PermissionComponent
                                  role={UserRoles.ORDERS_PAYMENT}
                                >
                                  <Button
                                    style={{
                                      width: 50,
                                      height: 30,
                                      backgroundColor: '#009BEA',
                                    }}
                                    label={t('OrderDetails.paidOut')}
                                    onClick={() => informPayment(row)}
                                  />
                                  <Checkbox
                                    // color="primary"
                                    checkedIcon={<BpCheckedIcon />}
                                    icon={<BpIcon />}
                                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                                    // checked={rowCount > 0 && numSelected === rowCount}
                                    onChange={e => handleClick(e, row)}
                                    inputProps={{
                                      'aria-label': 'select all desserts',
                                    }}
                                  />
                                </PermissionComponent>
                              )}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell component="th" align="right">
                            <MoreVertIcon
                              onClick={e => handleMenu(row, e)}
                              fontSize="small"
                              style={{ cursor: 'pointer' }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={10} align="center">
                        {t('Order.table.noResult')}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={9} align="center" />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="menu-appbar"
            style={{ borderRadius: '25px' }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              className={classes.textNotification}
              onClick={() =>
                history.push(routing.orderDetails(menuOptions.all.order_id))
              }
            >
              Detalhes
            </MenuItem>
          </Menu>
        </Paper>
      </Box>
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 15,
        }}
        spacing={2}
        direction="row"
      >
        <Grid item>
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Itens"
            value={itens}
            onChange={setItens}
            options={options}
          />
        </Grid>
        <Grid item>
          <Pagination
            size="large"
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'end',
          padding: 40,
        }}
      />
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        maxWidth="lg"
      >
        <Grid
          container
          spacing={1}
          style={{
            border: '2px solid #c5c5c5',
            borderRadius: 10,
            justifyContent: 'center',
            padding: 40,
          }}
        >
          conteudo
        </Grid>
      </Dialog>
      <Dialog
        onClose={() => setOpenDuplicate(!openDuplicate)}
        open={openDuplicate}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="simple-dialog-title">
          {t('Order.table.duplicateTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Order.table.duplicateMsg')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenDuplicate(false)}
          >
            {t('Order.table.duplicateCancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={duplicateOrder}
            color="primary"
            variant="contained"
            label={t('Order.table.duplicateConfirmBtn')}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
