/* eslint-disable import/prefer-default-export */

import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Loading from '../../components/loading/Loading';
import SelectOption from './SelectOption';
import api from '../../service/axios/api';
import Individuotable from './Individuotable';
import FlashContext from '../../components/contexts/FlashContext';


export function Individuo() {
  const { notifyError } = useContext(FlashContext)
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [itens, setItens] = useState(10);
  const [selected, setSelected] = useState([]);
  const [chipData, setChipData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('Buscando arquivos...');


  useEffect(() => {
    searchData()

  }, []);

  const searchData = extra => {
    setLoading(true);
    setMsg('Buscando indivíduos...');
    api.commercialAgent.getSummaryIndividuos(page + 1, itens, extra, chipData)
      .then(res => {
        setData(res.data.docs);
        setTotal(res.data.total);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  };
  
  useEffect(() => {
    searchData();
  }, [page, itens]);

    const templateDownload = () => {
      setMsg('Gerando planilha....');
      setLoading(true);
        api.commercialAgent
          .getSummaryIndividuosXls(chipData)
          .then(res => {
            const url = res.retorno.fileLink;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'individuos.xlsx'); // or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            if (err?.response?.data) {
              if (err.response.data.Problems) {
                err.response.data.Problems.forEach((e) => {
                  notifyError(e.Message);
                });
              } else {
                notifyError(err.response.data.Message);
              }
            } else if (err.message) {
              if (err.message === "Network Error") {
                notifyError(
                  "Erro de conexão, verifique sua conexão com a internet e tente novamente"
                );
              } else {
                notifyError(err.message);
              }
            }
          })
          .finally(() => setLoading(false));
    };
  

  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ display: 'flex', justifyContent: 'start' }}
      >
        <h3 style={{ marginTop: "20px" }}>Indivíduos</h3>
      </Grid>
      <Loading open={loading} msg={msg} />
      <Grid style={{ width: "100%", justifyContent: "end" }}>
        <SelectOption
          setStatus={() => console.log('')}
          setChipData={setChipData}
          chipData={chipData}
          find={searchData}
        />

      </Grid>
       <Individuotable
           docs={data}
           setDocs={setData}
           page={page}
           setPage={setPage}
           itens={itens}
           setItens={setItens}
           total={total}
           setSelected={setSelected}
           selected={selected}
           searchData={searchData}
           templateDownload={templateDownload}
        /> 
    </>
  );
}
