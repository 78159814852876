/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import api from '../../../service/axios/api';

import {
  TextFieldOutlined as TextField,
  InputLabel,
  HelperText,
} from '../components/common/TextFieldOutlined';

import useStyles from '../styles/AuthForm.styles';

// eslint-disable-next-line react/prop-types
export default function LoginForm({ onSuccess }) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');

  const LoginSchema = object({
    email: string().email().required(),
    password: string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors, isSubmitting },
    getValues,
  } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const onSubmit = async data => {
    try {
      const { email, password } = data;
      const response = await api.user.login(email, password);
      if (response.status === 200) {
        onSuccess({ userId: response?.data?.userId, email });
      }
    } catch (err) {
      const error = err;
      console.log(err);
      setErrorMessage('Credenciais erradas');
    }
  };

  const resetPassword = async () => {
    console.log('resetar');
  };

  return (
    <Box component="form" width="100%" onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        fullWidth
        variant="outlined"
        color="primary"
        error={!!errors.email}
      >
        <InputLabel htmlFor="email" shrink>
          Usuário
        </InputLabel>
        <TextField
          id="username"
          inputProps={{
            style: {
              paddingLeft: '60px',
            },
          }}
          {...register('email')}
        />
        <InputAdornment
          position="start"
          style={{
            position: 'absolute',
            margin: ' 28px 28px 28px 14px',
            opacity: '0.7',
          }}
        >
          <MailOutlineRoundedIcon color="primary" fontSize="large" />
        </InputAdornment>
        <HelperText>{errors?.email?.message}</HelperText>
      </FormControl>
      <Box p={7} />
      <FormControl
        fullWidth
        variant="outlined"
        color="primary"
        error={!!errors.password}
      >
        <InputLabel htmlFor="password" shrink>
          Senha
        </InputLabel>
        <TextField
          id="password"
          type="password"
          inputProps={{
            style: {
              paddingLeft: '60px',
            },
          }}
          {...register('password')}
        />
        <InputAdornment
          position="start"
          style={{
            position: 'absolute',
            margin: ' 28px 28px 28px 14px',
            opacity: '0.7',
          }}
        >
          <LockOpenRoundedIcon color="primary" fontSize="large" />
        </InputAdornment>
        <HelperText component="span">
          <Typography color="primary">
            <a onClick={resetPassword} style={{ cursor: 'pointer' }}>
              Esqueci a minha senha{' '}
            </a>
          </Typography>
        </HelperText>
      </FormControl>
      <Box p={4}>
        <HelperText error>{errorMessage}</HelperText>{' '}
      </Box>
      <Button
        type="submit"
        className={classes.formButton}
        variant="contained"
        color="primary"
        disabled={!isDirty || isSubmitting}
        fullWidth
        size="large"
      >
        Entrar
      </Button>
    </Box>
  );
}
