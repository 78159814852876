/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import {
    TextField,
    TablePagination,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table,
    Paper,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    Grid,
    DialogContent,
    DialogContentText,
    DialogActions,
    Card,
} from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import GetApp from '@material-ui/icons/GetApp';
// import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslation } from 'react-i18next';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';

import { isNull } from 'lodash';
import Button from '../../components/button/MyButton';
import { cleanNumber, validateDocExtern, validarCPF } from '../../service/utils';
import Loading from '../../components/loading/Loading';
import api from '../../service/axios/api';
import FlashContext from '../../components/contexts/FlashContext';



const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    table: {
        minWidth: 1100,
    },
});


const StyledTableCell = withStyles(() => ({
    head: {
        // backgroundColor: theme.palette.common.black,
        // color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export default function Individuotable({
    docs,
    page,
    setPage,
    itens,
    setItens,
    total,
    searchData,
    templateDownload
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    // const [rows, setRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [menuOptions, setMenuOptions] = useState(null);
    const [openDialogEdit, setOpenDialogEdit] = useState(false);

    const [Nome, setNome] = useState("");
    // const [Email, setEmail] = useState("");
    const [ID, setID] = useState("");
    const [CPF, setCPF] = useState("");
    const { notifySuccess, notifyError } = useContext(FlashContext);
    const [birthdate, setBirthDate] = useState("")
    const [motherName, setMotherName] = useState("")
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [verifyUser, setVerifyUser] = useState(false)


    const handleMenu = (row, event) => {
        setBirthDate(moment(row.birthdate).tz('America/Sao_Paulo'));
        setNome(row.name)
        // setEmail(row.email)
        setCPF(row.cpf)
        setMotherName(row.mothers_name)
        setID(row.id)
        setAnchorEl(event.currentTarget)
        setVerifyUser(isVerifiedUser(row.is_verified))
    };

    const isVerifiedUser = (value) => {
        if (value === 1) {
            return true
        } else {
            return false
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        setOpenDialogEdit(true);
        handleClose();
    };

    const editUser = (listOk) => {
        api.commercialAgent.SummaryIndividuosEdit(ID, Nome, CPF, birthdate || '', motherName || '', listOk[0])
            .then(() => {
                searchData()
                notifySuccess("Usuário Editado com sucesso!");
                setOpenDialogEdit(false)
                setPaymentLoading(false);
            })
            .catch(err => {
                if (err?.problems) {
                    err.problems?.forEach((p) => {
                        notifyError(p.msg)
                    })
                } else {
                    notifyError(err.message)
                }
            })
            .finally(() => setPaymentLoading(false));
    }

    const ConsultarCPF = async () => {
        setPaymentLoading(true);
        if (verifyUser === false) {
            const doc = cleanNumber(CPF)

            if (validarCPF(doc)) {
                const listOk = []
                const listNoOk = []
                const consultCpf = [];
                const consultaCnpj = [];
                consultCpf.push({ doc, name: Nome, type: 'Transfer' })
                await api.order.consultaBeneficiario(consultCpf, consultaCnpj, 'Card')
                    .then(async (res) => {
                        try {

                            if (res.retorno?.notFindCNPJ?.length > 0 || res.retorno?.notFindCPF?.length > 0) {
                                await validateDocExtern(res, listOk, listNoOk, "Card")
                                if (listNoOk.length > 0) {
                                    setPaymentLoading(false);
                                    return notifyError(listNoOk[0].motivo)
                                } else {
                                    setPaymentLoading(false);
                                    editUser(listOk)
                                    return true
                                }
                            } else {
                                setPaymentLoading(false);
                                editUser(listOk)
                                return true
                            }
                        } catch (e) {
                            setPaymentLoading(false)
                            return false
                        }
                    })
                    .catch((e) => {
                        setPaymentLoading(false)
                        return false
                    })
            } else {
                setPaymentLoading(false)
                notifyError(t('newOrder.cardVinc.cpfError'));
            }
        } else {
            setPaymentLoading(false)
            editUser('')
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setItens(event.target.value);
    };
    return (
        <>
            <Paper className={classes.root}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Nome</StyledTableCell>
                                <StyledTableCell>CPF</StyledTableCell>
                                <StyledTableCell>Nome da mãe</StyledTableCell>
                                <StyledTableCell>
                                    Data Nascimento
                                </StyledTableCell>
                                <StyledTableCell>Status Serpro</StyledTableCell>
                                <StyledTableCell style={{ justifyContent: 'end', display: 'flex', gap: 10 }}>Atualização Serpro <GetApp onClick={templateDownload} style={{ cursor: 'pointer' }} /></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {docs?.map((row, i) => {
                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <StyledTableRow key={i}>
                                        <StyledTableCell>{row.name}</StyledTableCell>
                                        <StyledTableCell>{row.cpf}</StyledTableCell>
                                        <StyledTableCell>{row.mothers_name}</StyledTableCell>
                                        <StyledTableCell>
                                            {isNull(row.birthdate) ? "" : moment(row.birthdate).format('DD/MM/YYYY')}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.status_serpro}</StyledTableCell>
                                        <StyledTableCell style={{ justifyContent: 'end', display: 'flex', gap: 10 }}>
                                            {isNull(row.last_serpro_update) ? "" : moment(row.last_serpro_update).format('DD/MM/YYYY HH:mm')}
                                            <MoreVertIcon
                                                onClick={e => handleMenu(row, e)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
                    component="div"
                    count={total}
                    rowsPerPage={itens}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <Menu
                    id="menu-appbar"
                    style={{ borderRadius: '25px' }}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem className={classes.textNotification} onClick={handleEdit}>
                        Editar Indivíduo
                    </MenuItem>
                </Menu>
            </Paper>
            <Dialog
                onClose={() => setOpenDialogEdit(false)}
                open={openDialogEdit}
                fullWidth
                maxWidth="sm"
            >
                <Loading open={paymentLoading} msg="Editando Individuo..." />
                <DialogTitle id="simple-dialog-title">
                    <Grid
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Grid>Editar</Grid>
                        <Grid>{menuOptions && menuOptions.nf && menuOptions.nf}</Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {verifyUser === true ? (
                            <Grid style={{
                                display: "block",
                                margin: "20px",

                            }}>
                                <p style={{ marginTop: "5px", }}>Nome da Mãe:</p>
                                <TextField
                                    color="secondary"
                                    onChange={(event) => setMotherName(event.target.value)}
                                    defaultValue={motherName}
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                />
                            </Grid>

                        ) : (
                            <Grid container xs={12} style={{ width: "100%" }}>
                                <div style={{ textAlign: 'center', marginBottom: '10px', border: '4px dashed #dfe622', padding: '5px' }}><span>ATENÇÃO</span><br />Ao realizar a atualização deste registro iremos fazer uma consulta na base da Serpro e isso irá gerar <span style={{ color: 'red' }}>custos operacionais para a Element</span>!</div>
                                <Grid style={{
                                    width: "35%",
                                    display: "block",
                                    marginRight: "10px",
                                }}>

                                    <p>Data de Nascimento:</p>
                                    <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            inputVariant="outlined"
                                            style={{ backgroundColor: '#fff' }}
                                            size="small"
                                            format="dd/MM/yyyy"
                                            value={birthdate}
                                            InputProps={{ readOnly: true }}
                                            InputAdornmentProps={{ position: 'start' }}
                                            onChange={date => setBirthDate(date)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid style={{
                                    display: "block",
                                    width: "63%",

                                }}>
                                    <p>Nome:</p>
                                    <TextField
                                        color="secondary"
                                        onChange={(event) => setNome(event.target.value)}
                                        defaultValue={Nome}
                                        width="400px"
                                        fullWidth
                                        size="small"
                                        style={{ maxHeight: 10, minWidth: 260 }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid style={{
                                    display: "block",
                                    width: "63%",
                                    marginRight: "10px",
                                    marginTop: "20px"
                                }}>
                                    <p style={{ marginTop: "5px", }}>Nome da Mãe:</p>
                                    <TextField
                                        color="secondary"
                                        onChange={(event) => setMotherName(event.target.value)}
                                        defaultValue={motherName}
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid style={{
                                    display: "block",
                                    width: "35%",
                                    marginTop: "20px"
                                }}>
                                    <p style={{ marginTop: "5px", }}>CPF:</p>
                                    <TextField
                                        color="secondary"
                                        onChange={(event) => setCPF(event.target.value)}
                                        defaultValue={CPF}
                                        size="small"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <h6
                        style={{
                            cursor: 'pointer',
                            color: '#657588',
                            fontWeight: 'bold',
                            fontFamily: 'Open Sans',
                            marginTop: 5,
                        }}
                        onClick={() => setOpenDialogEdit(false)}
                    >
                        {t('OrderDetails.completeCancelBtn')}
                    </h6>
                    <Button
                        style={{ height: 40 }}
                        onClick={ConsultarCPF}
                        color="primary"
                        variant="contained"
                        label="Editar"
                    />
                </DialogActions>
            </Dialog >

        </>
    );
}
