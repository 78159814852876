/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */

/* eslint-disable no-unused-vars */
import {
  TablePagination,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState, useEffect, useContext } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/pt-BR';
import TableDialog from '../Resum/TableDialog';
import Button from '../../../../components/button/MyButton';
import ResumTable from '../Resum/ResumTable';
// import SelectOptions from '../Resum/SelectOptions';
import api from '../../../../service/axios/api';
import Loading from '../../../../components/loading/Loading';
import { LiberationTable } from './LiberationTable';
import FlashContext from '../../../../components/contexts/FlashContext';


export function Liberation({ setLoading, setMsg }) {
  const [dataCategory, setdataCategory] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [itens, setItens] = useState(10);
  const [selected, setSelected] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const { notifyError } = useContext(FlashContext)


  useEffect(() => {
    setLoading(true);
    api.order.summaryLiberation(selectedDate.toISOString(), page, itens)
      .then((res) => {
        setTotal(res.data.total)
        setdataCategory(res.data.docs)
        setLoading(false)
      })
      .catch((error) => { console.log(error) })
  }, [selectedDate, page, itens])

  const templateDownload = () => {
    setMsg('Gerando planilha....');
    setLoading(true);
    api.order
      .summaryLiberationXls(selectedDate.toISOString())
      .then(res => {
        const url = res.retorno.link;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'carga_template.xlsx'); // or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        if (err?.response?.data) {
          if (err.response.data.Problems) {
            err.response.data.Problems.forEach((e) => {
              notifyError(e.Message);
            });
          } else {
            notifyError(err.response.data.Message);
          }
        } else if (err.message) {
          if (err.message === "Network Error") {
            notifyError(
              "Erro de conexão, verifique sua conexão com a internet e tente novamente"
            );
          } else {
            notifyError(err.message);
          }
        }
      })
      .finally(() => setLoading(false));
  };


  return (
    <>
      <Grid
        item
        xs={12}
        sm={12}
        style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px", marginBottom: "40px" }}
      >

        <h3>Relatório de liberações</h3>
        <div style={{ marginTop: "8px" }}>
          <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils} style={{ float: "right " }}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              maxDate={Date()}
              inputVariant="outlined"
              // style={{ backgroundColor: '#fff' }}
              size="small"
              format="dd/MM/yyyy"
              InputProps={{ readOnly: true }}
              InputAdornmentProps={{ position: 'start' }}
              value={selectedDate}
              onChange={date => handleDateChange(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
      </Grid>

      <Grid style={{ marginBottom: "20px", marginLeft: "10px", width: "100%" }}>
        <LiberationTable
          docs={dataCategory}
          setDocs={setdataCategory}
          page={page}
          setPage={setPage}
          itens={itens}
          setItens={setItens}
          total={total}
          setSelected={setSelected}
          selected={selected}
          templateDownload={templateDownload}
        // searchDate={searchDate}
        />

      </Grid>
    </>
  );
}
