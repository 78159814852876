/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  Container,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';

import { useForm, Controller } from 'react-hook-form';
// import NumberFormat from 'react-number-format';
import axios from 'axios';
import DeliveryAddressSchema from '../../schemas/DeliveryAddressSchema';
import StepperContext from '../../context/StepperContext';
import {
  DELIVERY_AGENTS,
  DELIVERY_SERVICES,
  hasDeliveryServices,
  STATES,
} from '../../util/constants';

const useStyles = makeStyles(theme => ({
  form: {
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  content: {
    padding: theme.spacing(4),
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(4),
    background: '#f0f0f0',
    width: '100%',
    '& > *': {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  zipContainer: {
    marginBottom: theme.spacing(4),
  },
}));

// const DeliveryAddressCEPDefault = {
//   cep: '',
//   logradouro: '',
//   complemento: '',
//   bairro: '',
//   localidade: '',
//   uf: '',
//   ibge: '',
//   gia: '',
//   ddd: '',
//   siafi: '',
// };

function DeliveryAddressFormViewer({ deliveryAddress, companyAddress }) {
  const {
    register,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: deliveryAddress,
    resolver: yupResolver(DeliveryAddressSchema),
  });
  const { t } = useTranslation();
  const classes = useStyles();
  const stepper = useContext(StepperContext);

  const submit = data => {
    stepper.next({ deliveryAddress: data });
  };

  const { deliveryAgent, deliveryService } = watch();

  // const [cep, setCep] = useState(DeliveryAddressCEPDefault);
  const [cep, setCep] = useState(deliveryAddress);
  // eslint-disable-next-line no-unused-vars
  const [otherCep, setOtherCep] = useState(deliveryAddress.otherAddress);

  const handleChangeStreet = e => {
    setCep({
      ...cep,
      logradouro: String(e.target.value),
    });
  };
  const handleChangeDistrict = e => {
    setCep({
      ...cep,
      bairro: String(e.target.value),
    });
  };
  const handleChangeCity = e => {
    setCep({
      ...cep,
      localidade: String(e.target.value),
    });
  };
  const handleChangeComplement = e => {
    setCep({
      ...cep,
      complemento: String(e.target.value),
    });
  };

  useEffect(() => {
    if (deliveryAgent) {
      if (hasDeliveryServices(deliveryAgent)) {
        const newDeliveryService = DELIVERY_SERVICES[deliveryAgent].find(
          service => service.value === deliveryService,
        );

        setValue(
          'deliveryService',
          newDeliveryService ? newDeliveryService.value : '',
        );
      } else {
        setValue('deliveryService', '');
      }
    }
  }, [deliveryAgent]);

  const getCEP = async e => {
    e.preventDefault();
    const CEP = String(e.target.value);
    setCep({
      ...cep,
      cep: CEP,
    });
    if (
      (CEP.length === 8 && CEP[5] !== '-') ||
      (CEP.length === 9 && CEP[5] === '-')
    ) {
      try {
        const { data, status } = await axios.get(
          `https://viacep.com.br/ws/${CEP}/json/`,
        );

        if (status === 200) {
          setCep(data);
          setValue('street', data.logradouro);
          trigger('street');
          setValue('district', data.bairro);
          trigger('district');
          setValue('city', data.localidade);
          trigger('city');
          setValue('complement', data.complemento);
          trigger('complement');
          setValue('state', data.uf);
          trigger('state');
        }
      } catch (err) {
        const error = err;

        // eslint-disable-next-line no-console
        console.error(error.message);
      }
    }
  };
  return (
    <form className={classes.form} onSubmit={handleSubmit(submit)}>
      <Container className={classes.content}>
        <Typography
          className={classes.header}
          variant="h5"
          component="h3"
          gutterBottom
        >
          {t('newContract.address.label')}
        </Typography>
        <Grid container spacing={10} className={classes.zipContainer}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              // inputProps={{ ...register('postalCode') }}
              error={!!errors.postalCode}
              helperText={errors.postalCode?.message}
              label={t('newContract.address.postalCode')}
              variant="outlined"
              size="small"
              value={companyAddress?.postalCode}
              onChange={getCEP}
              autoFocus
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControlLabel
              label={t('newContract.address.chkLabel')}
              labelPlacement="start"
              control={
                <Controller
                  control={control}
                  name="isCentralizedDelivery"
                  render={props => (
                    <Switch
                      onChange={e => props.field.onChange(e.target.checked)}
                      checked={props.field.value}
                      color="primary"
                      disabled
                    />
                  )}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={10}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              label={t('newContract.address.address')}
              value={companyAddress?.street}
              variant="outlined"
              size="small"
              fullWidth
              // inputProps={{ ...register('street') }}
              error={!!errors.street}
              helperText={errors.street?.message}
              onChange={handleChangeStreet}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="district"
              name="district"
              type="text"
              value={companyAddress?.district}
              // inputProps={{ ...register('district') }}
              error={!!errors.district}
              helperText={errors.district?.message}
              label={t('newContract.address.district')}
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleChangeDistrict}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              // inputProps={{ ...register('city') }}
              error={!!errors.city}
              helperText={errors.city?.message}
              label={t('newContract.address.city')}
              value={companyAddress?.city}
              onChange={handleChangeCity}
              variant="outlined"
              size="small"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Controller
              control={control}
              name="state"
              render={props => (
                <TextField
                  select
                  onChange={e => props.field.onChange(e.target.value)}
                  value={companyAddress?.state}
                  error={!!errors.state}
                  helperText={errors.state?.message}
                  label={t('newContract.address.state')}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                >
                  {STATES.map(state => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              // inputProps={{ ...register('number') }}
              value={companyAddress?.number}
              error={!!errors.number}
              helperText={errors.number?.message}
              label={t('newContract.address.number')}
              variant="outlined"
              size="small"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8} lg={9}>
            <TextField
              // inputProps={{ ...register('complement') }}
              error={!!errors.complement}
              helperText={errors.complement?.message}
              label={t('newContract.address.complement')}
              variant="outlined"
              size="small"
              value={companyAddress?.complement}
              onChange={handleChangeComplement}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Controller
              control={control}
              name="deliveryAgent"
              render={props => (
                <TextField
                  select
                  onChange={e => props.field.onChange(e.target.value)}
                  value={props.field.value}
                  error={!!errors.deliveryAgent}
                  helperText={errors.deliveryAgent?.message}
                  label={t('newContract.address.deliveryAgent')}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                >
                  {DELIVERY_AGENTS.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          {hasDeliveryServices(deliveryAgent) && (
            <Grid item xs={12} sm={6} md={4}>
              <Controller
                control={control}
                name="deliveryService"
                render={props => (
                  <TextField
                    select
                    onChange={e => props.field.onChange(e.target.value)}
                    value={props.field.value}
                    error={!!errors.deliveryService}
                    helperText={errors.deliveryService?.message}
                    label={t('newContract.address.deliveryService')}
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  >
                    {DELIVERY_SERVICES[deliveryAgent].map(
                      ({ label, value }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ),
                    )}
                  </TextField>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              inputProps={{ ...register('receiver') }}
              error={!!errors.receiver}
              helperText={errors.receiver?.message}
              name="receiver"
              label={t('newContract.address.receiver')}
              variant="outlined"
              size="small"
              fullWidth
              disabled
            />
          </Grid>
        </Grid>
        <>
          <Typography
            className={classes.header}
            variant="h5"
            component="h3"
            gutterBottom
          >
            Endereço de entrega
          </Typography>
          <Grid container spacing={10} className={classes.zipContainer}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                inputProps={{ ...register('otherAddress.postalCode') }}
                // error={!!errors.postalCode}
                // helperText={errors.postalCode?.message}
                label={t('newContract.address.postalCode')}
                variant="outlined"
                size="small"
                disabled
                // value={otherCep.cep}
                // onChange={getOtherCEP}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                disabled
                label={t('newContract.address.address')}
                value={otherCep?.logradouro}
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ ...register('otherAddress.street') }}
                error={!!errors.street}
                helperText={errors.street?.message}
                // onChange={handleChangeOtherStreet}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                disabled
                id="district"
                name="district"
                type="text"
                value={otherCep?.bairro}
                inputProps={{ ...register('otherAddress.district') }}
                // error={!!errors.district}
                // helperText={errors.district?.message}
                label={t('newContract.address.district')}
                variant="outlined"
                size="small"
                fullWidth
                // onChange={handleChangeOtherDistrict}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                disabled
                inputProps={{ ...register('otherAddress.city') }}
                // error={!!errors.city}
                // helperText={errors.city?.message}
                label={t('newContract.address.city')}
                value={otherCep?.localidade}
                // onChange={handleChangeOtherCity}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Controller
                control={control}
                name="otherAddress.state"
                render={props => (
                  <TextField
                    disabled
                    select
                    // onChange={e => props.field.onChange(e.target.value)}
                    value={props.field.value || cep.uf}
                    // error={!!errors.state}
                    // helperText={errors.state?.message}
                    label={t('newContract.address.state')}
                    variant="outlined"
                    size="small"
                    fullWidth
                  >
                    {STATES.map(state => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                disabled
                inputProps={{ ...register('otherAddress.number') }}
                // error={!!errors.number}
                // helperText={errors.number?.message}
                label={t('newContract.address.number')}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={9}>
              <TextField
                disabled
                inputProps={{ ...register('otherAddress.complement') }}
                // error={!!errors.complement}
                // helperText={errors.complement?.message}
                label={t('newContract.address.complement')}
                variant="outlined"
                size="small"
                value={otherCep?.complemento}
                // onChange={handleChangeOtherComplement}
                fullWidth
              />
            </Grid>
          </Grid>
        </>
      </Container>
      {/* <div className={classes.footer}>
        <Button onClick={stepper.prev} type="button" variant="contained">
          {t('newContract.address.goBackBtn')}
        </Button>
        <Button type="submit" variant="contained" color="primary">
          {t('newContract.address.nextBtn')}
        </Button>
      </div> */}
    </form>
  );
}

export default DeliveryAddressFormViewer;
