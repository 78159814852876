/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import GetApp from '@material-ui/icons/GetApp';
import {
    TablePagination,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table,
    Paper,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import api from '../../../../service/axios/api';


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    table: {
        minWidth: 1100,
    },
});

const StyledTableCell = withStyles(() => ({
    head: {
        backgroundColor: "#303474",
        color: "white",
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

// eslint-disable-next-line import/prefer-default-export
export function OverviewTable({
    docs,
    page,
    setPage,
    itens,
    setItens,
    total,
    templateDownload
}) {
    const classes = useStyles();
    const history = useHistory()
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setItens(event.target.value);
    };


    return (
        <Paper className={classes.root}>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>CNPJ</StyledTableCell>
                            <StyledTableCell>NF/ND</StyledTableCell>
                            <StyledTableCell>CLIENTE</StyledTableCell>
                            <StyledTableCell>PEDIDO</StyledTableCell>
                            <StyledTableCell>TOTAL NF</StyledTableCell>
                            <StyledTableCell>PRÊMIO</StyledTableCell>
                            <StyledTableCell>PRÊMIO CONSUMIDO</StyledTableCell>
                            <StyledTableCell>AGUARDANDO LIBERAÇÃO</StyledTableCell>
                            <StyledTableCell>SALDO</StyledTableCell>
                            <StyledTableCell> <div style={{ display: 'flex', justifyContent: 'center' }}>
                                Data de referência  <GetApp onClick={templateDownload} style={{ cursor: 'pointer' }} />
                            </div></StyledTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {docs.map((row) => {
                            console.log(row)
                            return (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell>{row.cnpj}</StyledTableCell>
                                    <StyledTableCell>{row.invoice_number ? row.nd_number ? <span><span style={{cursor: 'pointer', color: 'blue'}} onClick={() => window.open(row.invoice_link,'blank')}>{row.invoice_number}</span>|<span style={{cursor: 'pointer',  color: 'blue'}} onClick={() => window.open(row.nd_link,'blank')}>{row.nd_number}</span></span>: <span style={{cursor: 'pointer',  color: 'blue'}} onClick={() => window.open(row.invoice_link,'blank')}>{row.invoice_number}</span> : row.nd_number ? <span style={{cursor: 'pointer',  color: 'blue'}} onClick={() => window.open(row.nd_link,'blank')} >{row.nd_number}</span> : ''}</StyledTableCell>
                                    <StyledTableCell>{row.company_name}</StyledTableCell>
                                    <StyledTableCell style={{cursor: 'pointer',  color: 'blue'}} onClick={() => history.push(`orderDetails/${row.order_id}`)}>{row.order}</StyledTableCell>
                                    <StyledTableCell> {new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })
                                        .format(row.total_invoice_nd)
                                        .replace(',', '.')}
                                    </StyledTableCell>
                                    <StyledTableCell> {new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })
                                        .format(row.total_amount)
                                        .replace(',', '.')}
                                    </StyledTableCell>
                                    <StyledTableCell> {new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })
                                        .format(row.relesed_amount)
                                        .replace(',', '.')}
                                    </StyledTableCell>
                                    <StyledTableCell> {new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })
                                        .format(row.awaiting_release)
                                        .replace(',', '.')}
                                    </StyledTableCell>
                                    <StyledTableCell> {new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL',
                                    })
                                        .format(row.remaining_amount)
                                        .replace(',', '.')}
                                    </StyledTableCell>
                                    <StyledTableCell>{row.created_at && moment(row.created_at).format('DD/MM/YYYY HH:mm')}</StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100, 1000, 50000]}
                component="div"
                count={total}
                rowsPerPage={itens}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
