/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */

/* eslint-disable react/function-component-definition */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';


import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Pagination from '@material-ui/lab/Pagination';
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  TableCell,
  styled,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import Loading from '../../loading/Loading';
import FlashContext from '../../contexts/FlashContext';
import api from '../../../service/axios/api';
import routing from '../../../service/routes/routing';

import Button from '../../button/MyButton';

import SelectOptions from './SelectOptions';

const useStyles = makeStyles(() =>
  createStyles({
    textNotification: {
      textAlign: 'center',
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      // lineHeight: 'auto',
      fontSize: 14,
      color: '#707070',
    },
    myLabel: {
      // padding: theme.spacing(6),
      color: '#2a3176',
      fontWeight: 'bold',
      margin: 20,
    },
    data: {
      marginTop: -10,
      color: '#717171',
      fontWeight: 500,
      fontFamily: 'Open Sans',
    },
    labelData: {
      color: '#717171',
      fontFamily: 'Open Sans',
    },
    gridItem: {
      marginTop: -40,
      '&& .MuiGrid-item': {
        paddingTop: 0,
        maxHeight: 60,
      },
    },
  }),
);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#005D9F',
    fontFamily: 'Open Sans',
    color: theme.palette.common.white,
  },
  body: {
    color: '#717171',
    fontFamily: 'Open Sans',
    maxHeight: 100,
    maxLines: 10,
    overflow: 'scroll',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: '#41b490',
      color: '#41b490',
    },
  },
}))(TableRow);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const options = [
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];


const EnhancedTableHead = props => {
  const { onSelectAllClick, numSelected, rowCount, templateDownload } = props;

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell component="th">Nome</StyledTableCell>
        <StyledTableCell component="th">CPF</StyledTableCell>
        <StyledTableCell component="th">Razão Social</StyledTableCell>
        <StyledTableCell component="th">Empresa do contrato</StyledTableCell>
        <StyledTableCell component="th">Status</StyledTableCell>
        {/* <StyledTableCell component="th">
          {t('Product.creditCard.table.status')}
        </StyledTableCell> */}
        <StyledTableCell padding="checkbox" align="right">
          <Checkbox
            // color="primary"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </StyledTableCell>
        <StyledTableCell component="th" align="right">
          <GetApp onClick={templateDownload} style={{ cursor: 'pointer' }} />
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  // order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  // orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = function (props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: '#fff',
          // (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  users,
  totalPages,
  setPage,
  page,
  itens,
  setItens,
  search,
  find,
  setStatus,
  setOrder,
  templateDownload,
}) {
  const [selected, setSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataDetails, setDataDetails] = useState();
  const { notifyError, notifySuccess } = useContext(FlashContext);
  const [loading, setLoading] = useState(false);

  // const handleMenu = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMenu = (row, event) => {
    setDataDetails(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setDataDetails();
    setAnchorEl(null);
  };

  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const listProd = [];
    users.forEach((u) => {
      listProd.push({
        id: u.id,
        name: u.name,
        cpf: u.cpf,
        rs: u.LegalEntity && u.LegalEntity.trading_name,
        company: u.LegalEntity && u.LegalEntity.company_name,
        companies: u.LegalEntity,
        // created: pd.created_at && moment
        //   .utc(pd.created_at)
        //   .format('DD/MM/yyyy'),
        // or: '',
        status: u.is_active ? 'Ativo' : 'Desativado',
        all: u,
      });
    });
    setRows(listProd);
  }, [users]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const { t } = useTranslation();

  const sendEmails = () => {
    if (selected.length > 0) {
      setLoading(true);
      api.notification
        .sendCredentials(selected)
        .then(() => {
          notifySuccess('Informações enviadas com sucesso');
          setOpenReset(false);
        })
        .catch(() =>
          notifyError('Erro ao enviar informações de login. Tente novamente.'),
        )
        .finally(() => setLoading(false));
    } else {
      notifyError(
        'Selecione pelo menos um usuário para enviar as informações de login.',
      );
    }
  };

  return (
    <>
      <Loading open={loading} msg="Enviando informações de login..." />
      <SelectOptions
        search={search}
        find={find}
        setStatus={setStatus}
        setOrder={setOrder}
      />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                handleMenu={handleMenu}
                templateDownload={templateDownload}
              />
              <TableBody>
                {rows ? (
                  rows.length > 0 ? (
                    rows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <StyledTableRow
                          tabIndex={-1}
                          key={row.id}
                          style={{ maxHeight: 100 }}
                        >
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding="none"
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell>{row.cpf}</StyledTableCell>
                          <StyledTableCell style={{ maxHeight: 150 }}>
                            <div style={{ maxHeight: 150, overflow: 'auto' }}>
                              {row.companies.map(c => (
                                <p>{c.company_name}</p>
                              ))}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell style={{ maxHeight: 150 }}>
                            <div style={{ maxHeight: 150, overflow: 'auto' }}>
                              {row.companies.map(c => (
                                <p>{c.trading_name}</p>
                              ))}
                            </div>
                          </StyledTableCell>
                          {/* <StyledTableCell>{row.created}</StyledTableCell> */}
                          {/* <StyledTableCell>{row.or}</StyledTableCell> */}
                          <StyledTableCell>{row.status}</StyledTableCell>
                          <StyledTableCell padding="checkbox" align="right">
                            <Checkbox
                              color="primary"
                              onClick={event => handleClick(event, row.id)}
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell component="th" align="right">
                            <MoreVertIcon
                              onClick={e => handleMenu(row, e)}
                              // fontSize="large"
                              style={{ cursor: 'pointer' }}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={7} align="center">
                        Nenhum registro disponível
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={7} align="center" />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="menu-appbar"
            style={{ borderRadius: '25px' }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              className={classes.textNotification}
              onClick={() => history.push(routing.usersDetails(dataDetails.id))}
            >
              Detalhes
            </MenuItem>
          </Menu>
        </Paper>
      </Box>
      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'rigth',
          justifyContent: 'end',
          marginRight: 15,
        }}
      >
        <h4
          style={{
            marginTop: 20,
            marginRight: 30,
            color: '#41B490',
            fontFamily: 'Open Sans',
            fontSize: 14,
            cursor: 'pointer',
          }}
          onClick={() => setOpenReset(true)}
        >
          Resetar senha
        </h4>
      </div>
      {totalPages > 1 && (
        <Grid
          container
          style={{
            marginTop: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          spacing={2}
          direction="row"
        >
          <Grid item>
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Itens"
              value={itens}
              onChange={setItens}
              options={options}
            />
          </Grid>
          <Grid item>
            <Pagination
              size="large"
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
            />
          </Grid>
        </Grid>
      )}
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
      >
        <Grid
          container
          style={{
            border: '2px solid #c5c5c5',
            borderRadius: 10,
          }}
        >
          <h4 className={classes.myLabel}>Detalhes do usuário</h4>
          <Grid
            container
            className={classes.gridItem}
            spacing={1}
            style={{
              padding: 40,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h6 className={classes.labelData}>Nome</h6>
              <p className={classes.data}>
                {dataDetails && dataDetails.all.name}
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h6 className={classes.labelData}>Razão social</h6>
              <p className={classes.data}>
                {dataDetails &&
                  dataDetails.all.LegalEntity &&
                  dataDetails.all.LegalEntity.trading_name}
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h6 className={classes.labelData}>CPF</h6>
              <p className={classes.data}>
                {dataDetails && dataDetails.all.cpf}
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h6 className={classes.labelData}>Empresa do contrato</h6>
              <p className={classes.data}>
                {dataDetails &&
                  dataDetails.all.LegalEntity &&
                  dataDetails.all.LegalEntity.company_name}
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h6 className={classes.labelData}>E-mail</h6>
              <p className={classes.data}>
                {dataDetails && dataDetails.all.email}
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h6 className={classes.labelData}>CNPJ</h6>
              <p className={classes.data}>
                {dataDetails &&
                  dataDetails.all.LegalEntity &&
                  dataDetails.all.LegalEntity.cnpj}
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h6 className={classes.labelData}>Telefone</h6>
              <p className={classes.data}>
                {dataDetails && dataDetails.all.phone}
              </p>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <h6 className={classes.labelData}>Status</h6>
              <p className={classes.data}>
                {dataDetails && dataDetails.all.is_active
                  ? 'Ativo'
                  : 'Desativado'}
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 30,
              marginTop: -20,
            }}
          >
            <Button
              style={{
                height: 40,
                width: 80,
                fontSize: 14,
              }}
              onClick={() => setOpenModal(false)}
              label="ok"
            />
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        onClose={() => setOpenReset(!openReset)}
        open={openReset}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loading} msg="Enviando informações de login..." />
        <DialogTitle id="simple-dialog-title">Senha</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selected.length > 1
              ? 'Deseja realmente resetar as senhas destes usuários? Eles irão receber em seus emails cadastrados um link para gerar uma nova senha. Deseja continuar?'
              : 'Deseja realmente resetar a senha deste usuário? Ele irá receber em seu email cadastrado um link para gerar uma nova senha. Deseja continuar?'}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenReset(false)}
          >
            {t('Order.table.orderCancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={sendEmails}
            color="primary"
            variant="contained"
            label={t('Order.table.orderCancelConfirmBtn')}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
