/* eslint-disable radix */
/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-regex-literals */

import moment from "moment"
import api from "./axios/api"


export const validarCPF = (inputCPF) => {
  let soma = 0;
  let resto;
  // var inputCPF = document.getElementById('cpf').value;
  if (inputCPF === '00000000000') return false;
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 9; i++)
    // eslint-disable-next-line radix
    soma += parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  // eslint-disable-next-line radix
  if (resto !== parseInt(inputCPF.substring(9, 10))) return false;

  soma = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 10; i++)
    soma += parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(inputCPF.substring(10, 11))) return false;
  return true;
}

/* eslint-disable import/prefer-default-export */
export const filterPt = (strTxt) => {
  strTxt = strTxt?.toLowerCase()
  strTxt = strTxt?.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
  strTxt = strTxt?.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
  strTxt = strTxt?.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
  strTxt = strTxt?.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
  strTxt = strTxt?.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
  strTxt = strTxt?.replace(new RegExp('[Ç]', 'gi'), 'c')
  return strTxt
}

export const validateDocExtern = async (res, listOk, listNoOk) => {
  const dataHoje = moment(new Date())
  const resCPF = res.retorno?.notFindCPF
  const resCNPJ = res.retorno?.notFindCNPJ
  // resCNPJ.push({doc: '57518038000178', name: 'DEBORA BREZAN SHIRAIWA TECNOLOGIA DA INFORMACAO LTDA'})

  console.log(res)

  for (let y = 0; y < resCNPJ.length; y++) {
    const resp = await api.validateDoc.cnpj(resCNPJ[y].doc)
    if (resp) {
      const apiFullName = resp?.razao_social
      const dbFullName = resCNPJ[y].name

      if (apiFullName.toUpperCase() === dbFullName.toUpperCase()) {
        if (resp.descricao_situacao_cadastral === 'ATIVA') {
          listOk.push(resCNPJ[y])
        } else {
          listNoOk.push({ ...resCNPJ[y], motivo: `Situação cadastral: ${resp.descricao_situacao_cadastral}` })
        }
      } else {
        listNoOk.push({ ...resCNPJ[y], motivo: `Diferença da razão social. Razão social esperada ${apiFullName}` })
      }
    } else {
      listNoOk.push({ ...resCNPJ[y], motivo: 'CNPJ inválido' })
    }
  }

  let token = {}
  try{
    token = await api.validateDoc.loginSerpro()
  }catch(e){
    console.log('LOGIN')
    console.log(e)
    // api.order.emailFail(e)
  }
  
  // console.log(token?.access_token)

  for (let x = 0; x < resCPF.length; x++) {
    // console.log(String(resCPF[x].doc)?.length)
    console.log(!resCPF[x].status)
    console.log('lista não ok', listNoOk)
    if (!resCPF[x].status) {
      if (String(resCPF[x].doc)?.length === 11) {
        try {
          console.log('Indo para Serpro')
          const resp = await api.validateDoc.cpf(resCPF[x].doc, token?.access_token)
          if (resp) {
            console.log(resp)
            listOk.push(resp)

            const apiFullName = resp.nome?.trim().split(' ')
            const dbFullName = resCPF[x].name?.trim().split(' ')
            const firstApiName = filterPt(apiFullName?.[0].toUpperCase())
            const dbFirstName = filterPt(dbFullName?.[0].toUpperCase())
            const lastApiName = filterPt(apiFullName?.[apiFullName.length - 1].toUpperCase())
            const dbLastName = filterPt(dbFullName?.[dbFullName.length - 1].toUpperCase())

            if (resp.situacao?.descricao?.trim()?.toUpperCase() === 'REGULAR') {
              if (firstApiName.toUpperCase() === dbFirstName.toUpperCase() && lastApiName.toUpperCase() === dbLastName.toUpperCase()) {
                const day = resp.nascimento?.substring(0, 2)
                const month = resp.nascimento?.substring(2, 4)
                const year = resp.nascimento?.substring(4, 8)
                const dataNasc = moment(`${year}${month}${day}`)
                if ((resCPF[x].type === 'Card' && dataHoje.diff(dataNasc, 'years') >= 18) || resCPF[x].type === 'Transfer' || resCPF[x].type !== 'Card' ) {
                  console.log('ok')
                } else {
                  // listOk.push(resp)
                  listNoOk.push({ ...resCPF[x], motivo: `Beneficiário com idade menor que ${resCPF[x].type === 'Transfer' ? '16' : '18'} anos. Idade ${dataHoje.diff(dataNasc, 'years')} anos` })
                }
              } else {
                listNoOk.push({ ...resCPF[x], motivo: `Diferença do nome. Nome esperado ${resp.nome}` })
              }

            } else if(resp.situacao?.descricao?.trim()?.toUpperCase() === 'TITULAR FALECIDO'){
              console.log('ok')
            } else if(resp.situacao?.descricao?.trim()?.toUpperCase() === 'PENDENTE DE REGULARIZAÇÃO'){
              console.log('Pendente de regularização')
              console.log(resCPF[x].type?.toUpperCase())
              if(resCPF[x].type?.toUpperCase() === 'DISCHARGE' || resCPF[x].type?.toUpperCase() === 'DESCARGA' ||resCPF[x].type?.toUpperCase() === 'DESCARGAELEMENT' || resCPF[x].type?.toUpperCase() === 'DISCHARGEELEMENT'){
                console.log(`PENDENTE DE REGULARIZAÇÃO -> ${resCPF[x].type}`)
              }
            } else {
              listNoOk.push({ ...resCPF[x], motivo: `Situação do CPF: ${resp.situacao?.descricao}` })
            }
          } else {
            listNoOk.push({ ...resCPF[x], motivo: 'CPF inválido' })
          }
        } catch (e) {
          console.log(e)
          const completError = e;
          completError.user = {...resCPF[x]}
          completError.typeError = resCPF[x].type

          api.order.emailFail(completError)
          // reload
          if(e.status === 400){
            listNoOk.push({ ...resCPF[x], motivo: 'O número de CPF informado não é válido.' })
          }
          if(e.status === 422){
             if (resCPF[x].type === 'Card' ){
              listNoOk.push({ ...resCPF[x], motivo: 'LGPD: Dados de Menor de Idade' })
             }
            
          }
          if(e.status === 404){
            listNoOk.push({ ...resCPF[x], motivo: 'Não existe CPF com o número de inscrição informado' })
          }
          if(e.status === 401){
            token = await api.validateDoc.loginSerpro()

            try{
              const resp = await api.validateDoc.cpf(resCPF[x].doc, token?.access_token)
              if (resp) {
                listOk.push(resp)
    
                const apiFullName = resp.nome?.trim().split(' ')
                const dbFullName = resCPF[x].name?.trim().split(' ')
                const firstApiName = filterPt(apiFullName?.[0].toUpperCase())
                const dbFirstName = filterPt(dbFullName?.[0].toUpperCase())
                const lastApiName = filterPt(apiFullName?.[apiFullName.length - 1].toUpperCase())
                const dbLastName = filterPt(dbFullName?.[dbFullName.length - 1].toUpperCase())
    
                console.log(resCPF[x].type)
                if (resp.situacao?.descricao?.toUpperCase() === 'REGULAR') {
                  if (firstApiName.toUpperCase() === dbFirstName.toUpperCase() && lastApiName.toUpperCase() === dbLastName.toUpperCase()) {
                    const day = resp.nascimento?.substring(0, 2)
                    const month = resp.nascimento?.substring(2, 4)
                    const year = resp.nascimento?.substring(4, 8)
                    const dataNasc = moment(`${year}${month}${day}`)
                    console.log(resCPF[x].type)
                    if ((resCPF[x].type === 'Card' && dataHoje.diff(dataNasc, 'years') >= 18) || resCPF[x].type === 'Transfer') {
                      console.log('ok')
                    } else {
                      // listOk.push(resp)
                      listNoOk.push({ ...resCPF[x], motivo: `Beneficiário com idade menor que ${resCPF[x].type === 'Transfer' ? '16' : '18'} anos. Idade ${dataHoje.diff(dataNasc, 'years')} anos` })
                    }
                  } else {
                    listNoOk.push({ ...resCPF[x], motivo: `Diferença do nome. Nome esperado ${resp.nome}` })
                  }
    
                }  if(resp.situacao?.descricao?.toUpperCase() === 'TITULAR FALECIDO'){
                  console.log('ok')
                } else {
                  listNoOk.push({ ...resCPF[x], motivo: `Situação do CPF: ${resp.situacao?.descricao}` })
                }
              } else {
                listNoOk.push({ ...resCPF[x], motivo: 'CPF inválido' })
              }
            }catch(error){
              // api.order.emailFail(error)
              console.log(error)
            }
          }
        }
      }
      else {
        listNoOk.push({ ...resCPF[x], motivo: 'CPF inválido' })
      }
    } else {
      listNoOk.push({ ...resCPF[x], motivo: resCPF[x].status })
    }
  }

  console.log('RETORNANDO', listNoOk, listOk)
  return 'finished'
}

export const cleanNumber = value => {
  const tmp = value.replace(/[/().\s-]+/g, '');
  return tmp;
}

export const validateCpf = (value) => {
  let sum = 0;
  let rest;

  const data = value.replace(/\D/g, "");
  if (data.length === 11) {
    if (!data.split("").every((c) => c === data[0])) {
      for (let i = 0; i < 9; i++) {
        sum += Number(data[i]) * (10 - i);
      }

      rest = (sum * 10) % 11;
      if (rest === 10 || rest === 11) {
        rest = 0;
      }

      if (rest === Number(data[9])) {
        sum = 0;
        for (let i = 0; i < 10; i++) {
          sum += Number(data[i]) * (11 - i);
        }

        rest = (sum * 10) % 11;
        if (rest === 10 || rest === 11) {
          rest = 0;
        }

        if (rest === Number(data[10])) {
          return true;
        }
      }
    }
  }
  return false;
};

export const validateDocument = (value) => {
  let sum = 0;
  let rest;

  const data = value.replace(/\D/g, "");
  if (data.length === 11) {
    if (!data.split("").every((c) => c === data[0])) {
      for (let i = 0; i < 9; i++) {
        sum += Number(data[i]) * (10 - i);
      }

      rest = (sum * 10) % 11;
      if (rest === 10 || rest === 11) {
        rest = 0;
      }

      if (rest === Number(data[9])) {
        sum = 0;
        for (let i = 0; i < 10; i++) {
          sum += Number(data[i]) * (11 - i);
        }

        rest = (sum * 10) % 11;
        if (rest === 10 || rest === 11) {
          rest = 0;
        }

        if (rest === Number(data[10])) {
          return true;
        }
      }
    }
  }

  // CNPJ
  if (data == "") return false;

  if (data.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    data == "00000000000000" ||
    data == "11111111111111" ||
    data == "22222222222222" ||
    data == "33333333333333" ||
    data == "44444444444444" ||
    data == "55555555555555" ||
    data == "66666666666666" ||
    data == "77777777777777" ||
    data == "88888888888888" ||
    data == "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = data.length - 2;
  let numeros = data.substring(0, tamanho);
  const digitos = data.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    const mySoma = Number(numeros.charAt(tamanho - i)) * pos--;
    soma += mySoma;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado?.toString() != digitos.charAt(0)) return false;

  // eslint-disable-next-line operator-assignment
  tamanho = tamanho + 1;
  numeros = data.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    const mySoma = Number(numeros.charAt(tamanho - i)) * pos--;
    soma += mySoma;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado?.toString() != digitos.charAt(1)) return false;

  return true;
  // return false;
};