/* eslint-disable no-unused-vars */
/* eslint-disable radix */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Grid, TextField, Switch, Tooltip, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import readXlsxFile from 'read-excel-file';
// import _ from 'lodash';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import FlashContext from '../../../components/contexts/FlashContext';
import TableVinc from '../../../components/tables/premiados/TableVinc';
import MyButton from '../../../components/button/MyButton';
import styles from '../../../styles/Layout.module.css';
import api from '../../../service/axios/api';
import { cleanNumber, validateDocExtern } from '../../../service/utils';
import Loading from '../../../components/loading/Loading';
import DisplayErrorDocument from './DisplayErrorDocument';

const useStyles = makeStyles(() => ({
  myTextField: {
    backgroundColor: 'none',
    boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
    border: 'none',
    color: '#717171',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#717171',
  },
  mySwitch: {
    '&.MuiSwitch-colorPrimary.MuiSwitch-checked': {
      color: 'green',
    },
  },
  checked: {},
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#41b490',
    },
    '&$checked + $track': {
      backgroundColor: '#41b490',
    },
  },
  track: {},
}));

// eslint-disable-next-line prettier/prettier
export default function CardVinc({
  nf,
  setNf,
  boleto,
  setBoleto,
  paymentDate,
  setPaymentDate,
  isNew,
  orderName,
  setOrderName,
  nfComments,
  setNfComments,
  cardVinc,
  setCardVinc,
  setOk
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [cpf, setCpf] = useState('');
  const [name, setName] = useState('');
  const { notifyError } = useContext(FlashContext);
  const fileXls = useRef(null);
  const [myFile, setMyfile] = useState();
  const [openModal, setOpenModal] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [erros, setErros] = useState([])
  const [loading, setLoading] = useState(false)

  const handleChangeNf = e => {
    setNf(e.target.value)
    if (e.target.value === 'NFND') {
      setOpenModal(true)
    }
  };

  function validarCPF(inputCPF) {
    let soma = 0;
    let resto;
    // var inputCPF = document.getElementById('cpf').value;

    if (inputCPF === '00000000000') return false;
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 9; i++)
      // eslint-disable-next-line radix
      soma += parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    // eslint-disable-next-line radix
    if (resto !== parseInt(inputCPF.substring(9, 10))) return false;

    soma = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 10; i++)
      soma += parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(inputCPF.substring(10, 11))) return false;
    return true;
  }

  const addPerson = () => {
    if (name !== '' && cpf !== '') {
      setLoading(true)
      if (validarCPF(cpf)) {
        const listOk = []
        const listNoOk = []
        const consultCpf = [];
        const consultaCnpj = [];

        const doc = cleanNumber(cpf)
        if(doc.trim()?.length > 11){
          // consultaCnpj.push({doc: cpf, name})
        }else{
          consultCpf.push({doc, name})
        }

        api.order.consultaBeneficiario(consultCpf, consultaCnpj, 'Card')
        .then(async(res)=>{
          try{
          if(res.retorno?.notFindCNPJ?.length > 0 || res.retorno?.notFindCPF?.length > 0){
            await validateDocExtern(res, listOk, listNoOk, 'Card')
            if(listNoOk.length > 0){
              fileXls.current.value = ''
              setErros(listNoOk)
              setOk(listOk)
              setErrorModal(true)
              setLoading(false)
            }else{
              if (cardVinc.length === 0) {
                setCardVinc([{ name, cpf: doc }]);
              } else {
                setCardVinc([...cardVinc, { name, cpf: doc }]);
              }
              setOk(listOk)
              setName('');
              setCpf('');
              setLoading(false)
            }
          }else{
            if (cardVinc.length === 0) {
              setCardVinc([{ name, cpf: doc }]);
            } else {
              setCardVinc([...cardVinc, { name, cpf: doc }]);
            }
            setName('');
            setCpf('');
            setOk(listOk)
            setLoading(false)
          }
          }catch(e){
            console.log(e)
            setLoading(false)
          }
          
        })
        .catch((e)=>{
          console.log(e)
          setLoading(false)
        })

      } else {
        setLoading(false)
        notifyError(t('newOrder.cardVinc.cpfError'));
      }
    }
  };

  useEffect(() => {
    if (myFile) {
      setLoading(true)
      const dataTable = [];
      const consultCpf = []
      readXlsxFile(myFile).then(r => {
        // const keys = r[0];
        r.forEach((data, i) => {
          if (i > 0) {
            if (validarCPF(String(data[1]))) {
              dataTable.push({
                name: data[0],
                cpf: String(data[1]),
              });
              const doc = cleanNumber(data[1])
              consultCpf.push({doc, name: data[0]})
            } 
            else {
              notifyError(`${data[0]} não foi inserido. CPF inválido.`);
            }
          }
        });
        const listOk = []
        const listNoOk = []

        api.order.consultaBeneficiario(consultCpf, [], 'Card')
        .then(async(res)=>{
          try{
            if(res.retorno?.notFindCNPJ?.length > 0 || res.retorno?.notFindCPF?.length > 0){
            await validateDocExtern(res, listOk, listNoOk, 'Card')
            if(listNoOk.length > 0){
              fileXls.current.value = ''
              setErros(listNoOk)
              setOk(listOk)
              setErrorModal(true)
              setLoading(false)
            }else{
              if (cardVinc.length === 0) {
                setCardVinc(dataTable);
              } else {
                setCardVinc([...cardVinc, ...dataTable]);
              }
              setLoading(false)
              setOk(listOk)
            }
          }else{
            if (cardVinc.length === 0) {
              setCardVinc(dataTable);
            } else {
              setCardVinc([...cardVinc, ...dataTable]);
            }
            setOk(listOk)
            setLoading(false)
          }
          }catch(e){
            console.log(e)
            setLoading(false)
          }
          
        })
        .catch((e)=>{
          console.log(e)
          setLoading(false)
        })


      });
    }
  }, [myFile]);

  const downloadModel = () => {
    const url =
      'https://epp-storage.s3.amazonaws.com/templates/Vinculo_template.xlsx';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Vinculo_template'); // or any other extension
    document.body.appendChild(link);
    link.click();
  };
  return (
    <div className="box-new-card">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h6 className={styles.textBoxInform}>{t('newOrder.cardVinc.label')}</h6>
        {isNew && (
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Tipo de documento</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                row
                onChange={handleChangeNf}
                value={nf}
              >
                <FormControlLabel value="NF" control={<Radio />} label="NF" />
                <FormControlLabel value="NFND" control={<Radio />} label="NF+ND" />
                {!api.currentUser.user.role.is_client && <FormControlLabel value="ND" control={<Radio />} label="ND" />}
              </RadioGroup>
            </FormControl>
          </div>
        )}
      </div>

      {isNew && (
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid item xs={5} style={{ marginTop: '15px', marginRight: '10px' }}>
            <TextField
              className={classes.text}
              variant="outlined"
              size="small"
              fullWidth
              placeholder={t('newOrder.cardVinc.orderName')}
              value={orderName}
              onChange={e => setOrderName(e.target.value)}
              // inputRef={refQtdCards}
              InputProps={{
                classes: {
                  notchedOutline: classes.myTextField,
                  input: classes.text,
                },
              }}
            />
          </Grid>
          <Grid item xs={5} style={{ marginTop: '15px', marginLeft: '10px' }}>
            <TextField
              className={classes.text}
              variant="outlined"
              size="small"
              fullWidth
              placeholder={t('newOrder.cardVinc.comments')}
              value={nfComments}
              onChange={e => setNfComments(e.target.value)}
              InputProps={{
                classes: {
                  notchedOutline: classes.myTextField,
                  input: classes.text,
                },
              }}
            />
          </Grid>
        </Grid>
      )}

      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          justifyContent: 'end',
          alignItems: 'end',
          paddingBottom: 10,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Grid item xs={5}>
          <p className={styles.labelTitleResum}>
            {t('newOrder.cardVinc.name')}
          </p>
          <TextField
            className={classes.text}
            variant="outlined"
            size="small"
            fullWidth
            // placeholder="Nome do pedido"
            value={name}
            onChange={e => setName(e.target.value)}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <p className={styles.labelTitleResum}>{t('newOrder.cardVinc.cpf')}</p>
          <TextField
            type="number"
            className={classes.text}
            variant="outlined"
            size="small"
            fullWidth
            value={cpf}
            onChange={e => setCpf(e.target.value)}
            // inputRef={refCpf}
            InputProps={{
              classes: {
                notchedOutline: classes.myTextField,
                input: classes.text,
              },
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <MyButton
              style={{
                width: 100,
                height: 40,
                borderRadius: 10,
                marginLeft: 30,
              }}
              onClick={addPerson}
              label={t('newOrder.cardVinc.btnAdd')}
            />
            <MyButton
              style={{
                width: 100,
                height: 40,
                borderRadius: 10,
                marginLeft: 10,
              }}
              onClick={() => fileXls.current.click()}
              label="Planilha"
            />
            <Tooltip title="Download modelo de planilha">
              <img
                id="refresh"
                src="./img/download.svg"
                alt="logo"
                style={{
                  height: 15,
                  width: 15,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
                onClick={downloadModel}
              />
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: 10,
        }}
      >
        <Grid item xs={12}>
          <TableVinc
            setOrders={setCardVinc}
            orders={cardVinc}
            totalPages={1}
            page={1}
            setCpf={setCpf}
            setName={setName}
            // setPage={setPage}
            itens={10}
            // setItens={setItens}
          />
        </Grid>
      </Grid>
      <input
        ref={fileXls}
        type="file"
        style={{
          opacity: 0,
        }}
        // accept="media_type"
        onChange={() => setMyfile(fileXls.current.files[0])}
        className="form-control"
        id="inputNumSerie"
        // placeholder="Arquivo DWG do projeto"
      />
      <Loading open={loading} msg="Validando documentos..." />
      <Dialog open={openModal}>
        <DialogTitle>Importante</DialogTitle>
        <DialogContent>
          <DialogContentText>Você selecionou a opção de emissão de NF + ND, com isso receberá uma Nota Fiscal com os valores de Serviços e uma Nota de Débito com os valores de repasse. Confirma?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MyButton
            style={{
              width: 120,
              height: 40,
              borderRadius: 10,
              backgroundColor: '#657588',
            }}
            onClick={() => {
              setNf('NF')
              setOpenModal(false)
            }}
            label="NÃO"
          />
          <MyButton
            style={{
              width: 120,
              height: 40,
              borderRadius: 10,
            }}
            onClick={() => setOpenModal(false)}
            label="SIM"
          />
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={() => setErrorModal(false)}
        open={errorModal}
        fullWidth
        maxWidth="md"
      >
        <DisplayErrorDocument
          errors={erros}
          setModalError={setErrorModal}
        />
      </Dialog>
    </div>
  );
}
