/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useContext, useState } from 'react';
import { Paper } from '@material-ui/core';
import StepperContext, { StepperProvider } from '../../context/StepperContext';

// import Contract from '@/types/Contract';
import CompanyForm from './CompanyForm';
import ContractReviewForm from './ContractReviewForm';
import DeliveryAddressForm from './DeliveryAddressForm';
import ProductDetailsForm from './ProductDetailsForm';
import RevenueForm from './RevenueForm';
import company from './defaultStates/company';
import { productDetails } from './defaultStates/productDetails';
// import FlashContext from '../../../../components/contexts/FlashContext';
// import defaultContract from './defaultStates/contract';

// const contact = {
//   uid: '',
//   fullName: '',
//   cpf: '',
//   companyRole: '',
//   companyArea: '',
//   phone: '',
//   mobile: '',
//   email: '',
// };
const revenue = {
  calculation: 'normal',
  currency: 'brl',
  paymentMethod: 'transfer',
  isInvoiceTopup: false,
  issuingCompanyId: 1,
  bankAccountId: 1,
  serviceCodeId: 8,
  signatureDate: new Date(),
  expirationDate: new Date(),
  isSigned: false,
};

const otherAddress = {
  postalCode: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  city: '',
  state: '',
  receiver: '',
};

const deliveryAddress = {
  postalCode: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  city: '',
  state: '',
  receiver: '',
  deliveryAgent: 'logistics',
  isCentralizedDelivery: true,
  deliveryService: 'doc+',
  otherAddress,
};

// const company = {
//   cnpj: '',
//   legalName: '',
//   email: '',
//   website: '',
//   comments: '',
//   tradingName: '',
//   phone: '',
//   mobile: '',
//   admin: contact,
// };

const defaultContract = {
  id: '0',
  isActive: true,
  pdfKey: '',
  company: {
    ...company,
    contacts: [
      {
        name: '',
        cpf: '',
        role: '',
        phone: '',
        mobile: '',
        email: '',
        department: '',
      },
    ],
  },
  productDetails,
  deliveryAddress,
  revenue,
};

function StepForm({
  contract,
  control,
  edit,
  myAgent,
  duplicate,
  myComission,
}) {
  const stepper = useContext(StepperContext);
  const [agent, setAgent] = useState(myAgent || []);
  const [agentComission, setAgentComission] = useState(myComission);
  // const { notifyError } = useContext(FlashContext);

  switch (stepper.step) {
    case 1:
      return (
        <CompanyForm
          company={contract.company}
          controlE={control}
          edit={edit}
        />
      );
    case 2:
      return <DeliveryAddressForm deliveryAddress={contract.deliveryAddress} companyAddress={contract.companyAddress} />;
    case 3:
      return (
        <ProductDetailsForm
          productDetails={contract.productDetails}
          edit={edit}
          agent={agent}
          setAgent={setAgent}
          agentComission={agentComission}
          setAgentComission={setAgentComission}
        />
      );
    // if (agent) {
    //   return (

    //   );
    // }
    // notifyError('Informe o agente comercial');
    // break;
    case 4:
      return <RevenueForm revenue={contract.revenue} />;
    default:
      return (
        <ContractReviewForm
          contract={contract}
          agent={agent}
          agentComission={agentComission}
          duplicate={duplicate}
        />
      );
  }
}

function ContractForm({
  contract = defaultContract,
  disabled = false,
  agent,
  agentComission,
  onSuccess,
  code,
  titleModal,
  actionModal,
  control,
  edit,
  duplicate,
}) {
  const [data, setData] = useState({
    ...contract,
    revenue: {
      ...contract.revenue,
      signatureDate: contract.revenue?.signatureDate
        ? new Date(contract.revenue?.signatureDate)
        : undefined,
      expirationDate: contract.revenue?.expirationDate
        ? new Date(contract.revenue?.expirationDate)
        : undefined,
    },
  });

  function updateData(stepData) {
    if (stepData) {
      setData(prevData => {
        return { ...prevData, ...stepData };
      });
    }
  }

  return (
    <Paper elevation={4}>
      <StepperProvider
        disabled={disabled}
        onChange={updateData}
        onSuccess={onSuccess}
        code={code}
        titleModal={titleModal}
        actionModal={actionModal}
      >
        <StepForm
          contract={data}
          control={control}
          edit={edit}
          duplicate={duplicate}
          myAgent={agent}
          myComission={agentComission}
        />
      </StepperProvider>
    </Paper>
  );
}

export default ContractForm;
