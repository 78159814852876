/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/aria-role */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
// import { makeStyles } from '@mui/styles';
import { Grid, Avatar, MenuItem, Menu } from '@material-ui/core';
import routing from '../../service/routes/routing';
// eslint-disable-next-line no-unused-vars
import api from '../../service/axios/api';
import PermissionComponent from '../../components/permissionComponent/PermissionComponent';
import './dashBoard.css';
import UserRoles from '../../service/acl/UserRoles';

// eslint-disable-next-line no-unused-vars
const myuseStyles = makeStyles(theme => ({
  myButton: {
    color: '#2a3176',
    backgroundColor: '#f4f4f4',
    fontWeight: 'bold',
    // minWidth: 200,
  },
  rootGrid: {
    display: 'flex',
    justifyContent: 'center',
    padding: 40,
  },
  myGrid: {
    margin: 10,
    minWidth: 320,
    maxWidth: 320,
    minHeight: 170,
    // marginBottom: -30,
    backgroundColor: '#f4f4f4',
    // margin: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    display: 'block',
    textAlign: 'center',
    cursor: 'pointer',
    border: '2px solid #c5c5c5',
    borderRadius: 10,
    // direction: 'column',
  },
  myGridS: {
    minWidth: 320,
    maxWidth: 320,
    minHeight: 170,
    backgroundColor: '#2A3176',
    margin: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    display: 'block',
    textAlign: 'center',
    cursor: 'pointer',
    border: '2px solid #c5c5c5',
    borderRadius: 10,
    // direction: 'column',
  },
  myLabel: {
    padding: theme.spacing(1),
    fontFamily: 'Open Sans',
    color: '#707070',
    fontWeight: 'bold',
  },
  myLabelS: {
    padding: theme.spacing(1),
    fontFamily: 'Open Sans',
    color: '#fff',
    fontWeight: 'bold',
  },
}));

export default function dashBoard() {
  const classes = myuseStyles();
  const { t } = useTranslation();
  const [language, setLanguage] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const history = useHistory();
  const [selected, setSelected] = useState();

  // const userLocale = navigator?.languages?.length
  //   ? navigator.languages[0]
  //   : navigator.language;

  // console.log('1', userLocale.substring(0, 2)); // 👉️ "pr-BR", essa opção vai retornar o idioma preferencial

  // Ou da seguinte maneira 👇️
  // console.log('2', navigator.languages); //

  useEffect(() => {
    api.language
      .get()
      .then(l => {
        if (l) {
          setLanguage(l);
        } else {
          setLanguage('pt');
        }
      })
      .catch(() => {
        setLanguage('pt');
      });
  }, []);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguage = lng => {
    api.language.set(lng);
    // i18n.changeLanguage(lng);
    window.location.reload();
    handleClose();
  };

  const click = e => {
    if (e === 'painel') {
      if (api.currentUser.user.role.id === '3') {
        setSelected(e);
        history.push(routing[`${e}c`]);
      } else if (
        api.currentUser.id === '8' ||
        api.currentUser.id === '9' ||
        api.currentUser.id === '278' ||
        api.currentUser.id === '214' ||
        api.currentUser.id === '215' ||
        api.currentUser.id === '255'||
        api.currentUser.id === '480'
      ) {
        setSelected(e);
        history.push(routing[e]);
      }
    } else {
      setSelected(e);
      history.push(routing[e]);
    }
  };

  return (
    <>
      <Grid
        className="baseAvatar"
        style={{
          marginTop: 5,
          display: 'flex',
          justifyContent: 'end',
          backgroundColor: 'transparent',
        }}
      >
        {language === 'en' && (
          <Avatar
            alt="Remy Sharp"
            src="./img/eua.webp"
            style={{ marginTop: 20, marginLeft: -60 }}
            onClick={handleMenu}
          />
        )}
        {language === 'es' && (
          <Avatar
            alt="Remy Sharp"
            src="./img/espanha.webp"
            style={{ marginTop: 20, marginLeft: -60 }}
            onClick={handleMenu}
          />
        )}
        {language === 'pt' && (
          <Avatar
            alt="Remy Sharp"
            src="./img/brasil.webp"
            style={{ marginTop: 20 }}
            onClick={handleMenu}
          />
        )}
        {!language && (
          <Avatar
            alt="Remy Sharp"
            src="./img/brasil.webp"
            style={{ marginTop: 20, marginLeft: -60 }}
            onClick={handleMenu}
          />
        )}
      </Grid>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {language !== 'en' && (
          <MenuItem
            // className={classes.textNotification}
            onClick={() => handleLanguage('en')}
          >
            <Avatar
              alt="English"
              src="./img/eua.webp"
              style={{ width: 30, height: 30, marginRight: 15 }}
            />
            English
          </MenuItem>
        )}

        {language !== 'pt' && (
          <MenuItem
            // className={classes.textNotification}
            onClick={() => handleLanguage('pt')}
          >
            <Avatar
              alt="Portugues"
              src="./img/brasil.webp"
              style={{ width: 30, height: 30, marginRight: 15 }}
            />
            Português
          </MenuItem>
        )}

        {language !== 'es' && (
          <MenuItem
            // className={classes.textNotification}
            onClick={() => handleLanguage('es')}
          >
            <Avatar
              alt="Spanish"
              src="./img/espanha.webp"
              style={{ width: 30, height: 30, marginRight: 15 }}
            />
            Spanish
          </MenuItem>
        )}
      </Menu>
      <div style={{ marginTop: '1%' }}>
        <Grid container spacing={4} className={classes.rootGrid}>
          <PermissionComponent role={UserRoles.DASHBOARD_ORDERS}>
            <Grid
              className={
                selected === 'orders' ? classes.myGridS : classes.myGrid
              }
              // style={{ marginRight: 20 }}
              item
              xs={12}
              sm={8}
              md={2}
              lg={4}
              xl={2}
              onClick={() => click('orders')}
            >
              <img src="./img/orders.png" alt="logo" className="img-2" />
              <p
                className={
                  selected === 'orders' ? classes.myLabelS : classes.myLabel
                }
              >
                {t('DashBoard.orders')}
              </p>
            </Grid>
          </PermissionComponent>
          {/* <Grid
          className={selected === 'product' ? classes.myGridS : classes.myGrid}
          // style={{ marginRight: 20 }}
          item
          xs={12}
          sm={8}
          md={2}
          lg={4}
          xl={2}
          onClick={() => click('product')}
        >
          <img src="./img/product.png" alt="logo" className="img-2" />
          <p className="myLabel">{t('DashBoard.products')}</p>
        </Grid> */}
          <PermissionComponent role={UserRoles.DASHBOARD_BASE_CARDS}>
            <Grid
              className={
                selected === 'product' ? classes.myGridS : classes.myGrid
              }
              // style={{ marginRight: 20 }}
              item
              xs={12}
              sm={8}
              md={2}
              lg={4}
              xl={2}
              onClick={() => click('baseCards')}
            >
              <img src="./img/product.png" alt="logo" className="img-2" />
              <p className="myLabel">{t('DashBoard.baseCards')}</p>
            </Grid>
          </PermissionComponent>
          <PermissionComponent role={UserRoles.DASHBOARD_CONTRACTS}>
            <Grid
              onClick={() => click('allContracts')}
              className={classes.myGrid}
              item
              xs={12}
              sm={8}
              md={2}
              lg={4}
              xl={2}
            >
              <img
                src="./img/contract.png"
                color="#fefe"
                alt="logo"
                className="img-2"
              />
              <p className="myLabel">{t('DashBoard.contract')}</p>
            </Grid>
          </PermissionComponent>
          <PermissionComponent role={UserRoles.DASHBOARD_ACCESSGM}>
            <Grid
              onClick={() => click('fidelityusers')}
              className={classes.myGrid}
              item
              xs={12}
              sm={8}
              md={2}
              lg={4}
              xl={2}
            >
              <img
                src="/img/lastAccess2.png"
                color="#fefe"
                alt="logo"
                className="img-2"
              />
              <p className="myLabel">Últimos acessos</p>
            </Grid>
          </PermissionComponent>
          <PermissionComponent role={UserRoles.DASHBOARD_ACCESSGM}>
            <Grid
              onClick={() => click('reports')}
              className={classes.myGrid}
              item
              xs={12}
              sm={8}
              md={2}
              lg={4}
              xl={2}
            >
              <img
                src="/img/orders.png"
                color="#fefe"
                alt="logo"
                className="img-2"
              />
              {/* <img src="./img/orders.png" alt="logo" className="img-2" /> */}
              <p className="myLabel">Relatórios emitidos</p>
            </Grid>
          </PermissionComponent>
        </Grid>
        <Grid
          container
          spacing={4}
          // style={{
          //   justifyContent: 'center', marginTop: -40,
          // }}
          className="rootGrid"
        >
          <PermissionComponent role={UserRoles.DASHBOARD_RELEASE_PAYMENTS}>
            <Grid
              onClick={() => click('releaseMoney')}
              className={classes.myGrid}
              item
              xs={12}
              sm={8}
              md={2}
              lg={4}
              xl={2}
            >
              <div>
                <img
                  src="./img/movimentIcon.svg"
                  alt="logo"
                  className="img-2"
                />
              </div>
              <div>
                <p className="myLabel">{t('DashBoard.release')}</p>
              </div>
            </Grid>
          </PermissionComponent>
          <PermissionComponent role={UserRoles.DASHBOARD_MOVING}>
            <Grid
              // style={{ marginRight: 20 }}
              onClick={() => click('moviment')}
              className={classes.myGrid}
              item
              xs={12}
              sm={8}
              md={2}
              lg={4}
              xl={2}
            >
              <img
                src="./img/money.png"
                color="#fefe"
                alt="logo"
                className="img-2"
              />
              <p className="myLabel">{t('DashBoard.movement')}</p>
            </Grid>
          </PermissionComponent>
          {/* <PermissionComponent role="1,3"> */}
          <PermissionComponent role={UserRoles.DASHBOARD_PANEL}>
            <Grid
              onClick={() => click('painel')}
              className={classes.myGrid}
              item
              xs={12}
              sm={8}
              md={2}
              lg={4}
              xl={2}
            >
              <img
                src="./img/dashboard1.svg"
                color="#fefe"
                alt="logo"
                className="img-2"
              />
              <p className="myLabel">{t('DashBoard.dashBoard')}</p>
            </Grid>
          </PermissionComponent>
          <Grid
            onClick={() => click('tutorial')}
            className={classes.myGrid}
            item
            xs={12}
            sm={8}
            md={2}
            lg={4}
            xl={2}
          >
            <img
              src="./img/formacao1.png"
              color="#fefe"
              alt="logo"
              // className="img-2"
              style={{ width: 120, height: 120, borderRadius: 12 }}
            />
            <p className="myLabel">{t('tutorial')}</p>
          </Grid>
          {/* <Grid
          // style={{ marginRight: 20 }}
          className={classes.myGrid}
          item
          xs={12}
          sm={8}
          md={2}
          lg={4}
          xl={2}
        >
          <img src="./img/contract.png" alt="logo" className="img-2" />
          <p className="myLabel">{t('DashBoard.contract')}</p>
        </Grid> */}
          {/* <Grid className={classes.myGrid} item xs={12} sm={8} md={2} lg={4} xl={2}>
          <p className={classes.myLabel}>
            {t('Product.category.plus')}
          </p>
        </Grid> */}
        </Grid>
        {/* <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        padding: 15, marginTop: 'auto',
      }}
      >
        <button type="button" onClick={() => history.push(routing.orders)} className="pedidos">
          <img src="./img/orders.png" alt="logo" className="img-2" />
          <h1 className="text-1 ">{t('DashBoard.orders')}</h1>
        </button>
        <button type="button" onClick={() => history.push
          (routing.product)} className="pedidos" style={{ marginLeft: 15 }}>
          <img src="./img/product.png" alt="logo" className="img-2" />
          <h1 className="text-1 ">{t('DashBoard.products')}</h1>
        </button>

      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <button type="button" onClick={() => console.log(api.currentSession)} className="pedidos">
          <img src="./img/dashboard.png" alt="logo" className="img-2" />
          <h1 className="text-1 ">{t('DashBoard.dashBoard')}</h1>
        </button>
        <button type="button" onClick={() => console.log
          (api.currentUser)} className="pedidos" style={{ marginLeft: 15 }}>
          <img src="./img/contract.png" alt="logo" className="img-2" />
          <h1 className="text-1 ">{t('DashBoard.contract')}</h1>
        </button>
      </div> */}
      </div>
    </>
  );
}
