/* eslint-disable no-await-in-loop */
/* eslint-disable no-loop-func */
/* eslint-disable guard-for-in */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import locale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import readXlsxFile from 'read-excel-file';
import routing from '../../service/routes/routing';
import TableOrderItens from '../../components/tables/orderItens/TableOrderItens';
import Button from '../../components/button/MyButton';
import styles from '../../styles/Layout.module.css';
import api from '../../service/axios/api';
import Status from '../../service/translateStatus';
import Loading from '../../components/loading/Loading';
import FlashContext from '../../components/contexts/FlashContext';
import NewOrderDetails from './newOrder/NewOrderDetails';
import UserRoles from '../../service/acl/UserRoles';
import PermissionComponent from '../../components/permissionComponent/PermissionComponent';
import DisplayError from './newOrder/DisplayError';
import { cleanNumber, validateDocExtern, validateDocument } from '../../service/utils';
import DisplayErrorDocument from './newOrder/DisplayErrorDocument';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: 262,
    backgroundColor: 'none',
    boxShadow: 'none',
    // border: 'none',
    // fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    // color: state.data.color,
    // backgroundColor: state.data.bgcolor,
    // fontSize: 24,
  }),
  placeholder: (provided, state) => ({
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
    marginTop: -20,
    // paddingBottom: 20,
    // fontSize: 24,
    // marginBottom: 30,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

export default function DetailsOrder() {
  const { t } = useTranslation();
  const { notifyError, notifySuccess } = useContext(FlashContext);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [itens, setItens] = useState({ label: '25', value: 25 });
  const [orders, setOrders] = useState([]);
  const [type, setType] = useState();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [completLoading, setCompletLoading] = useState(false);
  const [msg, setMsg] = useState(t('OrderDetails.searchMsg'));
  const [details, setDetails] = useState();
  const [open, setOpen] = useState(false);
  const [openProgram, setOpenProgram] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openItens, setOpenItens] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPayment, setOpenModalPayment] = useState(false);
  const [deleteItens, setDeleteItens] = useState([]);
  const [myFile, setMyfile] = useState();
  const [excludItem, setExcludItem] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [bankId, setBankId] = useState({ label: '', value: 0 });
  const [banks, setBanks] = useState([]);

  // erros
  const [docsOk, setDocsOk] = useState([])
  const [erros, setErros] = useState([])
  const [trimError, setTrimError] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalDocument, setErrorModalDocument] = useState(false)
  const [openError, setOpenError] = useState(false);
  const [tabelErrors, setTabelErrors] = useState([]);

  const [descontoError, setDescontoError] = useState('');

  const [orderBy, setOrderBy] = useState({ label: 'status', value: '' });

  const [newData, setNewData] = useState([]);

  const [status, setStatus] = useState({ label: '', value: '' });
  const search = useRef(null);
  const fileXls = useRef(null);

  // busca itens filtro
  const [chipData, setChipData] = useState([]);

  async function searchOrder() {
    const cancel = new AbortController();
    api.order
      .details(id, cancel)
      .then(res => {
        setDetails(res.retorno);
      })
      .catch(err => console.log(err));
  }

  useEffect(() => {
    const cancel = new AbortController();
    const query = '?status=Active';
    api.banks
      .get(cancel, query)
      .then(res => {
        const list = [];
        res.retorno.forEach((b, i) => {
          if (i === 0) setBankId({ label: b.bank, value: b.id });
          list.push({ label: b.bank, value: b.id });
        });
        setBanks(list);
      })
      .catch(err => console.log(err));
  }, []);


  const templateDownload = () => {
    setMsg(t('OrderDetails.modelPlanMsg'));
    setLoading(true);
    const cancel = new AbortController();
    if (details.contract_id) {
      api.contract
        .getPlanModel(cancel, details.contract_id)
        .then(res => {
          const url = res.retorno.link;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'carga_template.xlsx'); // or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (myFile) {
      setMsg(t('OrderDetails.savingOrderMsg'));
      setLoading(true);
      let Mytype = ''
      const consultCpf = []
      const consultaCnpj = [];
      const listOk = []
      const listNoOk = []
      const orig = _.cloneDeep(orders);
      readXlsxFile(myFile).then(r => {
        const keys = r[0];
        const intern = {
          id: 0,
          Card: {
            Individual: {
              name: '',
              cpf: '',
            },
            proxy: '',
          },
          product_description: '',
          units: '',
          value: 0,
          date_processing: '',
          date_conciliation: '',
          status: '',
          individual_name: '',
          individual_cpf: '',
          account_number: '',
          bank_number: '',
          branch_number: '',
          subtotal: '',
        };

        r.forEach((data, i) => {
          if (i > 0) {
            if (String(data[1]).toLocaleUpperCase() === 'TRANSFERAMOUNT' || String(data[1]).toLocaleUpperCase() === 'DESCONTO' || String(data[1]).toLocaleUpperCase() === 'TRANSFERTOCC') {
                Mytype = 'Transfer'
                if (data[4]) {
                const doc = cleanNumber(String(data[3]))
                if(validateDocument(doc.trim())){
                  if(doc.trim()?.length >= 14){
                    // consultaCnpj.push({doc: data[3], name: data[2]})
                  }else{
                    // eslint-disable-next-line no-lonely-if
                    if(String(data[1]).toLocaleUpperCase() === 'TRANSFERTOCC'){
                      consultCpf.push({doc, name: data[4], type: Mytype})
                    }else{
                      consultCpf.push({doc, name: data[2], type: Mytype})
                    }
                    
                  }
                }else{
                  // eslint-disable-next-line no-lonely-if
                  if(String(data[1]).toLocaleUpperCase() === 'TRANSFERTOCC'){
                    listNoOk.push({doc, name: data[4], type: Mytype, motivo: 'CPF inválido'})
                  }else{
                    listNoOk.push({doc, name: data[2], type: Mytype, motivo: 'CPF inválido'})
                  }
                  
                }
                intern.id = data[3];
                intern.Card.Individual.name = data[2];
                intern.Card.Individual.cpf = data[3];
                intern.Card.proxy = data[2];
                intern.product_description = 'Carga';
                intern.units = '1';
                intern.subtotal = data[4] && Number(data[4]).toFixed(2);
                intern.status = t('OrderDetails.creatingMsg');
                intern.individual_name = data[2];
                intern.individual_cpf = data[3];
                intern.bank_number = data[5];
                intern.account_number = data[9];
                intern.branch_number = data[6];
                orig.push(_.cloneDeep(intern));
                newData.push(_.cloneDeep(intern));
              }
            } else if (data[5]) {
              if(String(data[1])?.toUpperCase() === 'DISCHARGE' || String(data[1])?.toUpperCase() === 'DESCARGA' ||String(data[1])?.toUpperCase() === 'DESCARGAELEMENT' || String(data[1])?.toUpperCase() === 'DISCHARGEELEMENT'){
                consultCpf.push({ doc, name: data[4], type: data[1] })
                Mytype = String(data[1])
              }else{
                Mytype = 'Card'
              }
              
              const doc = cleanNumber(String(data[3]))
              if(validateDocument(doc.trim())){
                if(doc.trim()?.length >= 14){
                  // consultaCnpj.push({doc: data[3], name: data[2]})
                }else{
                  consultCpf.push({doc, name: data[4], type: Mytype})
                }
              }else{
                listNoOk.push({doc, name: data[4], type: Mytype, motivo: 'CPF inválido'})
              }

              intern.id = data[3];
              intern.Card.Individual.name = data[4];
              intern.Card.Individual.cpf = data[3];
              intern.Card.proxy = data[2];
              intern.product_description = 'Carga';
              intern.units = '1';
              intern.subtotal = data[5] && Number(data[5]).toFixed(2);
              intern.status = t('OrderDetails.creatingMsg');
              intern.individual_name = data[4];
              intern.individual_cpf = data[3];
              orig.push(_.cloneDeep(intern));
              newData.push(_.cloneDeep(intern));
            }
          }
        });

        api.order.consultaBeneficiario(consultCpf, consultaCnpj)
        .then(async(res)=>{
          try{
            if(res.retorno?.notFindCNPJ?.length > 0 || res.retorno?.notFindCPF?.length > 0 || listNoOk.length >0){
            await validateDocExtern(res, listOk, listNoOk)
            if(listNoOk.length > 0){
              listNoOk.forEach((nok)=>{
                const find = orig.findIndex((d) => d[keys[3]] === nok.doc)
                if(find > -1){
                  orig.splice(find, 1)
                }
              })
              // cleanPlan()
              setOrders(orig);
              setErros(listNoOk)
              setDocsOk(listOk)
              setErrorModalDocument(true)
              setLoading(false)
            }else{
              saveOrder(listOk)
              setOrders(orig);
              setDocsOk(listOk)
              setLoading(false)
            }
          }else{
            setOrders(orig);
            setDocsOk(listOk)
            setLoading(false)
            saveOrder(listOk)
          }
          }catch(e){
            console.log(e)
            setLoading(false)
          }
        })
        .catch((e)=>{
          console.log(e)
          setLoading(false)
        })
      });
    }
  }, [myFile]);

  const searchItems = extra => {
    setMsg(t('OrderDetails.searchMsg'));
    setLoading(true);
    const cancel = new AbortController();
    api.order
      .getItems(
        id,
        extra,
        orderBy.value,
        chipData,
        status.value,
        itens.value,
        page,
        cancel,
      )
      .then(res => {
        setTotalPage(res.pages);
        setType(res.type);
        setOrders(res.docs);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const completeOrder = () => {
    if (details && details.order_number) {
      // console.log(details.order_number);
      setCompletLoading(true);
      api.order
        .complete(id)
        .then(r => {
          setCompletLoading(false);
          window.location.reload();
        })
        .catch(err => {
          console.log(err);

          // notifyError(t('OrderDetails.completeErrorMsg'));
          notifyError(t(err.message));
          if (err.params.length > 0) {
            setDescontoError(err.params[0].message);
            setOpenError(true);
            setOpen(false);
          }
          setCompletLoading(false);
        });
    }
  };

  const paymentOrder = () => {
    if (details && details.order_number) {
      // console.log(details.order_number);
      setPaymentLoading(true);
      api.order
        .payment(id, selectedDate, bankId.value)
        .then(r => {
          setPaymentLoading(false);
          window.location.reload();
        })
        .catch(err => {
          console.log(err);
          notifyError(err.message);
          // notifyError(t('OrderDetails.paymentErrorMsg'));
          setPaymentLoading(false);
        });
    }
  };

  const calcelOrder = () => {
    setOpenCancel(false);
    setMsg(t('OrderDetails.cancelingMsg'));
    setLoading(true);
    api.order
      .cancel(id)
      .then(res => console.log(res))
      .catch(err => {
        console.log(err);
        notifyError(err?.message);
      })
      .finally(() => setLoading(false));
  };

  const removeItens = async () => {
    setExcludItem(true);
    if (deleteItens.length > 0) {
      let myErr = false;
      for (const del in deleteItens) {
        const find = orders.find(o => o.id === deleteItens[del]);
        if (find) {
          try {
            const result = await api.order.updateItemAdmin(
              find.id,
              'Canceled',
              find.date_processing,
              find.date_conciliation,
            );
            // console.log(result);
          } catch (err) {
            console.log(err);
            myErr = true;
            notifyError(
              `Falha ao cancelar o item: ${find.name || find.comments}`,
            );
          }
        }
      }

      if (!myErr) {
        notifySuccess('Itens descartados com sucesso!');
      }
      // api.order
      //   .removeItems(deleteItens)
      //   .then(() => {
      searchOrder();
      setDeleteItens([]);
      setOpenItens(false);
      searchItems();
      //   })
      //   .catch(err => {
      //     console.log(err);
      //     notifyError(err.message);
      //   })
      //   .finally(() => {
      setExcludItem(false);
      //   });
    } else {
      notifyError(t('OrderDetails.cancelingErrorMsg'));
    }
  };

  useEffect(() => {
    searchOrder();
    searchItems();
  }, [page, itens, status, orderBy]);

  const saveOrder = (myDockOk) => {
    setMsg(t('OrderDetails.savingOrderMsg'));
    setLoading(true);
    console.log(myFile, id, null, erros, myDockOk || docsOk)
    api.order
      .addItems(myFile, id, null, erros, myDockOk || docsOk)
      .then(res => {
        if (res.err) {
          notifyError(t('OrderDetails.reviewPlanMsg'));
        }
        // reload aqui
        searchOrder();
        searchItems();
      })
      .catch(err => {
        console.log(err)
        searchItems();
        if (err?.params.length > 0) {
          setTabelErrors(err.params);
          setTrimError(err.trimError);
          setErrorModal(true);
        }

        notifyError(err?.message);
      })
      .finally(() => {
        setLoading(false);
        // window.location.reload();
      });
  };

  const closeModal = () => {
    setOpenModal(false);
    window.location.reload();
  };

  const clean = () => {
    const orig = _.cloneDeep(orders);
    newData.forEach(d => {
      const found = orig.indexOf(orig.find(o => o.id === d.id));
      if (found) {
        orig.splice(found, 1);
      }
    });
    setOrders(orig);
    setNewData([]);
    setMyfile();
    fileXls.current.value = null;
  };

  const downloadBoleto = () => {
    if (details.link_boleto) {
      const url = details.link_boleto;
      window.open(url, '_blank');
    }
  };

  const resend = () => {
    setLoading(true);
    api.order
      .addItems(myFile, id, true, erros, docsOk)
      .then(res => {
        if (res.err) {
          notifyError(t('OrderDetails.reviewPlanMsg'));
        }
        setErrorModal(false);
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
        if (err.params.length > 0) {
          setTabelErrors(err.params);
          setTrimError(err.trimError);
          setErrorModal(true);
        }
      })
      .finally(() => {
        setLoading(false);
        // window.location.reload();
      });
  };

  const closeAndSave = () => {
    setErrorModalDocument(false)
    saveOrder()
  }

  const openErros = (err) =>{
    setErros(err)
    setErrorModalDocument(true)
  }

  return (
    <>
      <Loading open={loading} msg={msg} />
      <div className="col-sm-12" style={{ marginTop: 50 }}>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <div>
            <p
              className={styles.textMenu}
              onKeyDown={() => history.push(routing.orders)}
              onClick={() => history.push(routing.orders)}
            >
              {t('initialMenu')}
            </p>
          </div>
        </div>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            // marginTop: -30,
          }}
        >
          <h4 className={styles.textTitle}>
            {t('OrderDetails.title')} {details && details.order_number}{' '}
            {details?.product_type === 'Transfer'
              ? `| ${t('OrderDetails.transfer')}`
              : `| ${t('OrderDetails.card')}`}
          </h4>
        </div>
        <Grid
          container
          spacing={1}
          style={{
            border: '2px solid #c5c5c5',
            borderRadius: 10,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Grid
            container
            spacing={1}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item sm={12}>
              <p className="modal-details-order-box-label-header">
                {t('OrderDetails.summary')}
              </p>
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <p className="modal-box-body-header-label">
                {t('OrderDetails.orderNumber')}
              </p>
              <p className="modal-box-body-value-label">
                {details && details.order_number}
              </p>
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <p className="modal-box-body-header-label">
                {' '}
                {t('OrderDetails.nf')}
              </p>
              <p
                className="modal-box-body-value-label"
                onClick={() =>
                  details &&
                  details.link_nf &&
                  window.open(details && details.link_nf, '_blank')
                }
                style={
                  details && details.link_nf
                    ? { cursor: 'pointer' }
                    : { cursor: 'not-allowed' }
                }
              >
                {details && details.link_nf
                  ? details.number_nf
                  : t('OrderDetails.notContainMsg')}
              </p>
            </Grid>
            {api.currentUser?.user?.role?.id !== '3' &&
              details?.debitNotes?.length > 0 ? (
              <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
                <p className="modal-box-body-header-label">
                  {t('OrderDetails.db')}
                </p>
                {details.debitNotes.map(
                  d =>
                    d.link_debit && (
                      <p
                        key={d.nd_number}
                        style={{ cursor: 'pointer' }}
                        className="modal-box-body-value-label"
                        onClick={() => window.open(d.link_debit)}
                      >
                        {d.nd_number}
                      </p>
                    ),
                )}
              </Grid>
            ) : (
              api.currentUser?.user?.role?.id !== '1' &&
              details?.number_nf &&
              details?.debitNotes?.length > 0 && (
                <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
                  <p className="modal-box-body-header-label">
                    {t('OrderDetails.db')}
                  </p>
                  {details.debitNotes.map(
                    d =>
                      d.link_debit && (
                        <p
                          key={d.nd_number}
                          style={{ cursor: 'pointer' }}
                          className="modal-box-body-value-label"
                          onClick={() => window.open(d.link_debit)}
                        >
                          {d.nd_number}
                        </p>
                      ),
                  )}
                </Grid>
              )
            )}
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <p className="modal-box-body-header-label">
                {t('OrderDetails.product')}
              </p>
              <p className="modal-box-body-value-label">
                {details?.product_type === 'Transfer'
                  ? t('OrderDetails.transfer')
                  : t('OrderDetails.productName')}
              </p>
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <p className="modal-box-body-header-label">
                {t('OrderDetails.rz')}
              </p>
              <p className="modal-box-body-value-label">
                {details && details.client_name}
              </p>
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <p className="modal-box-body-header-label">
                {t('OrderDetails.status')}
              </p>
              <p className="modal-box-body-value-label">
                {details && details.status && Status(details.status, t)}
              </p>
            </Grid>
          </Grid>
          {/* SEGUNDA LINHA */}
          <Grid
            container
            spacing={1}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                {t('OrderDetails.orderBy')}
              </p>
              <p className="modal-box-body-value-label">
                {details && details.user}
              </p>
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                {t('OrderDetails.orderDate')}
              </p>
              <p className="modal-box-body-value-label">
                {details &&
                  details.created_at &&
                  moment.utc(details.created_at).format('DD/MM/yyyy')}
              </p>
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                {t('OrderDetails.bearers')}
              </p>
              <p className="modal-box-body-value-label">
                {details && details.individuals}
              </p>
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                {t('OrderDetails.cnpj')}
              </p>
              <p className="modal-box-body-value-label">
                {details && details.client_cnpj}
              </p>
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <p
                className="modal-box-body-header-label"
                style={{ marginTop: -10 }}
              >
                {t('OrderDetails.notification')}
              </p>
              {details?.errors?.map((e, i) =>
                e.retFile && e.retFile !== '' ? (
                  <p
                    className="modal-box-body-value-label"
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.open(e.retFile)}
                  >
                    {`Planilha erro: ${i + 1}`}
                  </p>
                ) : null,
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          style={{
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid
              container
              spacing={1}
              style={{
                justifyContent: 'start',
                marginLeft: -30,
              }}
            >
              <PermissionComponent role={UserRoles.ORDERS_DETAILS_PLAN}>
                <Grid item>
                  <p className="label-planilhas" onClick={templateDownload}>
                    <img
                      id="planilha"
                      src="../img/download.svg"
                      alt="logo"
                      className="img-planilhas"
                    />
                    {t('OrderDetails.planAwarded')}
                  </p>
                </Grid>
              </PermissionComponent>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid
              container
              spacing={1}
              style={{
                justifyContent: 'end',
              }}
            >
              <PermissionComponent role={UserRoles.ORDERS_DETAILS_PLAN}>
                <Grid item>
                  <p
                    className="label-planilhas"
                    onClick={() => fileXls.current.click()}
                  >
                    <img
                      id="planilha"
                      src="../img/upload.svg"
                      alt="logo"
                      className="img-planilhas"
                    />
                    {t('OrderDetails.planAwarded')}
                  </p>
                </Grid>
              </PermissionComponent>
              <input
                ref={fileXls}
                type="file"
                style={{
                  opacity: 0,
                }}
                onChange={() => setMyfile(fileXls.current.files[0])}
                className="form-control"
                id="inputNumSerie"
                placeholder=""
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <TableOrderItens
        type={type}
        orders={orders}
        totalPages={totalPage}
        consumed={details && details.consumed}
        provision={details && details.provision}
        page={page}
        itens={itens}
        setItens={setItens}
        setPage={setPage}
        setStatus={setStatus}
        // search={search}
        chipData={chipData}
        setChipData={setChipData}
        searchItems={searchItems}
        setSelected={setDeleteItens}
        selected={deleteItens}
        setOrderBy={setOrderBy}
      />
      <Grid
        container
        spacing={1}
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Grid item>
          <PermissionComponent role={UserRoles.ORDERS_DETAILS_TICKET}>
            <p
              className="label-planilhas"
              style={
                details && details.link_boleto
                  ? { color: '#717171B2', cursor: 'pointer' }
                  : { color: '#717171B2', cursor: 'not-allowed' }
              }
              onClick={downloadBoleto}
            >
              <img
                id="planilha"
                src="../img/boleto.svg"
                style={
                  details && details.link_boleto
                    ? { cursor: 'pointer' }
                    : { cursor: 'not-allowed' }
                }
                alt="logo"
                className="img-planilhas"
              />
              {t('OrderDetails.ticket')}
            </p>
          </PermissionComponent>
        </Grid>

        <Grid item>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <PermissionComponent role={UserRoles.ORDERS_DETAILS_ADD_ITEM}>
              <p
                className="label-addItem"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenModal(true)}
              >
                {t('OrderDetails.addItem')}
              </p>
            </PermissionComponent>
            <PermissionComponent role={UserRoles.ORDERS_DETAILS_CANCEL_ITEM}>
              <p
                className="label-descart"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenItens(true)}
              >
                {t('OrderDetails.discardItem')}
              </p>
            </PermissionComponent>
          </div>
        </Grid>
      </Grid>

      <div
        className="col-sm-12"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'rigth',
          justifyContent: 'space-between',
          marginRight: 15,
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
          onClick={() => history.push(routing.orders)}
        >
          <p className="label-goback">{t('OrderDetails.goBack')}</p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            textJustify: 'center',
          }}
        >
          {details?.number_nf && (
            <p
              className="label-addItem"
              style={{ cursor: 'pointer' }}
              onClick={() => setOpenCancel(true)}
            >
              {t('OrderDetails.cancelOrder')}
            </p>
          )}
          {details && details.status === 'Created' && orders.length > 0 && (
            <Button
              style={{ width: 200, height: 40, backgroundColor: '#ffa500' }}
              label={t('OrderDetails.finalizeOrder')}
              onClick={() => {
                if (details?.joyCodeStatus === 'Active') {
                  setOpenProgram(true);
                } else {
                  setOpen(true);
                }
              }}
            />
          )}
          {details &&
            details.status === 'AwaitingPayment' &&
            (api.currentUser.id === '8' ||
              '18' ||
              '214' ||
              '215' ||
              '240' ||
              '287' ||
              '255') && (
              <PermissionComponent role={UserRoles.ORDERS_DETAILS_PAYMENT}>
                <Button
                  style={{ width: 100, height: 40, backgroundColor: '#009BEA' }}
                  label={t('OrderDetails.paidOut')}
                  onClick={() => setOpenModalPayment(true)}
                />
              </PermissionComponent>
            )}
        </div>
      </div>
      <Dialog
        onClose={() => setOpen(!open)}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={completLoading} msg="Completando pedido..." />
        <DialogTitle id="simple-dialog-title">
          {t('OrderDetails.modalTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('OrderDetails.modalCompleteMsg')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpen(false)}
          >
            {t('OrderDetails.completeCancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={completeOrder}
            color="primary"
            variant="contained"
            label={t('OrderDetails.completeConfirmBtn')}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpenProgram(!openProgram)}
        open={openProgram}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={completLoading} msg="Completando pedido..." />
        <DialogTitle id="simple-dialog-title">
          {t('OrderDetails.modalTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* {t('OrderDetails.modalCompleteMsg')} */}
            Este pedido tem fechamento automático programado de{' '}
            {details?.joyCodeInterval} em {details?.joyCodeInterval} dias.
            Deseja realmente fazer o fechamento agora?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenProgram(false)}
          >
            {t('OrderDetails.completeCancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={completeOrder}
            color="primary"
            variant="contained"
            label={t('OrderDetails.completeConfirmBtn')}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpenItens(!openItens)}
        open={openItens}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={excludItem} msg="Excluindo item..." />
        <DialogTitle id="simple-dialog-title">
          {t('OrderDetails.modalTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('OrderDetails.modalRemoveItemMsg')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenItens(false)}
          >
            {t('OrderDetails.completeCancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={removeItens}
            color="primary"
            variant="contained"
            label={t('OrderDetails.completeConfirmBtn')}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpenCancel(!openCancel)}
        open={openCancel}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="simple-dialog-title">
          {t('OrderDetails.modalTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('OrderDetails.modalCancelMsg')}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenCancel(false)}
          >
            {t('OrderDetails.completeCancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={calcelOrder}
            color="primary"
            variant="contained"
            label={t('OrderDetails.completeConfirmBtn')}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpenCancel(!setOpenModalPayment)}
        open={openModalPayment}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={paymentLoading} msg={t('OrderDetails.loadPaymentMsg')} />
        <DialogTitle id="simple-dialog-title">
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Grid>{t('OrderDetails.modalTitle')}</Grid>
            <Grid>{details && details.number_nf && details.number_nf}</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container>
              <Grid item xs={12}>
                {t('OrderDetails.paymentMsg')}
              </Grid>
              <Grid
                container
                style={{
                  // display: 'flex',
                  // justifyContent: 'center',
                  // alignItems: 'center',
                  marginTop: 25,
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p>Data do Pagamento:</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      style={{ backgroundColor: '#fff' }}
                      // label="With keyboard"
                      size="small"
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      InputProps={{ readOnly: true }}
                      InputAdornmentProps={{ position: 'start' }}
                      onChange={date => handleDateChange(date)}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  marginTop: 25,
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <p>Banco de Entrada:</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Select
                    fullWidth
                    styles={customStyles}
                    // style={{ zIndex: 999 }}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Banco"
                    menuPortalTarget={document.body}
                    // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    value={bankId}
                    onChange={setBankId}
                    isSearchable={false}
                    options={banks}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <h6
            style={{
              cursor: 'pointer',
              color: '#657588',
              fontWeight: 'bold',
              fontFamily: 'Open Sans',
              marginTop: 5,
            }}
            onClick={() => setOpenModalPayment(false)}
          >
            {t('OrderDetails.completeCancelBtn')}
          </h6>
          <Button
            style={{ height: 40 }}
            onClick={paymentOrder}
            color="primary"
            variant="contained"
            label={t('OrderDetails.completeConfirmBtn')}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        fullWidth
        maxWidth="lg"
      >
        <NewOrderDetails
          closeModal={closeModal}
          setOpenModal={setOpenModal}
          orderId={id}
          details={details}
          setErros={openErros}
          erros={erros}
          docsOk={docsOk}
          setOk={setDocsOk}
        />
      </Dialog>
      <Dialog
        onClose={() => setErrorModal(false)}
        open={errorModal}
        fullWidth
        maxWidth="md"
      >
        <Loading open={loading} msg="Adicionando items ao pedido" />
        <DisplayError
          errors={tabelErrors}
          setModalError={setErrorModal}
          hasTrim={trimError}
          resend={resend}
        />
      </Dialog>

      {/* erro de desconto */}
      <Dialog onClose={() => setOpenError(false)} open={openError} fullWidth>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {descontoError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ height: 40 }}
            color="primary"
            onClick={() => {
              setOpenError(false);
            }}
            variant="contained"
            label="FECHAR"
          />
        </DialogActions>
      </Dialog>


      <Dialog
        onClose={() => setErrorModalDocument(false)}
        open={errorModalDocument}
        fullWidth
        maxWidth="md"
      >
        <DisplayErrorDocument
          errors={erros}
          setModalError={closeAndSave}
        />
      </Dialog>
    </>
  );
}
