/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

// import { NextPageContext } from 'next';

import { Grid, makeStyles, Typography } from '@material-ui/core';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from '../../../../styles/Layout.module.css';
import api from '../../../../service/axios/api';
import ContractFormAddCompany from '../../components/forms/ContractFormAddCompany';
import company from '../../components/forms/defaultStates/company';
import { productDetails } from '../../components/forms/defaultStates/productDetails';
import SpinnerPage from '../../../../components/loading/Loading';
import routing from '../../../../service/routes/routing';
// import { privateFetch } from '@/util/fetch';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  selectField: {
    minWidth: '200px',
  },
}));

const revenue = {
  calculation: 'normal',
  currency: 'brl',
  paymentMethod: 'transfer',
  isInvoiceTopup: false,
  issuingCompanyId: 1,
  bankAccountId: 1,
  serviceCodeId: 8,
  signatureDate: new Date(),
  expirationDate: new Date(),
  isSigned: false,
};
const deliveryAddress = {
  postalCode: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  city: '',
  state: '',
  receiver: '',
  deliveryAgent: 'logistics',
  isCentralizedDelivery: true,
  deliveryService: 'doc+',
};

const defaultContract = {
  id: '0',
  isActive: true,
  pdfKey: '',
  company: {
    ...company,
    contacts: [
      {
        name: '',
        cpf: '',
        role: '',
        phone: '',
        mobile: '',
        email: '',
        department: '',
      },
    ],
  },
  productDetails,
  deliveryAddress,
  revenue,
};

const cleanCEP = dt => {
  const res = dt.replace('-', '');
  const final = res.replaceAll('.', '');
  return final.trim();
};

function AddCompanie({ idd, contract }) {
  const { t } = useTranslation();
  const router = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const [myContract, setMycontract] = useState();
  const [agent, setAgent] = useState();
  const [agentComission, setAgentComission] = useState();
  const [existIds, setExistIds] = useState([]);
  const [orig, setOrig] = useState([]);

  const formatValue = vl => {
    if (vl) {
      const i = vl.replaceAll('.', ',');
      return `R$ ${i}`;
    }
    return 'R$ 0,00';
  };

  useEffect(() => {
    if (id) {
      const cancel = new AbortController();
      api.contract
        .getSubCompanies(cancel, id)
        .then(res => {
          const listContacts = [];
          const listCompanies = [];
          const listId = [];
          res.retorno.forEach(l => {
            listId.push(l.LegalEntity.cnpj);
            listCompanies.push({
              id: l.LegalEntity.id,
              cnpj: l.LegalEntity.cnpj,
              companyName: l.LegalEntity.company_name,
              tradingName: l.LegalEntity.trading_name,
              email: l.email,
              phone: l.phone,
              mobile: l.mobile,
              website: l.LegalEntity.website,
              comments: l.LegalEntity.comments,
              street_name: l.street_name,
              postalCode: l.postal_code,
              number: l.number,
              complement: l.complement,
              district: l.district,
              city: l.city,
              state: l.state,
              allow_invoice: '',
              is_active: '',
              is_default: '',
            });
          });
          setExistIds(listId);
          // res.docs?.Contract?.ContractUser?.forEach(c => {
          //   listContacts.push({
          //     id: c.User?.id,
          //     name: c.User?.name,
          //     cpf: c.User?.cpf,
          //     role: c.User?.role_id,
          //     phone: c.User?.phone,
          //     mobile: c.User?.mobile,
          //     email: c.User?.email,
          //     department: c.User?.department,
          //   });
          // });
          // if (res.docs?.Contract?.ContractCommercialAgent[0]?.CommercialAgent) {
          //   setAgent({
          //     label:
          //       res.docs?.Contract?.ContractCommercialAgent[0]?.CommercialAgent
          //         .company_name,
          //     value:
          //       res.docs?.Contract?.ContractCommercialAgent[0]?.CommercialAgent
          //         .id,
          //   });
          //   setAgentComission(
          //     res.docs?.Contract?.ContractCommercialAgent[0]?.agent_percentage,
          //   );
          // }
          setMycontract({
            ...defaultContract,
            id,
            company: {
              ...company,
              companies: listCompanies,
              contacts: listContacts,
            },
          });
          setOrig(listCompanies);
        })
        .catch(err => console.log(err));
    }
  }, [id]);

  async function editCompany(data, address, contractId, cnpj) {
    // eslint-disable-next-line no-param-reassign
    address.postalCode = cleanCEP(address.postalCode);
    return api.contract.updateCompany(data, address, contractId, cnpj);
  }

  async function editContract(
    operatorFees,
    companyOperatingFees,
    cardFees,
    campaign,
    mproductDetails,
    mDelivery,
    mrevenue,
    contractId,
  ) {
    return api.contract.updateContract(
      operatorFees,
      companyOperatingFees,
      cardFees,
      campaign,
      mproductDetails,
      mDelivery,
      mrevenue,
      contractId,
    );
  }

  async function editDeliveryAddress(data, contractId) {
    return api.contract.updateDeliveryAddress(data, contractId);
  }

  async function editUsers(users, contractId) {
    return api.contract.updateUser(users, contractId);
  }

  async function onSuccessContract(mycontract) {
    // return axios.put(`/api/contracts/${idd}`, mycontract);
    await editContract(
      mycontract.productDetails.operatorFees,
      mycontract.productDetails.companyOperatingFees,
      mycontract.productDetails.cardFees,
      mycontract.productDetails.campaign,
      mycontract.productDetails,
      mycontract.deliveryAddress,
      mycontract.revenue,
      id,
    );
    await editDeliveryAddress(mycontract.deliveryAddress, id);
    await editUsers(mycontract.company.contacts, id);
    return editCompany(
      mycontract.company,
      mycontract.deliveryAddress,
      id,
      mycontract.company.cnpj,
    );
    // return editDeliveryAddress(mycontract.deliveryAddress, id);
  }

  // const onSuccessContract = mycontract => {
  //   // return axios.put(`/api/contracts/${idd}`, mycontract);
  //   await editCompany()
  // };

  const callback = () => {
    router.push('/allContracts');
  };

  if (!myContract) {
    return <SpinnerPage open />;
  }

  return (
    <>
      <Grid
        className={classes.header}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginTop: 50 }}
      >
        <p
          className={styles.textMenu}
          onKeyDown={() => router.push(routing.allContracts)}
          onClick={() => router.push(routing.allContracts)}
        >
          Menu Inicial
        </p>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* <h4 className={styles.textTitle}>{t('editContract.label')}</h4> */}
        </div>
        {/* <Typography variant="h4" component="h2" gutterBottom>
          Editar contrato
        </Typography> */}
      </Grid>
      {myContract && (
        <ContractFormAddCompany
          contract={myContract}
          contractId={id}
          agent={agent}
          agentComission={agentComission}
          // eslint-disable-next-line react/jsx-no-bind
          onSuccess={onSuccessContract}
          code="API_RES_SUCCESS"
          // titleModal={t('editContract.successMsg')}
          titleModal="Edições realizadas com sucesso"
          actionModal={callback}
          control
          existIds={existIds}
          orig={orig}
          edit
        />
      )}
    </>
  );
}

export const getServerSideProps = async context => {
  const id = context.query.contractId;
  try {
    // const {
    //   data: { company, deliveryAddress, productDetails, revenue },
    // } = await privateFetch(context).get(`/contracts/${id}`);
    const cancel = new AbortController();
    // const {
    //   data: { company, deliveryAddress, productDetails, revenue },
    // } = await api.contract.getContract(cancel, id);

    return {
      props: {
        id,
        contract: {
          company,
          deliveryAddress,
          productDetails,
          revenue,
        },
      },
    };
  } catch (err) {
    // eslint-disable-next-line no-unused-vars
    const error = err;

    return {
      notFound: true,
    };
  }
};

export default AddCompanie;
