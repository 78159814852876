/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useRef, useContext, useState } from 'react';
// import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MyInput from '../../components/input/MyInput';
import Button from '../../components/button/MyButton';

import routing from '../../service/routes/routing';
import api from '../../service/axios/api';

import FlashContext from '../../components/contexts/FlashContext';
import Loading from '../../components/loading/Loading';
import '../login/login.css';

const ForgotPassword = function () {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const email = useRef(null);

  const { notifyError, notifySuccess } = useContext(FlashContext);
  // const handleNext = (e) => {
  //   // setSteep(steep + 1);
  //   console.log(e);
  // };

  const handleLogin = () => {
    setLoading(true);
    api.user
      .resetPassword(email.current.value)
      .then(() => {
        notifySuccess('Redefinição de senha envida por email.');
        setLoading(false);
        history.push(routing.login);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        notifyError(
          'Informe o email cadastrado no sistema para enviar a redefinição de senha',
        );
      });
  };
  return (
    <>
      <Loading open={loading} msg="Resetando a senha..." />
      <div className="base">
        <div className="lateral">
          <img src="img/controlEcosystemnova3.png" alt="logo" className="logo" />
          <div className="textPosition">
            <h5 className="textWelcome">{t('ForgotPassword.recover')}</h5>
          </div>
        </div>
        <div className="baseLogin">
          <div className="login1">
            <h4 className="textLogin">{t('ForgotPassword.label1')}</h4>
            <MyInput myRef={email} icon="user" label="E-mail" />

            <Button onClick={handleLogin} label={t('ForgotPassword.send')} />
          </div>
          <footer
            style={{
              textAlign: 'center',
              position: 'absolute',
              bottom: 10,
              height: '2.5rem',
              width: '100%',
              // marginTop: '100vh -20px',
              // left: 0,
            }}
          >
            <div>
              <h6 style={{ margin: '0px' }}>
                Todos direitos reservados para Promotech
              </h6>
              <a
                style={{ marginBottom: '20px' }}
                href="https://elementcontrol.azurewebsites.net"
                target="_blank"
                rel="noreferrer"
              >
                DESENVOLVEDORES
              </a>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
