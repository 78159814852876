/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, TextField, Grid, Chip } from '@material-ui/core';
import Loading from '../../components/loading/Loading';
import ContractsTable from './ContractsTable';
import api from '../../service/axios/api';

const customStyles = {
  control: provided => ({
    ...provided,
    width: 105,
    // width: 982,
    height: 40,
    borderRadius: 10,
    // backgroundColor: 'none',
    // boxShadow: 'none',
    // border: 'none',
    // fontWeight: 'bold',
    // fontFamily: 'Open Sans',
    // fontSize: 14,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
    // fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  // placeholder: () => ({
  //   // fontWeight: 'bold',
  //   // fontFamily: 'Open Sans',
  //   // fontSize: 24,
  //   color: '#9C9C9C',
  //   // paddingT: 10,
  //   // marginBottom: 30,
  // }),
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 10,
    margin: 0,
  },
  chip: {
    margin: 10,
  },
  textField: {
    // width: '90%',
    // // height: '40px',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // paddingTop: '2px',
    // paddingBottom: 0,
    // marginTop: 0,
    // fontWeight: 500,
    // backgroundColor: '#fff',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
    // color: 'white',
  },
});

export default function Contract() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [chipData, setChipData] = useState([]);
  const [searchType, setSearchType] = useState();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [statusSelected, setStatusSelected] = useState({
    label: 'Todos',
    value: '',
  });
  const [productSelected, setProductSelected] = useState({
    label: 'Todos',
    value: '',
  });
  const searchRef = useRef(null);
  const [options, setOptions] = useState([
    { label: 'Grupo', value: 'groupName' },
    { label: 'CNPJ', value: 'cnpj' },
    { label: 'Razão Social', value: 'billed' },
  ]);

  const [page, setPage] = useState(0);
  const [itens, setItens] = useState(10);

  const search = extra => {
    setLoading(true);
    const cancel = new AbortController();
    api.contract
      .get(
        productSelected.value,
        statusSelected.value,
        extra,
        itens,
        page + 1,
        cancel,
        chipData,
      )
      .then(res => {
        const list = [];
        res.docs.forEach(r => {
          list.push({
            id: r?.id,
            group: r?.Contract?.group_name,
            cnpj: r?.LegalEntity?.cnpj,
            rz: r?.LegalEntity?.company_name,
            product: r?.Contract?.product_type,
            status: r?.Contract?.is_active,
            contractId: r?.Contract?.id,
          });
        });
        setTotal(res.total);
        setData(list);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    // setSearchType();
    // setChipData([]);
    // setData([]);
    search();
  }, [itens, page, statusSelected, productSelected]);

  const internSearch = () => {
    if (searchType?.value !== '' && searchRef?.current?.value !== '') {
      addChip(true);
    } else {
      search();
    }
  };

  const teclaEnter = e => {
    if (e.key === 'Enter') {
      internSearch();
    }
  };

  const handleDelete = chipToDelete => () => {
    setOptions([
      ...options,
      { label: chipToDelete.by, value: chipToDelete.byType },
    ]);
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  const addChip = btn => {
    if (searchType) {
      const orig = _.cloneDeep(options);
      setChipData([
        ...chipData,
        {
          key: chipData.length + 1,
          label: searchRef.current.value,
          by: searchType.label,
          byType: searchType.value,
        },
      ]);
      const find = orig.findIndex(e => e.value === searchType.value);

      orig.splice(find, 1);
      setSearchType(null);
      setOptions(orig);
      if (btn) {
        search({
          label: searchRef.current.value,
          byType: searchType.value,
        });
      }
      searchRef.current.value = '';
    } else {
      // notifyError('Informe o tipo de busca');
    }
  };
  return (
    <>
      <Loading open={loading} msg="Buscando contratos..." />
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h1>Contratos</h1>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={0} style={{ justifyContent: 'start' }}>
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {/* <Box flexGrow={1}> */}
                <label>Produto:</label>
                {/* </Box> */}
                <Box ml={1} mt={1}>
                  <Select
                    // fullWidth
                    styles={customStyles}
                    classNamePrefix="select"
                    placeholder="Produto"
                    isSearchable={false}
                    options={[
                      { label: 'Cartão', value: 'Card' },
                      { label: 'Transferência', value: 'Transfer' },
                      { label: 'Todos', value: '' },
                    ]}
                    onChange={setProductSelected}
                    value={productSelected}
                    menuPortalTarget={document.body}
                    style={{ zIndex: 999 }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} style={{ marginLeft: 15 }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {/* <Box flexGrow={1}> */}
                <label>Filtrar por status:</label>
                {/* </Box> */}
                <Box ml={1} mt={1}>
                  <Select
                    // fullWidth
                    styles={customStyles}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    isSearchable={false}
                    options={[
                      { label: 'Ativo', value: true },
                      { label: 'Desativado', value: false },
                      { label: 'Todos', value: '' },
                    ]}
                    onChange={setStatusSelected}
                    value={statusSelected}
                    menuPortalTarget={document.body}
                  // style={{ zIndex: 999, width: 600 }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Grid container spacing={1} style={{ justifyContent: 'end' }}>
            <Grid item xs={12} sm={3}>
              <Select
                fullWidth
                styles={customStyles}
                onChange={setSearchType}
                value={searchType}
                classNamePrefix="select"
                placeholder="Tipo"
                isSearchable={false}
                options={options}
                menuPortalTarget={document.body}
                style={{ zIndex: 999 }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                onKeyUpCapture={e => teclaEnter(e)}
                inputRef={searchRef}
                color="secondary"
                // margin="none"
                className={classes.textField}
                fullWidth
                size="small"
                style={{ maxHeight: 10 }}
                // label="Outlined"
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
            <Button
              onClick={() => addChip(false)}
              variant="contained"
              size="small"
              style={{ marginLeft: 5, marginRight: 5 }}
            >
              <AddIcon />
            </Button>
            {/* <Grid item xs={12} sm={2}> */}
            <Button onClick={() => internSearch(true)} variant="contained">
              Buscar
            </Button>
            {/* </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid item className={classes.root}>
            {chipData.map(dt => {
              let icon;

              return (
                <li key={data.key}>
                  <Chip
                    icon={icon}
                    label={`${dt.by} | ${dt.label}`}
                    onDelete={handleDelete(dt)}
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <ContractsTable
            docs={data}
            setDocs={setData}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
            total={total}
          />
        </Grid>
      </Grid>
    </>
  );
}
