/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef, useContext } from 'react';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import LockIcon from '@material-ui/icons/Lock';
import Chip from '@material-ui/core/Chip';
import locale from 'date-fns/locale/pt-BR';
import CardsTable from './CardsTable';
import api from '../../service/axios/api';
import Loading from '../../components/loading/Loading';
import FlashContext from '../../components/contexts/FlashContext';

const optionsStatus = [
  {
    label: 'Ativo',
    value: 'Active',
  },
  {
    label: 'Aguardando Link',
    value: 'AwaitingIssuerLink',
  },
  {
    label: 'Aguardando finalizar',
    value: 'AwaitingRelease',
  },
  {
    label: 'Aguardando pagamento',
    value: 'AwaitingPayment',
  },
  {
    label: 'Aguardando Status do Cartão',
    value: 'AwaitingRelease',
  },
  {
    label: 'Estoque',
    value: 'InStock',
  },
  {
    label: 'Fraudado',
    value: 'Fraud',
  },
  {
    label: 'Cancelado',
    value: 'Canceled',
  },
  {
    label: 'Todos',
    value: '',
  },
];

const optionsType = [
  {
    label: 'Saque/Compra',
    value: true,
  },
  {
    label: 'Compra',
    value: false,
  },
];

const optionsFlagCad = [
  {
    label: 'MasterCard',
    value: 'mastercard',
  },
  {
    label: 'Visa',
    value: 'visa',
  },
  {
    label: 'Cabal',
    value: 'cabal',
  },
];
const optionsFlag = [
  {
    label: 'MasterCard',
    value: 'mastercard',
  },
  {
    label: 'Visa',
    value: 'visa',
  },
  {
    label: 'Cabal',
    value: 'cabal',
  },
  {
    label: 'Todos',
    value: '',
  },
];

const customStyles = {
  control: provided => ({
    ...provided,
    width: 105,
    // width: 982,
    height: 40,
    borderRadius: 10,
    // backgroundColor: 'none',
    // boxShadow: 'none',
    // border: 'none',
    // fontWeight: 'bold',
    // fontFamily: 'Open Sans',
    // fontSize: 14,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: provided => ({
    ...provided,
    // fontFamily: 'Open Sans',
    // fontSize: 24,
  }),
  // placeholder: () => ({
  //   // fontWeight: 'bold',
  //   // fontFamily: 'Open Sans',
  //   // fontSize: 24,
  //   color: '#9C9C9C',
  //   // paddingT: 10,
  //   // marginBottom: 30,
  // }),
};
const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 10,
    margin: 0,
  },
  chip: {
    margin: 10,
  },
  textField: {
    // width: '90%',
    // // height: '40px',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // paddingTop: '2px',
    // paddingBottom: 0,
    // marginTop: 0,
    // fontWeight: 500,
    // backgroundColor: '#fff',
  },
  input: {
    backgroundColor: '#fff',
    marginTop: 0,
    // color: 'white',
  },
});

// const res = {
//   code: 'API_RES_PAGINATEDLIST',
//   message: 'Lista paginada de itens de pedidos do sistema',
//   pages: 9292,
//   total: 232280,
//   docs: [
//     {
//       status: 'Active',
//       proxy: '8034302005779296',
//       card_number: '5290530205846466',
//       brand: 'mastercard',
//       created_at: '2020-05-06T14:06:58.000Z',
//       issuer: 'acesso',
//       Individual: {
//         cpf: '07675718860',
//         name: 'LUIZ ANTONIO ALVES MOREIRA',
//         mothers_name: 'IRACEMA CORAÇARI MOREIRA',
//         birthdate: '1969-12-31',
//       },
//       updated_at: '2022-01-24T19:27:36.000Z',
//       deleted_at: null,
//       date_processing: null,
//       date_conciliation: null,
//     },
//   ],
// };
const customStylesEdit = {
  control: provided => ({
    ...provided,
    // width: 105,
    height: 40,
    borderRadius: 10,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: provided => ({
    ...provided,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
};

export default function CardsAdmin() {
  const local = useLocation();
  const classes = useStyles();
  const [openUploadCards, setOpenUploadCards] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [itens, setItens] = useState(10);
  const [loading, setLoading] = useState(false);
  const [loadingC, setLoadingC] = useState(false);
  const [statusSelected, setStatusSelected] = useState({
    label: 'Todos',
    value: '',
  });
  const [flagSelected, setFlagSelected] = useState({
    label: 'Todos',
    value: '',
  });
  const [options, setOptions] = useState([
    {
      label: 'Nome',
      value: 'name',
    },
    {
      label: 'CPF',
      value: 'cpf',
    },
    {
      label: 'PROXY',
      value: 'proxy',
    },
    {
      label: 'CARTÃO',
      value: 'cardNumber',
    },
    {
      label: 'FATURADO',
      value: 'billed',
    },
    {
      label: 'VENCIMENTO',
      value: 'validity',
    },
  ]);
  const [searchType, setSearchType] = useState();
  const [msg, setMsg] = useState('Buscando cartões...');
  const [type, setType] = useState({ label: 'Todos', value: '' });
  const [chipData, setChipData] = useState([]);
  const searchRef = useRef(null);

  const { notifyError, notifySuccess } = useContext(FlashContext);

  // PLANILHA
  const [typeSelected, setTypeSelected] = useState();
  const [cardsFlagSelected, setCardsFlagTypeSelected] = useState();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [myFile, setMyfile] = useState();
  const emissor = useRef(null);
  const fileXls = useRef(null);

  const [value, setValue] = useState(
    local.search.substring(local.search.indexOf('?') + 1, 3) || 'todos',
  );

  const handleChange = event => {
    setValue(event.target.value);
  };

  const searchData = extra => {
    const cancel = new AbortController();
    setLoading(true);
    setMsg('Buscando cartões...');
    api.product
      .getAllCardAdmin(
        extra,
        itens,
        page + 1,
        statusSelected.value,
        flagSelected.value,
        chipData,
        cancel,
        value,
      )
      .then(res => {
        console.log(res)
        setTotal(res.total);
        setData(res.docs);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    searchData();
  }, [page, itens, statusSelected, flagSelected, value]);

  const uploadCards = e => {
    e.preventDefault();
    setLoadingC(true);
    api.cards
      .uploadBaseCards(
        myFile,
        cardsFlagSelected.value,
        emissor.current.value,
        typeSelected.value,
        selectedDate,
      )
      .then(res => {
        notifySuccess(res.message);
        setOpenUploadCards(false);
      })
      .catch(err => {
        notifyError(err.message);
      })
      .finally(() => setLoadingC(false));
  };

  const handleDelete = chipToDelete => () => {
    setOptions([
      ...options,
      { label: chipToDelete.by, value: chipToDelete.byType },
    ]);
    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  const addChip = btn => {
    if (searchType) {
      if (searchType.value === 'validity') {
        const orig = _.cloneDeep(options);
        setChipData([
          ...chipData,
          {
            key: chipData.length + 1,
            label: moment(selectedDate).format('MM/YYYY'),
            by: searchType.label,
            byType: searchType.value,
            date: selectedDate,
          },
        ]);
        const myfind = orig.findIndex(e => e.value === searchType.value);
        orig.splice(myfind, 1);
        setSearchType(null);
        setOptions(orig);
        if (btn) {
          // find({
          //   label: selectedDate,
          //   byType: searchType.value,
          //   date: selectedDate,
          // });
          // searchData({
          //   label: selectedDate,
          //   byType: searchType.value,
          //   date: selectedDate,
          // });
          searchData({
            key: chipData.length + 1,
            label: moment(selectedDate).format('DD/MM/YYYY'),
            by: searchType.label,
            byType: searchType.value,
            date: selectedDate,
          });
        }
      } else {
        const orig = _.cloneDeep(options);
        setChipData([
          ...chipData,
          {
            key: chipData.length + 1,
            label: searchRef.current.value,
            by: searchType.label,
            byType: searchType.value,
          },
        ]);
        const find = orig.findIndex(e => e.value === searchType.value);

        orig.splice(find, 1);
        setSearchType(null);
        // selectRef.current.select.clearValue();
        setOptions(orig);
        if (btn) {
          searchData({
            label: searchRef.current.value,
            byType: searchType.value,
          });
        }
        searchRef.current.value = '';
      }
    } else {
      notifyError('Informe o tipo de busca');
    }
  };

  const internSearch = () => {
    if (searchType?.value !== '' && searchRef?.current?.value !== '') {
      addChip(true);
    } else {
      searchData();
    }
  };

  const teclaEnter = e => {
    if (e.key === 'Enter') {
      internSearch();
    }
  };

  const downloadBase = () => {
    const cancel = new AbortController();
    setMsg('Download da base....');
    setLoading(true);
    api.cards
      .baseAll(cancel)
      .then(res => {
        const url = res.retorno.link;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'base_cards.xlsx'); // or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loading open={loading} msg={msg} />
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <h1>Cartões - TODA BASE</h1>
          <Button
            onClick={downloadBase}
            color="primary"
            variant="contained"
            size="small"
            style={{ height: '40px' }}
          >
            Download BASE
          </Button>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Grid container spacing={0} style={{ justifyContent: 'start' }}>
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <label>Filtrar por bandeira:</label>
                </Box>
                <Box ml={1} mt={1}>
                  <Select
                    // fullWidth
                    styles={customStyles}
                    classNamePrefix="select"
                    placeholder="Bandeira"
                    isSearchable={false}
                    options={optionsFlag}
                    onChange={setFlagSelected}
                    menuPortalTarget={document.body}
                    style={{ zIndex: 999 }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} style={{ marginLeft: 15 }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <label>Filtrar por status:</label>
                </Box>
                <Box ml={1} mt={1}>
                  <Select
                    // fullWidth
                    styles={customStyles}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    isSearchable={false}
                    options={optionsStatus}
                    onChange={setStatusSelected}
                    menuPortalTarget={document.body}
                    // style={{ zIndex: 999, width: 600 }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Grid container spacing={1} style={{ justifyContent: 'end' }}>
            <Grid item xs={12} sm={3}>
              <Select
                fullWidth
                styles={customStyles}
                onChange={setSearchType}
                value={searchType}
                classNamePrefix="select"
                placeholder="Tipo"
                isSearchable={false}
                options={options}
                menuPortalTarget={document.body}
                style={{ zIndex: 999 }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              {searchType?.value === 'validity' ? (
                <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    views={['year', 'month']}
                    variant="inline"
                    inputVariant="outlined"
                    style={{ backgroundColor: '#fff' }}
                    // label="With keyboard"
                    size="small"
                    format="MM/yyyy"
                    value={selectedDate}
                    InputProps={{ readOnly: true }}
                    InputAdornmentProps={{ position: 'start' }}
                    onChange={date => handleDateChange(date)}
                  />
                </MuiPickersUtilsProvider>
              ) : (
                <TextField
                  onKeyUpCapture={e => teclaEnter(e)}
                  inputRef={searchRef}
                  color="secondary"
                  // margin="none"
                  className={classes.textField}
                  fullWidth
                  size="small"
                  style={{ maxHeight: 10 }}
                  // label="Outlined"
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            </Grid>
            <Button
              onClick={() => addChip(false)}
              variant="contained"
              size="small"
              style={{ marginLeft: 5, marginRight: 5 }}
            >
              <AddIcon />
            </Button>
            {/* <Grid item xs={12} sm={2}> */}
            <Button onClick={() => internSearch(true)} variant="contained">
              Buscar
            </Button>
            {/* </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <div>
            <p className="text-select-label ">Validade cartões:</p>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  // className="myLabel"
                  value="vencidos"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label="Vencidos"
                />
                <FormControlLabel
                  // className="myLabel"
                  value="30"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label="Em 30 dias"
                />
                <FormControlLabel
                  // className="myLabel"
                  value="60"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label="Em 60 dias"
                />
                <FormControlLabel
                  // className="myLabel"
                  value="90"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label="Em 90 dias"
                />
                <FormControlLabel
                  // className="myLabel"
                  value="todos"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked,
                      }}
                    />
                  }
                  label="Todos"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid item className={classes.root}>
            {chipData.map(dt => {
              let icon;

              return (
                <li key={data.key}>
                  <Chip
                    icon={icon}
                    label={`${dt.by} | ${dt.label}`}
                    onDelete={handleDelete(dt)}
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <CardsTable
            docs={data}
            setDocs={setData}
            page={page}
            setPage={setPage}
            itens={itens}
            setItens={setItens}
            searchData={searchData}
            total={total}
          />
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setOpenUploadCards(!openUploadCards)}
        open={openUploadCards}
        fullWidth
        maxWidth="sm"
      >
        <Loading open={loadingC} msg="Cadastrando cartões..." />
        <DialogTitle id="simple-dialog-title">
          Upload de novos cartões
        </DialogTitle>
        <form onSubmit={uploadCards}>
          <Grid
            container
            spacing={1}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              padding: 20,
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={10}>
                      <h6>Planilha</h6>
                      <TextField
                        value={myFile?.name}
                        disabled
                        size="small"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs="auto" style={{ marginTop: 35 }}>
                      <img
                        onClick={() => fileXls.current.click()}
                        style={{ cursor: 'pointer' }}
                        id="planilha"
                        src="../img/upload.svg"
                        alt="logo"
                        className="img-planilhas"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <h6>Emissor</h6>
                    <TextField
                      inputRef={emissor}
                      size="small"
                      variant="outlined"
                      fullWidth
                      // defaultValue={dataEdit && dataEdit.cpf}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h6>Validade</h6>
                    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        size="small"
                        format="yyyy-MM-dd"
                        fullWidth
                        value={selectedDate}
                        onChange={handleDateChange}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                style={{
                  paddingRight: 20,
                  paddingLeft: 20,
                }}
              >
                <Grid item xs={12} sm={6}>
                  <h6>Tipo</h6>
                  <Select
                    fullWidth
                    styles={customStylesEdit}
                    classNamePrefix="select"
                    placeholder="Tipo do cartão"
                    value={typeSelected}
                    onChange={setTypeSelected}
                    isSearchable={false}
                    options={optionsType}
                    menuPortalTarget={document.body}
                  />
                </Grid>
                <Grid item xs={12} sm={6} disabled>
                  <h6>Bandeira</h6>
                  <Select
                    fullWidth
                    // isDisabled
                    styles={customStylesEdit}
                    value={cardsFlagSelected}
                    onChange={setCardsFlagTypeSelected}
                    classNamePrefix="select"
                    placeholder="Tipo"
                    isSearchable={false}
                    options={optionsFlagCad}
                    menuPortalTarget={document.body}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <DialogActions>
            <h6
              style={{
                cursor: 'pointer',
                color: '#657588',
                fontWeight: 'bold',
                fontFamily: 'Open Sans',
                marginTop: 5,
              }}
              onClick={() => setOpenUploadCards(false)}
            >
              Cancelar
            </h6>
            <Button
              style={{ height: 40 }}
              type="submit"
              color="primary"
              variant="contained"
            >
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <input
        ref={fileXls}
        type="file"
        style={{
          opacity: 0,
        }}
        // accept="media_type"
        onChange={() => setMyfile(fileXls.current.files[0])}
        className="form-control"
        id="inputNumSerie"
        placeholder="Arquivo DWG do projeto"
      />
    </>
  );
}
