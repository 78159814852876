/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

// import { NextPageContext } from 'next';

import { Grid, makeStyles, Typography } from '@material-ui/core';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from '../../../../styles/Layout.module.css';
import api from '../../../../service/axios/api';
import ContractFormViewer from '../../components/forms/ContractFormViewer';
import company from '../../components/forms/defaultStates/company';
import { productDetails } from '../../components/forms/defaultStates/productDetails';
import SpinnerPage from '../../../../components/loading/Loading';
import routing from '../../../../service/routes/routing';
// import { privateFetch } from '@/util/fetch';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  selectField: {
    minWidth: '200px',
  },
}));

const revenue = {
  calculation: 'normal',
  currency: 'brl',
  paymentMethod: 'transfer',
  isInvoiceTopup: false,
  issuingCompanyId: 1,
  bankAccountId: 1,
  serviceCodeId: 8,
  signatureDate: new Date(),
  expirationDate: new Date(),
  isSigned: false,
};
const deliveryAddress = {
  postalCode: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  city: '',
  state: '',
  receiver: '',
  deliveryAgent: 'logistics',
  isCentralizedDelivery: true,
  deliveryService: 'doc+',
};

const defaultContract = {
  id: '0',
  isActive: true,
  pdfKey: '',
  company: {
    ...company,
    contacts: [
      {
        name: '',
        cpf: '',
        role: '',
        phone: '',
        mobile: '',
        email: '',
        department: '',
      },
    ],
  },
  productDetails,
  deliveryAddress,
  revenue,
};

const cleanCEP = dt => {
  const res = dt.replace('-', '');
  const final = res.replaceAll('.', '');
  return final.trim();
};

function ViewerContract({ idd, contract }) {
  const { t } = useTranslation();
  const router = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const [myContract, setMycontract] = useState();
  const [agent, setAgent] = useState();
  const [agentComission, setAgentComission] = useState();

  const formatValue = vl => {
    if (vl) {
      const i = vl.replaceAll('.', ',');
      return `R$ ${i}`;
    }
    return 'R$ 0,00';
  };

  useEffect(() => {
    if (id) {
      const cancel = new AbortController();
      api.contract
        .getContract(cancel, id)
        .then(res => {
          const listContacts = [];
          res.docs?.Contract?.ContractUser?.forEach(c => {
            listContacts.push({
              id: c.User?.id,
              name: c.User?.name,
              cpf: c.User?.cpf,
              role: c.User?.role_id,
              phone: c.User?.phone,
              mobile: c.User?.mobile,
              email: c.User?.email,
              department: c.User?.department,
            });
          });
          if (res.docs?.Contract?.ContractCommercialAgent[0]?.CommercialAgent) {
            setAgent({
              label:
                res.docs?.Contract?.ContractCommercialAgent[0]?.CommercialAgent
                  .company_name,
              value:
                res.docs?.Contract?.ContractCommercialAgent[0]?.CommercialAgent
                  .id,
            });
            setAgentComission(
              res.docs?.Contract?.ContractCommercialAgent[0]?.agent_percentage,
            );
          }
          setMycontract({
            ...defaultContract,
            id,
            company: {
              ...company,
              cnpj: res.docs.LegalEntity.cnpj,
              companyName: res.docs.LegalEntity.company_name,
              tradingName: res.docs.LegalEntity.trading_name,
              email: res.docs.email,
              phone: res.docs.phone,
              mobile: res.docs.mobile,
              website: res.docs.LegalEntity.website,
              comments: res.docs.LegalEntity.comments,
              groupName: res.docs?.Contract?.group_name,
              FourSign: res.docs?.Contract?.doc4sign_file,
              contacts: listContacts,
            },
            companyAddress: {
              // id: res.docs.Contract?.ContractDeliveryAddress?.[0]?.id,
              postalCode:
                res.docs?.postal_code,
              street:
                res.docs?.street_name,
              number: res.docs?.number,
              complement:
                res.docs?.complement,
              district:
                res.docs?.district,
              city: res.docs?.city,
              state: res.docs?.state,
              receiver:
                res.docs.Contract?.ContractDeliveryAddress?.[0]?.recipient,
              deliveryAgent: res.docs.Contract?.delivery_agent,
              isCentralizedDelivery: res.docs.Contract?.is_centralized_delivery,
              deliveryService: res.docs.Contract?.delivery_service,
            },
            deliveryAddress: {
              id: res.docs.Contract?.ContractDeliveryAddress?.[0]?.id,
              postalCode:
                res.docs.Contract?.ContractDeliveryAddress?.[0]?.postal_code,
              street:
                res.docs.Contract?.ContractDeliveryAddress?.[0]?.street_name,
              number: res.docs.Contract?.ContractDeliveryAddress?.[0]?.number,
              complement:
                res.docs.Contract?.ContractDeliveryAddress?.[0]?.complement,
              district:
                res.docs.Contract?.ContractDeliveryAddress?.[0]?.district,
              city: res.docs.Contract?.ContractDeliveryAddress?.[0]?.city,
              state: res.docs.Contract?.ContractDeliveryAddress?.[0]?.state,
              receiver:
                res.docs.Contract?.ContractDeliveryAddress?.[0]?.recipient,
              deliveryAgent: res.docs.Contract?.delivery_agent,
              isCentralizedDelivery: res.docs.Contract?.is_centralized_delivery,
              deliveryService: res.docs.Contract?.delivery_service,
              otherAddress: {
                id: res.docs.Contract?.ContractDeliveryAddress?.[0]?.id,
                postalCode:
                  res.docs.Contract?.ContractDeliveryAddress?.[0]?.postal_code,
                street:
                  res.docs.Contract?.ContractDeliveryAddress?.[0]?.street_name,
                number: res.docs.Contract?.ContractDeliveryAddress?.[0]?.number,
                complement:
                  res.docs.Contract?.ContractDeliveryAddress?.[0]?.complement,
                district:
                  res.docs.Contract?.ContractDeliveryAddress?.[0]?.district,
                city: res.docs.Contract?.ContractDeliveryAddress?.[0]?.city,
                state: res.docs.Contract?.ContractDeliveryAddress?.[0]?.state,
                receiver:
                  res.docs.Contract?.ContractDeliveryAddress?.[0]?.recipient,
                deliveryAgent: res.docs.Contract?.delivery_agent,
                isCentralizedDelivery:
                  res.docs.Contract?.is_centralized_delivery,
                deliveryService: res.docs.Contract?.delivery_service,
              },
            },
            productDetails: {
              typeCard: res.docs.Contract?.card_type ? res.docs.Contract?.card_type : 1,
              commission_calculation: res.docs.Contract?.commission_calculation,
              operatorFees: {
                // For virtual_card and transfer
                isChanged: true,
                emitionTicket: 'R$ 3,50',
                pixPf: 'R$ 6,00',
                pixPj: 'R$ 6,00',
                lotteryDeposit: 'R$ 0,00',
                lotteryWithdrawal: 'R$ 0,00',
                PDV: '0,00',
                aditionalCard: 'R$ 0,00',
                expiredCard: 'R$ 0,00',
                codeQR: 'R$ 8,00',
                TED: 'R$ 9,00',
                SMS: 'R$ 0,00',
                // only for cards
                monthlyFee: formatValue(res.docs.Contract?.monthly_fee), // 'R$ 0,00',
                unlockFee: formatValue(res.docs.Contract?.unlock_fee), // 'R$ 0,00',
                reissueFee: formatValue(res.docs.Contract?.reissue_fee), // 'R$ 0,00',
                chargebackFee: formatValue(res.docs.Contract?.chargeback_fee), // 'R$ 0,00',
                atmWithdrawFee: formatValue(
                  res.docs.Contract?.atm_withdraw_fee,
                ), // 'R$ 0,00',
                markupRate: res.docs.Contract?.markup_rate, // '0,00',
                rechargePortalFee: 'R$ 0,00',
                rechargeInvoiceFee: 'R$ 0,00',
                p2pTransferFee: formatValue(
                  res.docs.Contract?.p2p_transfer_fee,
                ), // 'R$ 0,00',
                // only for transfers
                transferFee: formatValue(res.docs.Contract?.transfer_fee), // 'R$ 0,00',
                rejectedTransactionFee: formatValue(
                  res.docs.Contract?.rejected_transaction_fee,
                ), // 'R$ 0,00',
                // only for checks
                checkFee: formatValue(res.docs.Contract?.check_fee), // 'R$ 0,00',
                // only for vouchers
                voucherFee: formatValue(res.docs.Contract?.voucher_fee), // 'R$ 0,00',
                // VIRTUAL
                virtualMonthlyFee: formatValue(res.docs.Contract?.virtual_monthly_fee),
                virtualUnlockFee: formatValue(res.docs.Contract?.virtual_unlock_fee),
                virtualReissueFee: formatValue(res.docs.Contract?.virtual_reissue_fee),
                virtualChargebackFee: formatValue(res.docs.Contract?.virtual_chargeback_fee),
                virtualAtmWithdrawFee: formatValue(res.docs.Contract?.virtual_atm_withdraw_fee),
                virtualMarkupRate: res.docs.Contract?.virtual_markup_rate,
              },
              cardFees: {
                physicalCard: formatValue(res.docs.Contract?.issue_fee), // 'R$ 8,50',
                sendCard: 'R$ 0,00',
                cardWithdrawal: 'R$ 0,00',
              },
              campaign: {
                name: '',
                rechargeFrequency:
                  res.docs.Contract?.readjustment_period?.toString(),
                annualEstimatedAward: formatValue(
                  res.docs.Contract?.annual_estimated_award,
                ), // 'R$ 0,00',
                totalCommissionRate: formatValue(
                  res.docs.Contract?.total_commission_rate,
                ),
                isAdministrationRate: res.docs.Contract?.is_administration_rate,
                // only when is_administration_rate is true
                administrationRate: res.docs.Contract?.administration_rate,
                // '5.00',
                // only when is_administration_rate is false
                administrationFee: formatValue(
                  res.docs.Contract?.administration_fee,
                ), // 'R$ 0,00',
                // only when product category is a card
                issueFee: formatValue(res.docs.Contract?.issue_fee), // 'R$ 0,00',
              },
              product:
                res.docs.Contract?.product_type === 'Card'
                  ? 'prepaid_card'
                  : 'transfer',
              productBrand: res.docs.Contract?.brand, // 'mastercard',
              commercialAgent: {
                id: 'CsFaIoewgVLlz5mZOgSI',
                company: {
                  admin: {
                    companyArea: 'venda',
                    companyRole: 'vendedor',
                    cpf: '11111111111',
                    fullName: 'John Doe',
                    email: 'churi410@gmail.com',
                    mobile: '5804269900709',
                    phone: '5804269900709',
                  },
                  cnpj: '11111111111111',
                  comments: '',
                  email: 'churi410@gmail.com',
                  legalName: ' Element Marketing e Participações',
                  tradingName: ' Element Marketing e Participações',
                  mobile: '5804269900709',
                  phone: '5804269900709',
                  website: 'www.churi.com.br',
                },
                salesCommissionId: 'ga3ze2XuOSX7FsD9T80e',
              },
              // only for cards
              companyOperatingFees: {
                isChanged: true,
                KYC: 'R$ 0,00',
                balanceTransferFee: formatValue(
                  res.docs.Contract?.balance_transfer_fee,
                ), // 'R$ 0,00',
                minimumLoadAmount: formatValue(
                  res.docs.Contract?.minimum_load_amount,
                ), // 'R$ 0,00',
                belowMinimumLoadFee: formatValue(
                  res.docs.Contract?.below_minimum_load_fee,
                ), // 'R$ 0,00',
                emergencyLoadFee: formatValue(
                  res.docs.Contract?.emergency_load_fee,
                ), // 'R$ 0,00',
                specialHandlingFee: formatValue(
                  res.docs.Contract?.special_handling_fee,
                ), // 'R$ 0,00',
                chargebackRate: res.docs.Contract?.chargeback_rate, // '0,00',
                rejectedTransactionFee: formatValue(
                  res.docs.Contract?.rejected_transaction_fee,
                ), // 'R$ 0,00',
                ir_fee: formatValue(res.docs.Contract?.ir_fee), // 'R$ 0,00',

                // VIRTUAL
                virtualIrFee: formatValue(res.docs.Contract?.ir_fee),  
                virtualBalanceTransferFee: formatValue(res.docs.Contract?.virtual_balance_transfer_fee),
                virtualMinimumLoadAmount: formatValue(res.docs.Contract?.virtual_minimum_load_amount),
                virtualBelowMinimumLoadFee: formatValue(res.docs.Contract?.virtual_below_minimum_load_fee),
                virtualEmergencyLoadFee: formatValue(res.docs.Contract?.virtual_emergency_load_fee),
                virtualSpecialHandlingFee: formatValue(res.docs.Contract?.virtual_special_handling_fee),
                virtualChargebackRate: res.docs.Contract?.virtual_chargeback_rate,
                virtualIssueFee: formatValue(res.docs.Contract?.virtual_issue_fee),
              },
              salesChannel: res.docs.Contract?.sales_channel, // 'direct',
              classAccount: 'basic',
              isGiftCard: !res.docs.Contract?.allow_withdraw,
              // typeCard: !res.docs.Contract?.typeCard,
              // isVirtualCard: !res.docs.Contract?.is_virtual_card,
              kindAccount: 'virtual_card',
              isInternationalCard: res.docs.Contract?.is_international_card, // true,
              cardQuantity: 1,
              isChipCard: res.docs.Contract?.is_chip_card, // true,
              issuer: res.docs.Contract?.issuer, // 'acesso',
            },
            revenue: {
              calculation: res.docs.Contract.is_normal_calculation
                ? 'normal'
                : 'inverted',
              currency: 'brl',
              paymentMethod: res.docs.Contract.is_transfer_payment
                ? 'transfer'
                : 'ticket',
              isInvoiceTopup: res.docs.Contract.is_invoice_topup,
              issuingCompanyId: 1,
              bankAccountId: Number(res.docs.Contract?.bank_account_id),
              serviceCodeId: 8,
              signatureDate: new Date(),
              expirationDate: new Date(),
              isSigned: false,
            },
          });
          // setMycontract({
          //   ...defaultContract,
          //   id,
          //   company: {
          //     ...company,
          //     cnpj: res.docs.LegalEntity.cnpj,
          //     companyName: res.docs.LegalEntity.company_name,
          //     tradingName: res.docs.LegalEntity.trading_name,
          //     email: res.docs.email,
          //     phone: res.docs.phone,
          //     mobile: res.docs.mobile,
          //     website: res.docs.LegalEntity.website,
          //     comments: res.docs.LegalEntity.comments,
          //     contacts: listContacts,
          //   },
          //   deliveryAddress: {
          //     id: res.docs.Contract?.ContractDeliveryAddress?.[0]?.id,
          //     postalCode: res.docs.postal_code,
          //     street: res.docs.street_name,
          //     number: res.docs.number,
          //     complement: res.docs.complement,
          //     district: res.docs.district,
          //     city: res.docs.city,
          //     state: res.docs.state,
          //     receiver:
          //       res.docs.Contract?.ContractDeliveryAddress?.[0]?.recipient,
          //     deliveryAgent: res.docs.Contract?.delivery_agent,
          //     isCentralizedDelivery: res.docs.Contract?.is_centralized_delivery,
          //     deliveryService: res.docs.Contract?.delivery_service,
          //     otherAddress: {
          //       id: res.docs.Contract?.ContractDeliveryAddress?.[0]?.id,
          //       postalCode:
          //         res.docs.Contract?.ContractDeliveryAddress?.[0]?.postal_code,
          //       street:
          //         res.docs.Contract?.ContractDeliveryAddress?.[0]?.street_name,
          //       number: res.docs.Contract?.ContractDeliveryAddress?.[0]?.number,
          //       complement:
          //         res.docs.Contract?.ContractDeliveryAddress?.[0]?.complement,
          //       district:
          //         res.docs.Contract?.ContractDeliveryAddress?.[0]?.district,
          //       city: res.docs.Contract?.ContractDeliveryAddress?.[0]?.city,
          //       state: res.docs.Contract?.ContractDeliveryAddress?.[0]?.state,
          //       receiver:
          //         res.docs.Contract?.ContractDeliveryAddress?.[0]?.recipient,
          //       deliveryAgent: res.docs.Contract?.delivery_agent,
          //       isCentralizedDelivery:
          //         res.docs.Contract?.is_centralized_delivery,
          //       deliveryService: res.docs.Contract?.delivery_service,
          //     },
          //   },
          //   productDetails: {
          //     operatorFees: {
          //       // For virtual_card and transfer
          //       isChanged: true,
          //       emitionTicket: 'R$ 3,50',
          //       pixPf: 'R$ 6,00',
          //       pixPj: 'R$ 6,00',
          //       lotteryDeposit: 'R$ 0,00',
          //       lotteryWithdrawal: 'R$ 0,00',
          //       PDV: '0,00',
          //       aditionalCard: 'R$ 0,00',
          //       expiredCard: 'R$ 0,00',
          //       codeQR: 'R$ 8,00',
          //       TED: 'R$ 9,00',
          //       SMS: 'R$ 0,00',
          //       // only for cards
          //       monthlyFee: formatValue(res.docs.Contract?.monthly_fee), // 'R$ 0,00',
          //       unlockFee: formatValue(res.docs.Contract?.unlock_fee), // 'R$ 0,00',
          //       reissueFee: formatValue(res.docs.Contract?.reissue_fee), // 'R$ 0,00',
          //       chargebackFee: formatValue(res.docs.Contract?.chargeback_fee), // 'R$ 0,00',
          //       atmWithdrawFee: formatValue(
          //         res.docs.Contract?.atm_withdraw_fee,
          //       ), // 'R$ 0,00',
          //       markupRate: res.docs.Contract?.markup_rate, // '0,00',
          //       rechargePortalFee: 'R$ 0,00',
          //       rechargeInvoiceFee: 'R$ 0,00',
          //       p2pTransferFee: formatValue(
          //         res.docs.Contract?.p2p_transfer_fee,
          //       ), // 'R$ 0,00',
          //       // only for transfers
          //       transferFee: formatValue(res.docs.Contract?.transfer_fee), // 'R$ 0,00',
          //       rejectedTransactionFee: formatValue(
          //         res.docs.Contract?.rejected_transaction_fee,
          //       ), // 'R$ 0,00',
          //       // only for checks
          //       checkFee: formatValue(res.docs.Contract?.check_fee), // 'R$ 0,00',
          //       // only for vouchers
          //       voucherFee: formatValue(res.docs.Contract?.voucher_fee), // 'R$ 0,00',
          //     },
          //     cardFees: {
          //       physicalCard: formatValue(res.docs.Contract?.issue_fee), // 'R$ 8,50',
          //       sendCard: 'R$ 0,00',
          //       cardWithdrawal: 'R$ 0,00',
          //     },
          //     campaign: {
          //       name: '',
          //       rechargeFrequency:
          //         res.docs.Contract?.readjustment_period?.toString(),
          //       annualEstimatedAward: formatValue(
          //         res.docs.Contract?.annual_estimated_award,
          //       ), // 'R$ 0,00',
          //       totalCommissionRate: formatValue(
          //         res.docs.Contract?.total_commission_rate,
          //       ),
          //       isAdministrationRate: res.docs.Contract?.is_administration_rate,
          //       // only when is_administration_rate is true
          //       administrationRate: res.docs.Contract?.administration_rate,
          //       // '5.00',
          //       // only when is_administration_rate is false
          //       administrationFee: formatValue(
          //         res.docs.Contract?.administration_fee,
          //       ), // 'R$ 0,00',
          //       // only when product category is a card
          //       issueFee: formatValue(res.docs.Contract?.issue_fee), // 'R$ 0,00',
          //     },
          //     product:
          //       res.docs.Contract?.product_type === 'Card'
          //         ? 'prepaid_card'
          //         : 'transfer',
          //     productBrand: res.docs.Contract?.brand, // 'mastercard',
          //     commercialAgent: {
          //       id: 'CsFaIoewgVLlz5mZOgSI',
          //       company: {
          //         admin: {
          //           companyArea: 'venda',
          //           companyRole: 'vendedor',
          //           cpf: '11111111111',
          //           fullName: 'John Doe',
          //           email: 'churi410@gmail.com',
          //           mobile: '5804269900709',
          //           phone: '5804269900709',
          //         },
          //         cnpj: '11111111111111',
          //         comments: '',
          //         email: 'churi410@gmail.com',
          //         legalName: ' Element Marketing e Participações',
          //         tradingName: ' Element Marketing e Participações',
          //         mobile: '5804269900709',
          //         phone: '5804269900709',
          //         website: 'www.churi.com.br',
          //       },
          //       salesCommissionId: 'ga3ze2XuOSX7FsD9T80e',
          //     },
          //     // only for cards
          //     companyOperatingFees: {
          //       isChanged: true,
          //       KYC: 'R$ 0,00',
          //       balanceTransferFee: formatValue(
          //         res.docs.Contract?.balance_transfer_fee,
          //       ), // 'R$ 0,00',
          //       minimumLoadAmount: formatValue(
          //         res.docs.Contract?.minimum_load_amount,
          //       ), // 'R$ 0,00',
          //       belowMinimumLoadFee: formatValue(
          //         res.docs.Contract?.below_minimum_load_fee,
          //       ), // 'R$ 0,00',
          //       emergencyLoadFee: formatValue(
          //         res.docs.Contract?.emergency_load_fee,
          //       ), // 'R$ 0,00',
          //       specialHandlingFee: formatValue(
          //         res.docs.Contract?.special_handling_fee,
          //       ), // 'R$ 0,00',
          //       chargebackRate: res.docs.Contract?.chargeback_rate, // '0,00',
          //       rejectedTransactionFee: formatValue(
          //         res.docs.Contract?.rejected_transaction_fee,
          //       ), // 'R$ 0,00',
          //       ir_fee: formatValue(res.docs.Contract?.ir_fee), // 'R$ 0,00',
          //     },
          //     salesChannel: res.docs.Contract?.sales_channel, // 'direct',
          //     classAccount: 'basic',
          //     isGiftCard: !res.docs.Contract?.allow_withdraw,
          //     // typeCard: !res.docs.Contract?.typeCard,
          //     // isVirtualCard: !res.docs.Contract?.is_virtual_card,
          //     kindAccount: 'virtual_card',
          //     isInternationalCard: res.docs.Contract?.is_international_card, // true,
          //     cardQuantity: 1,
          //     isChipCard: res.docs.Contract?.is_chip_card, // true,
          //     issuer: res.docs.Contract?.issuer, // 'acesso',
          //     // revenue: {
          //     //   calculation: res.docs.Contract.is_normal_calculation
          //     //     ? 'normal'
          //     //     : 'inverted',
          //     //   currency: 'brl',
          //     //   paymentMethod: res.docs.Contract.is_transfer_payment
          //     //     ? 'transfer'
          //     //     : 'ticket',
          //     //   isInvoiceTopup: true,
          //     //   issuingCompanyId: 1,
          //     //   bankAccountId: 1,
          //     //   serviceCodeId: 8,
          //     //   signatureDate: new Date(),
          //     //   expirationDate: new Date(),
          //     //   isSigned: false,
          //     // },
          //     commission_calculation: res.docs.Contract?.commission_calculation,
          //   },
          //   revenue: {
          //     calculation: res.docs.Contract.is_normal_calculation
          //       ? 'normal'
          //       : 'inverted',
          //     currency: 'brl',
          //     paymentMethod: res.docs.Contract.is_transfer_payment
          //       ? 'transfer'
          //       : 'ticket',
          //     isInvoiceTopup: res.docs.Contract.is_invoice_topup,
          //     issuingCompanyId: 1,
          //     bankAccountId: 1,
          //     serviceCodeId: 8,
          //     signatureDate: new Date(),
          //     expirationDate: new Date(),
          //     isSigned: false,
          //   },
          // });
        })
        .catch(err => console.log(err));
    }
  }, [id]);

  async function editCompany(data, address, contractId, cnpj) {
    // eslint-disable-next-line no-param-reassign
    address.postalCode = cleanCEP(address.postalCode);
    return api.contract.updateCompany(data, address, contractId, cnpj);
  }

  async function editContract(
    operatorFees,
    companyOperatingFees,
    cardFees,
    campaign,
    mproductDetails,
    mDelivery,
    mrevenue,
    contractId,
  ) {
    return api.contract.updateContract(
      operatorFees,
      companyOperatingFees,
      cardFees,
      campaign,
      mproductDetails,
      mDelivery,
      mrevenue,
      contractId,
    );
  }

  async function editDeliveryAddress(data, contractId) {
    return api.contract.updateDeliveryAddress(data, contractId);
  }

  async function editUsers(users, contractId) {
    return api.contract.updateUser(users, contractId);
  }

  async function onSuccessContract(mycontract) {
    // return axios.put(`/api/contracts/${idd}`, mycontract);
    await editContract(
      mycontract.productDetails.operatorFees,
      mycontract.productDetails.companyOperatingFees,
      mycontract.productDetails.cardFees,
      mycontract.productDetails.campaign,
      mycontract.productDetails,
      mycontract.deliveryAddress,
      mycontract.revenue,
      id,
    );
    await editDeliveryAddress(mycontract.deliveryAddress, id);
    await editUsers(mycontract.company.contacts, id);
    return editCompany(
      mycontract.company,
      mycontract.deliveryAddress,
      id,
      mycontract.company.cnpj,
    );
    // return editDeliveryAddress(mycontract.deliveryAddress, id);
  }

  // const onSuccessContract = mycontract => {
  //   // return axios.put(`/api/contracts/${idd}`, mycontract);
  //   await editCompany()
  // };

  const callback = () => {
    router.push('/allContracts');
  };

  if (!myContract) {
    return <SpinnerPage open />;
  }

  return (
    <>
      <Grid
        className={classes.header}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginTop: 50 }}
      >
        <p
          className={styles.textMenu}
          onKeyDown={() => router.push(routing.allContracts)}
          onClick={() => router.push(routing.allContracts)}
        >
          Menu Inicial
        </p>
        <div
          className="col-sm-12"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* <h4 className={styles.textTitle}>{t('editContract.label')}</h4> */}
        </div>
        {/* <Typography variant="h4" component="h2" gutterBottom>
          Editar contrato
        </Typography> */}
      </Grid>
      {myContract && (
        <ContractFormViewer
          contract={myContract}
          agent={agent}
          agentComission={agentComission}
          // eslint-disable-next-line react/jsx-no-bind
          onSuccess={onSuccessContract}
          code="API_RES_SUCCESS"
          titleModal={t('editContract.successMsg')}
          actionModal={callback}
          control
          edit
        />
      )}
    </>
  );
}

export const getServerSideProps = async context => {
  const id = context.query.contractId;
  try {
    // const {
    //   data: { company, deliveryAddress, productDetails, revenue },
    // } = await privateFetch(context).get(`/contracts/${id}`);
    const cancel = new AbortController();
    // const {
    //   data: { company, deliveryAddress, productDetails, revenue },
    // } = await api.contract.getContract(cancel, id);

    return {
      props: {
        id,
        contract: {
          company,
          deliveryAddress,
          productDetails,
          revenue,
        },
      },
    };
  } catch (err) {
    // eslint-disable-next-line no-unused-vars
    const error = err;

    return {
      notFound: true,
    };
  }
};

export default ViewerContract;
